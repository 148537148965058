import React from 'react'
import warning from '../../images/warning.svg'

function DeleteModel({isDelete, setIsDelete, section, id,
handleDelete = () => null
}) {

  // const handleAnnouncementDelete = () => {
  //   console.log("section", section)
  // }

  // const handleWatchlatestDelete = () => {
  //   console.log("section", section)
  // }

  // const handleCalenderDelete = () => {
  //   console.log("section", section)
  // }

  // const handleResourceDelete = () => {
  //   console.log("section", section)
  // }
  // const handleDelete = () => {
  //   switch(section !== ""){
  //     case section === "announcement":
  //       handleAnnouncementDelete()
  //       break;
  //     case section === "watchlatest":
  //       handleWatchlatestDelete()
  //       break;
  //     case section === "calender":
  //       handleCalenderDelete()
  //       break;
  //     case section === "resource":
  //       handleResourceDelete()
  //       break;
  //     default : 

      
  //   }
  // }


  return <>
  <div className="divoverlay">
      <div className="divoverlayBlock w--600">
        {/* <div className="popupheaderblock">
          <div> Business Owner </div>

          <img
            src={popupclose}
            alt="popup"
            className="cursorPointer"

          />
        </div> */}
         <div className="overdivScroll container-fluid popupcentercontent">
          <div className="row">
            <div className="col-md-12 textAlignCenter  ">

              <img
                src={warning}
                alt='warning'


              />
              <div className='areyousuretxt'>


                Are You Sure?


              </div>
              <div className='popupTxt pb30 fs--16'>
                Are you sure you want to delete this item?
              </div>
              <div className='displayFlex alignCenter gap30 justify-content-center'>
                <button type='button' className='blueButton' onClick={() => handleDelete(id)}> Yes, delete it!  </button>
                <button type='button' className='cancelButton' onClick={() => {
                  setIsDelete(false)
                }}> Cancel</button>

              </div>
            </div>




          </div>

        </div>
       
        

      </div>
    </div>
    :<></>
  </>
}

export default DeleteModel