import React from 'react'
import {Navigate } from 'react-router-dom'
// import { toast } from 'react-toastify'

function AdminProtectedRoute({children}) {
  
  let token = sessionStorage.getItem('token')
  let role = sessionStorage.getItem('role')

  return <>
    {
        (role==='admin' || role==='superadmin') && token!= null ?children:<Navigate to='sign-in'/>
    }
  </>
}

export default AdminProtectedRoute