import React, { useState, useEffect } from "react";
import popupclose from "../../images/popupclose-icon.svg";
import AxiosService from "./../../service/ApiService";
import { useNavigate } from "react-router-dom";

import countryicon from "../../images/country-icon.svg";
import { useFormik } from "formik";
import * as Yup from "yup";
import { mobileNumber } from "../utils/Regex";
import { toast } from "react-toastify";

function AddBusinessOwner({ addToggle }) {
  const navigate = useNavigate()
  const [countries, setCountries] = useState([]);

  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);

  const [selectedCountry, setSelectedCountry] = useState("");
  const [selectedState, setSelectedState] = useState("");
  //get countries

  const handleCountries = async () => {
    try {
      let res = await AxiosService.get("address/countries");
      if (res.data.statusCode === 200) {
        setCountries(res.data.country);
      }
    } catch (error) {
      console.log(error);
      toast.error(
        error.response.data.message
          ? error.response.data.message
          : error.response.data.message[0]
      );
    }
  };
  // console.log(countries);
  const handleStates = async (countryid) => {
    try {
      // console.log("countryId",countryId);
      let res = await AxiosService.get(`address/states/${countryid}`);
      // console.log("states",res);
      if (res.data.statusCode === 200) {
        setStates(res.data.state);
      }
    } catch (error) {
      console.log(error);
      toast.error(
        error.response.data.message
          ? error.response.data.message
          : error.response.data.message[0]
      );
    }
  };

  const handleCities = async (stateid) => {
    try {
      let res = await AxiosService.get(`address/cities/${stateid}`);
      // console.log(res);
      if (res.data.statusCode === 200) {
        setCities(res.data.city);
        // console.log(res.data.city);
      }
    } catch (error) {
      console.log(error);
      toast.error(
        error.response.data.message
          ? error.response.data.message
          : error.response.data.message[0]
      );
    }
  };
  useEffect(() => {
    handleCountries();
  }, []);

  useEffect(() => {
    if (selectedCountry !== "") {
      let country = countries.filter((e, i) => {
        return e.name === selectedCountry;
      });

      handleStates(country[0].id);
    }
  }, [selectedCountry]);

  useEffect(() => {
    if (selectedState !== "") {
      let state = states.filter((e, i) => {
        return e.name === selectedState;
      });

      handleCities(state[0].id);
    }
  }, [selectedState]);

  const formik = useFormik({
    initialValues: {
      dateOfSignUp: "",
      firstname: "",
      lastname: "",
      businessName: "",
      email: "",

      mobile: "",
      country: "",
      address: "",
      state: "",
      city: "",
      zipcode: "",
      appAccess: false,
    },
    enableReinitialize: true,
    validationSchema: Yup.object({
      dateOfSignUp: Yup.string().required("Please enter the signup date"),
      firstname: Yup.string().required("Please enter the first name"),
      lastname: Yup.string().required("Please enter the last name"),
      businessName: Yup.string().required("Please enter the business name"),
      email: Yup.string().required("Please enter the email"),

      mobile: Yup.string()
        .required("Please enter the mobile")
        .matches(mobileNumber, "Invalid mobile number"),
      country: Yup.string().required("Please enter the country"),
      address: Yup.string().required("Please enter the street address"),
      state: Yup.string().required("Please enter the state"),
      city: Yup.string().required("Please enter the city"),
      zipcode: Yup.string().required("Please enter the zipcode"),
      appAccess: Yup.boolean(),
    }),
    onSubmit: (values) => {
      handleAddBusinessOwner(values);
    },
  });

  const handleAddBusinessOwner = async(values) => {
    try {

      let res = await AxiosService.post('/business-owners/create', values)

      if(res.data.statusCode === 200){
        navigate('/admin/business-owner-management')
        handleClose()
        toast.success(res.data.message)
      }

    } catch (error) {
      toast.error(
        error.response.data.message
          ? error.response.data.message
          : error.response.data.message[0]
      );
    }
  };

  const handleClose = () => {
    addToggle((prev) => !prev);
    formik.resetForm();
  };
 
  return (
    <form onSubmit={formik.handleSubmit}>
      <div className="divoverlay">
        <div className="divoverlayBlock">
          <div className="popupheaderblock">
            <div> Add Business Owner </div>

            <img
              src={popupclose}
              alt="close"
              className="cursorPointer cursor"
              onClick={() => handleClose()}
            />
          </div>

          <div className="container-fluid">
            <div className="overdivScroll">
              <div className="row mb40">
                <div className="col-md-6">
                  <div className="form-group mb15">
                    <label htmlFor="exampleInputEmail1">
                      Date of Signup or Added
                    </label>
                    <input
                      type="date"
                      className="form-control"
                      id="exampleInputEmail1"
                      aria-describedby="emailHelp"
                      placeholder="Enter First Name"
                      name="dateOfSignUp"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.dateOfSignUp}
                    />
                  </div>
                  {formik.errors.dateOfSignUp && formik.touched.dateOfSignUp ? (
                    <p className="error_text">{formik.errors.dateOfSignUp}</p>
                  ) : null}
                </div>

                <div className="col-md-6">
                  <div className="form-group mb15">
                    <label htmlFor="exampleInputEmail1">First name</label>
                    <input
                      type="text"
                      className="form-control"
                      aria-describedby="emailHelp"
                      placeholder="Enter First Name"
                      name="firstname"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.firstname}
                    />
                  </div>
                  {formik.errors.firstname && formik.touched.firstname ? (
                    <p className="error_text">{formik.errors.firstname}</p>
                  ) : null}
                </div>

                <div className="col-md-6">
                  <div className="form-group mb15">
                    <label htmlFor="exampleInputEmail1">Last name</label>
                    <input
                      type="text"
                      className="form-control"
                      aria-describedby="emailHelp"
                      placeholder="Enter Last Name"
                      name="lastname"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.lastname}
                    />
                  </div>
                  {formik.errors.lastname && formik.touched.lastname ? (
                    <p className="error_text">{formik.errors.lastname}</p>
                  ) : null}
                </div>
                <div className="col-md-6">
                  <div className="form-group mb15">
                    <label htmlFor="exampleInputEmail1">Business Name</label>
                    <input
                      type="text"
                      className="form-control"
                      aria-describedby="emailHelp"
                      placeholder="Enter Business Name"
                      name="businessName"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.businessName}
                    />
                  </div>
                  {formik.errors.businessName && formik.touched.businessName ? (
                    <p className="error_text">{formik.errors.businessName}</p>
                  ) : null}
                </div>
                <div className="col-md-6">
                  <div className="form-group mb15">
                    <label htmlFor="exampleInputEmail1">Email</label>
                    <input
                      type="text"
                      className="form-control"
                      aria-describedby="emailHelp"
                      placeholder="Enter Email ID"
                      name="email"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.email}
                    />
                  </div>
                  {formik.errors.email && formik.touched.email ? (
                    <p className="error_text">{formik.errors.email}</p>
                  ) : null}
                </div>
                <div className="col-md-6">
                  <div className="phonenumber ">
                    <label htmlFor="exampleInputEmail1">Phone number</label>

                    <div className="displayFlex">
                      <div className="countryIcon">
                        {" "}
                        <img src={countryicon} alt="country" />
                      </div>
                      <div className="form-group mb15 w100per">
                        <input
                          type="text"
                          className="form-control borderleftcss"
                          id="exampleInputEmail1"
                          aria-describedby="emailHelp"
                          placeholder="Enter Phone Number"
                          name="mobile"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.mobile}
                        />
                      </div>
                    </div>
                    {formik.errors.mobile && formik.touched.mobile ? (
                      <p className="error_text">{formik.errors.mobile}</p>
                    ) : null}
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group mb15">
                    <label htmlFor="exampleInputEmail1">Street Address</label>
                    <input
                      type="text"
                      className="form-control"
                      aria-describedby="emailHelp"
                      placeholder="Enter Street Address"
                      name="address"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.address}
                    />
                  </div>
                  {formik.errors.address && formik.touched.address ? (
                    <p className="error_text">{formik.errors.address}</p>
                  ) : null}
                </div>
                <div className="col-md-6">
                  <div className="form-group mb15">
                    <label htmlFor="exampleInputEmail1">Country</label>
                    <select
                      className="form-select"
                      aria-describedby="emailHelp"
                      placeholder="Enter Country"
                      name="country"
                      onChange={(e) => {
                        // eslint-disable-next-line no-lone-blocks
                        {
                          setSelectedCountry(e.target.value);
                          formik.setFieldValue("country", e.target.value);
                        }
                      }}
                      onBlur={formik.handleBlur}
                      value={formik.values.country}
                    >
                      <option>Select the Country</option>
                      {countries?.map((country, i) => {
                        return (
                          <option key={country.id} value={country.name}>
                            {" "}
                            {country.name}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                  {formik.errors.country && formik.touched.country ? (
                    <p className="error_text">{formik.errors.country}</p>
                  ) : (
                    <></>
                  )}
                </div>

                <div className="col-md-6">
                  <div className="form-group mb15">
                    <label htmlFor="exampleInputEmail1">State</label>
                    <select
                      // name="cars"
                      // id="cars"
                      className="form-select"
                      // onfocus="this.size=4;"
                      // onblur="this.size=0;"
                      name="state"
                      onChange={(e) => {
                        // eslint-disable-next-line no-lone-blocks
                        {
                          setSelectedState(e.target.value);
                          formik.setFieldValue("state", e.target.value);
                        }
                      }}
                      onBlur={formik.handleBlur}
                      value={formik.values.state}
                    >
                      <option>Select the State</option>
                      {states?.map((state, i) => {
                        return (
                          <option key={state.id} value={state.name}>
                            {" "}
                            {state.name}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                  {formik.errors.state && formik.touched.state ? (
                    <p className="error_text">{formik.errors.state}</p>
                  ) : null}
                </div>

                <div className="col-md-6">
                  <div className="form-group mb15">
                    <label htmlFor="exampleInputEmail1">City</label>
                    <select
                      // name="cars"
                      // id="cars"
                      className="form-select"
                      // onfocus="this.size=4;"
                      // onblur="this.size=0;"
                      name="city"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.city}
                    >
                      <option>Select the City</option>

                      {cities?.map((city, i) => {
                        return (
                          <option key={city.id} value={city.name}>
                            {city.name}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                  {formik.errors.city && formik.touched.city ? (
                    <p className="error_text">{formik.errors.city}</p>
                  ) : null}
                </div>

                <div className="col-md-6">
                  <div className="form-group mb15">
                    <label htmlFor="exampleInputEmail1">Zip Code</label>
                    <input
                      type="text"
                      className="form-control"
                      aria-describedby="emailHelp"
                      placeholder="Enter Zip Code"
                      name="zipcode"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.zipcode}
                    />
                  </div>
                  {formik.errors.zipcode && formik.touched.zipcode ? (
                    <p className="error_text">{formik.errors.zipcode}</p>
                  ) : null}
                </div>

                <div className="col-md-6">
                  <div className="grandAccessbox">
                    <div> Grant App Access </div>
                    <div>
                      <label className="toggle">
                        <input
                          type="checkbox"
                          name="appAccess"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.appAccess}
                        />
                        <span className="slider">                          
                        </span>
                        <span
                          className="labels"
                          data-on="Active"
                          data-off="Inactive"
                        ></span>
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row mb40">
              <div className="col-md-12">
                <div className="displayFlex  alignCenter gap15 w100per   justifyflexend">
                  <button
                    className="primaryorangeButtonoutline"
                    onClick={() => {
                      handleClose();
                    }}
                  >
                    {" "}
                    Close{" "}
                  </button>

                  <button className="primaryorangeButton cursor" type="submit">
                    {" "}
                    Save{" "}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  );
}

export default AddBusinessOwner;
