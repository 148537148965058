import React from 'react'

function ResourceSection() {
  return <>
  <div className='adminContentSection'>
        <div className='displayFlex alignCenter spaceBetween'>
          <div className='titleText '> ResourceSection</div>
            </div>
        <div className='maintable mt30'>
         
        </div>
      </div>
  </>
}

export default ResourceSection