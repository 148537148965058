import React from 'react'
// import { useNavigate } from 'react-router-dom'
import UserBackdrop from './UserBackdrop'

function PrivacyPolicy({setTermsToggle,setToggle, setCheck}) {
  // const navigate = useNavigate();
  const handleAccept = () =>{
    setTermsToggle(false)
    setCheck(true)
  }

  const handleDecline = () =>{
    setTermsToggle(false)
    setCheck(false)
  }
  return (
    <>
      <div className='displayFlex'>
        <UserBackdrop />

      </div>
      <div className='overlayDiv'>


        <div className='overlayContent'>

          <div className='termTextblock'>
            <div className='termtext' onClick={()=>{
              setToggle(true)
            }}>Term & Condition</div>
            <div className='termtext  termtextActive' onClick={()=>{
              setToggle(false)
            }}> Privacy Policy</div>
          </div>

          <div className='innercontent'>



            <strong>Privacy Policy</strong> <br />

            We respect your privacy and value the relationship we have with you. This Privacy Notice describes the types of personal information we may collect on our website, how we may use that information, with whom we may share it, and the measures we take to protect the security of the information. Additionally, we describe the choices you can make about how we use the information you provide to us, and provide you with directions on how you can reach us to update certain information you've given us, ask us not to send you future communications, or answer any questions you may have about our privacy practices. <br />
            <br />


            This Privacy Notice applies only to information we collect on our website and through your interaction with our website. This Privacy Notice does not apply to any other website, service, product, or application operated or provided by us or a third party. <br />
            <br />


            Information We Collect and How We Use It
            You may choose to provide personal information (such as your name, address, telephone number, and email address) on this website, and we may collect personal information about you when you visit or use this website. This privacy notice summarizes the ways you may provide the information, the types of information you may submit, and the information we may collect from you when you visit or use this website. We will also tell you how we may use the information. <br />
            <br />


            <strong>Contact Us</strong> <br />

            You may choose to contact us through our website. If you email us via the "Contact Us" link on this site, we ask you for information such as your name and email address so we can respond to your questions and comments. You may choose to provide additional information, and we use the information you provide to respond to your inquiries. <br />
            <br />


            <strong>Use of Website</strong> <br />

            When you visit our website, we collect information from you through automated means. This includes, in particular, your internet protocol (IP) address, device information, browser information, and details on your usage of our website, so we can determine whether you are a repeat visitor to our website. <br />
            <br />


            <strong>Information We Share</strong> <br />

            We do not sell, rent, trade or otherwise disclose personal information about our website visitors, except as described here. We share information about our website visitors with service providers we have retained to perform services on our behalf, such as website hosting, customer service, and information technology services. These service providers are not authorized by us to use or disclose information, except as necessary to perform services on our behalf or to comply with legal requirements. We also may share information about you with other members of our corporate family. In addition, we may disclose information about you (i) if we are required to do so by law, (ii) in response to a request from law enforcement authorities or other government officials, (iii) when we believe disclosure is necessary or appropriate to prevent physical harm or financial loss or in connection with an investigation of suspected or actual illegal activity, or (iv) to exercise our legal rights or defend against legal claims. <br />
            <br />


            We reserve the right to transfer any information we have about you in the event we sell or transfer all or a portion of our business or assets. Should such a sale or transfer occur, we will make reasonable efforts to try to direct the transferee to use personal information you have provided through this website in a manner that is consistent with this Privacy Notice. <br />
            <br />


            <strong>Links to Other Sites</strong> <br />

            We may provide links to other websites for your convenience and information. These sites operate independently from our site and are not under our control. These sites may have their own privacy notices in place, which we strongly suggest you review if you visit any linked websites. We are not responsible for the content of these sites, any products or services that may be offered through these sites, or any other use of these sites. <br />
            <br />


            <strong>Security</strong> <br />

            We maintain administrative, technical, and physical safeguards designed to protect against unauthorized disclosure, use, alteration or destruction of the personal information we have about you. When you provide credit card information to make a purchase, we use the industry standard for Internet security -- Secure Socket Layer (SSL) technology -- to help protect the information you provide. This Internet encryption standard scrambles data as it travels from your computer to our server. Please note, however, that perfect security does not exist on the Internet. You'll know that you're in a secure area of our website when a "lock" icon appears on your screen and the "http" portion of our URL address changes to "https." The "s" stands for "secure." <br />
            <br />


            <strong>Your Choices</strong> <br />

            If you would like to update your contact information or prefer not to receive certain communications from us (such as e-mail), please call us or write to us using one of the options provided below. <br />
            <br />


            <strong>Updates to this Privacy Notice</strong> <br />

            We may update or change this Privacy Notice from time to time and without prior notice to you. We will post a prominent notice on this site to notify you of any significant changes to our Privacy Notice and indicate below when it was most recently updated. <br />
            <br />


            <strong>How to Contact Us</strong> <br />

            If you have any questions or comments about this Privacy Notice, or if you would like us to update certain information you have provided to us or your communication preferences,  <br />
            <br />

            <strong> Contact US:</strong> <br />

            Info@gostartupgo.co <br />


            US -
            985 Senate Drive, <br />
            Dayton, OH 45459 <br />

            +1 9376198397 <br />

          



          </div>
          <div style={{display : 'flex'}}>
          <button className='orangeButton' onClick={() =>{
            handleAccept();
          }}>Accept</button>
          <button className='orangeButton' onClick={()=>{
            handleDecline();
          }}>Decline</button>
          </div>
          


        </div>


      </div>



    </>
  )
}

export default PrivacyPolicy