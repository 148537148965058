import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Banner from '../../images/landingPage/home/home-banner.jpg';
import WhyBusiness from '../../images/landingPage/home/WhyBusiness.jpg';
import OurGoal1 from '../../images/landingPage/home/OurGoal1.jpg';
import Header from './common/Header';
import Footer from './common/Footer';
import Copyright from './common/Copyright';
import AOS from 'aos';
import 'aos/dist/aos.css';
import styles from './Landing.module.scss';

function LandingPage() {
  const [earnings, setEarnings] = useState([]);
  const goalAmount = 100000;

  useEffect(() => {
    AOS.init();
    window.scrollTo(0, 0);
  }, []);

  const url = `${process.env.REACT_APP_STORE_URL}`

  const fetcEarnings = async () => {
    try {
      const response = await fetch(url, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          'Authorization': 'Basic Z29zdGFydHVwZ286Z1VVOCAxU25iIGpDR1YgUktwMCBVRUNpIHdSWno=',
        },
      });
      if (!response.ok) {
        throw new Error("Unable to Retrieve Data. Please try again.");
      }

      const data = await response.json();
      setEarnings(data.earnings.totals);

    } catch (error) {
      console.error('Error:', error);
    }
  };

  const calculateProgress = () => {
    return (earnings / goalAmount) * 100;
  };

  useEffect(() => {
    fetcEarnings()
  }, []);

  return <>
    <Header />
    <section className={`${styles.section1} container-fluid`}>
      <div className={`container py-lg-5 py-xs-0`}>
        <div className='row'>
          <div className='col-sm-6 col-xs-12'>
            <h2 className='fs--36 mb-4 pt-lg-5 pt-xs-0 fontPoppins fw--600 lh-55' data-aos="fade-up"><span className='text--primary'>Empowering Lives</span> and Communities Through The Power of Entrepreneurship</h2>
            <p className='fs--18 pb-4 text--white3 fontPoppins lh-35  pe-lg-5 pe-xs-0' data-aos="fade-up">Entrepreneurship has proven to have a positive impact on today's global challenges.<br /> Each one of us, by developing and using our strengths and skills, are agents of social change.</p>
            <div className='col-sm-12' data-aos="fade-up">
              <Link to="https://dev-store.gostartupgo.org/index.php/donate/" target="_blank" className='btn--secondary border-0' role='button' aria-label='Support a Business'>Support a Business</Link>
            </div>
          </div>
          <div className='col-sm-6 col-xs-12 py-3 d-flex justify-content-end ps-lg-5 ps-xs-0 pe-lg-5 pe-xs-0 position-relative rightEffect'>
            <img src={Banner} alt='Slider' className='w-100 h-auto br--30' data-aos="fade-up" />
          </div>
        </div>
      </div>
    </section>
    <section className={`${styles.section4} container-fluid`}>
      <div className={`container py-5`}>
        <div className='row'>
          <div className='col-sm-12 col-xs-12 text-center pb-5 fontPoppins'>
            <h3 className='fs--38 lh--45 fw--600' data-aos="fade-up">Our mission </h3>
            <p className='fs--18 lh--45 text--white3' data-aos="fade-up">Business Building is the action that fights poverty</p>
          </div>
        </div>
        <div className='row'>
          <div className='col-sm-6 col-xs-12 pe-lg-5 pe-xs-0 position-relative rightEffect'>
            <img src={WhyBusiness} alt='Mission ' className='w-100 h-auto br--30' data-aos="fade-right" />
          </div>
          <div className='col-sm-6 col-xs-12 d-flex flex-column justify-content-center'>
            <p className='fs--18 pb-4 pt-lg-0 pt-5 text-black fontPoppins lh-35 mb-0 pe-lg-5 pe-xs-0 mt-lg-0 mt-xs-5' data-aos="fade-up">At GoStartUpGo Foundation, we believe in the transformative power of entrepreneurship. By providing entrepreneurs in developing countries with training, mentorship, and funding, we equip them to build sustainable businesses that create lasting change in their communities. This is not just about charity; it's about empowerment and opportunity.</p>
          </div>
        </div>
      </div>
    </section>
    <section className={`${styles.section7} container-fluid`}>
      <div className='container py-5'>
        <div className='row'>
          <div className={`col-sm-12 text-center mb-2`}>
            <h4 className='fs--38 fw--600 fontPoppins' data-aos="fade-up">Join Us in Empowering Entrepreneurs</h4>
          </div>
          <div className={`col-sm-8 col-12 offset-sm-2 text-center mb-4`}>
            <p className='fs--18 lh--45 text--white3' data-aos="fade-up">Your support can help build businesses that uplift entire communities. Whether through a donation or by purchasing from our shop, you’re directly funding the tools and resources that help entrepreneurs succeed.</p>
          </div>
          <div className='col-sm-12 col-xs-12'>
            <div className='row gx-5'>
              <div className='col-sm-4 offset-sm-4 col-xs-12 mb-5'>
                <div className={`${styles.section7Inner}  p-3 h-100`} data-aos="fade-up">
                  <img src={OurGoal1} alt='Our Goals' className={`${styles.section6InnerImg} w-100 h-auto mb-4 br--5`} />
                  <div>
                    <h5 className="fs--22 lh-35 fw--600">Micro Venture funding</h5>
                    <small className='d-block mb-3'>Help us fund 50 more entrepreneurs by the end of the year! </small>
                    {/* <p><strong>${earnings && earnings}</strong> raised out of ${goalAmount}.00</p>
                    <div className="progress mb-3">
                      <div className="progress-bar progress-bar-striped progress-bar-animated bg--secondary " role="progressbar" style={{ width: `${calculateProgress()}%` }} aria-valuenow={earnings && earnings} aria-valuemin="0" aria-valuemax="100">{calculateProgress()}%</div>
                    </div> */}
                    <div className='col-sm-12' data-aos="fade-up"><Link to="https://dev-store.gostartupgo.org/index.php/donate/" target="_blank" className='btn--outline--signin me-2 d-block text-center' role='button' aria-label='Donate'>Donate</Link></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section className={`${styles.section10} container-fluid bgGradient--primary`}>
      <div className={`container py-5`}>
        <div className='row'>
          <div className='col-sm-6 col-xs-12'>
            <h2 className='fs--30 mb-3 fontMontserrat text-white fw--600 lh-38' data-aos="fade-up">Go Start Up Go is the ideal partner to turn your small business dreams into reality.</h2>
            <p className='fs--16 text--grey1 mb-0 fontMontserrat lh-25' data-aos="fade-up">With Go Start Up Go, you'll have the support and tools necessary to be the best version of yourself and realize your full potential.</p>
          </div>
        </div>
      </div>
    </section>
    <Footer />
    <Copyright />
  </>
}

export default LandingPage