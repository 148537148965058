import React from 'react';
import NoIcon from '../../images/folder.png';

export const NoData = () => {
    return (
        <div className='px-4 text-center mb-4'>
            <div className="bg--grey3 p-3">
                <img src={NoIcon} alt='No Data Found' width='70' />
                <p className="mb-0 mt-1 fw--500">No Data Found</p>
            </div>
        </div>
    )
}
