import React, { useState } from 'react'
import TermsAndCondition from './TermsAndCondition'
import PrivacyPolicy from './PrivacyPolicy'
function TermsAndPrivacyWrapper({setTermsToggle, setCheck}) {
    let [toggle,setToggle] = useState(true)
  return <>
   { toggle?<TermsAndCondition setTermsToggle = {setTermsToggle} setToggle={setToggle} setCheck={setCheck}/>:
    <PrivacyPolicy setTermsToggle = {setTermsToggle} setToggle={setToggle} setCheck={setCheck}/>
    }
  </>
}

export default TermsAndPrivacyWrapper