import React, { useEffect, useState, useRef } from "react";
import popupclose from "../../images/popupclose-icon.svg";
import editicon from "../../images/edit-icon.svg";
import resetpassword from "../../images/password-reset.svg";
import eyeicon from "../../images/eye-icon.svg";
import tickicon from "../../images/tick-icon.svg";
import AxiosService from "./../../service/ApiService";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useLogout } from "../../hooks/useLogout";
import { email, mobileNumber, name, username, zipcode } from "../utils/Regex";
import { toast } from "react-toastify";
import tickicongreen from "../../images/tick-icon-green.svg";
import eyeopen from "../../images/eye-open.svg";

function AdminUser({ viewToggle, id }) {

  const [user, setUser] = useState();
  const Logout = useLogout()
  const [displayForm, setDisplayForm] = useState("");
  const [displayReset, setDisplayReset] = useState("none");
  // console.log(user)

  //reset password
  const [isedit, setIsedit] = useState(true);
  const [isreset, setIsreset] = useState(false);
  const passref = useRef("");
  const cpassref = useRef("");
  const [width, setWidth] = useState(0);
  const [char, setChar] = useState(false);
  const [oneNum, setOneNum] = useState(false);
  const [splChar, setSplChar] = useState(false);
  const [upLow, setUpLow] = useState(false);
  const [passwordVisibilty1, setPasswordVisibilty1] = useState(true);
  const [passwordVisibilty2, setPasswordVisibilty2] = useState(true);

  //select
  const [countries, setCountries] = useState([]);

  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);

  // const [selectedCountry, setSelectedCountry] = useState("");
  // const [selectedState, setSelectedState] = useState("");

  const handleGetUserById = async () => {

    try {
      let res = await AxiosService.get(`/admin/${id}`)
      if (res.data.statusCode === 200) {
        setUser(res.data.user[0]);
      }

    } catch (error) {
      console.log(error)
      toast.error(
        error.response.data.message
          ? error.response.data.message
          : error.response.data.message[0]
      );
    }
  };

  useEffect(() => {

    if (id) {
      handleGetUserById();
    }
  }, []);

  const handleCountries = async () => {
    try {
      let res = await AxiosService.get("address/countries");
      if (res.data.statusCode === 200) {
        setCountries(res.data.country);
      }
    } catch (error) {
      console.log(error);
      toast.error(
        error.response.data.message
          ? error.response.data.message
          : error.response.data.message[0]
      );
    }
  };
  // console.log(countries);
  const handleStates = async (countryid) => {
    try {
      // console.log("countryId",countryId);
      let res = await AxiosService.get(`address/states/${countryid}`);
      // console.log("states",res);
      if (res.data.statusCode === 200) {
        setStates(res.data.state);
      }
    } catch (error) {
      console.log(error);
      toast.error(
        error.response.data.message
          ? error.response.data.message
          : error.response.data.message[0]
      );
    }
  };

  const handleCities = async (stateid) => {
    try {

      let res = await AxiosService.get(`address/cities/${stateid}`);

      if (res.data.statusCode === 200) {
        setCities(res.data.city);
        // console.log(res.data.city);
      }
    } catch (error) {
      console.log(error);
      toast.error(
        error.response.data.message
          ? error.response.data.message
          : error.response.data.message[0]
      );
    }
  };

  useEffect(() => {
    handleCountries();
  }, []);





  const createPassword = useFormik({
    initialValues: {
      password: "",
      confirmPassword: "",
    },
    enableReinitialize: true,

    validationSchema: Yup.object({
      password: Yup.string()
        .required("Please enter the password")
        .min(8, "Password must be 8 characters long")
        .matches(/[0-9]/, "Password requires a number")
        .matches(/[a-z]/, "Password requires a lowercase letter")
        .matches(/[A-Z]/, "Password requires an uppercase letter")
        .matches(/[^\w]/, "Password requires a symbol"),
      confirmPassword: Yup.string()
        .required("Please enter the confirm password")
        .oneOf([Yup.ref("password"), null], "Must match Password field value"),
    }),
    onSubmit: (values) => {
      handleResetPassword(values);
    },
  });

  const handleResetPassword = async ({ password, confirmPassword }) => {


    try {
      let mobile = user?.mobile;
      // console.log(mobile, password, confirmPassword);
      let res = await AxiosService.post("/users/set-password", {
        mobile,
        password,
        confirmPassword,
      });
      if (res.status === 200) {
        toast.success(res.data.message);
        viewToggle((prev) => !prev);
      }
    } catch (error) {
      if (error.response.status === 401)
        Logout()
      toast.error(error.response.data.message ? error.response.data.message : error.response.data.message[0]);
    }
  };


  const handleEditAdminUser = async (values) => {
    try {
      let res = await AxiosService.put(`/admin/edit/${id}`, values);
      if (res.status === 200) {
        toast.success(res.data.message);
        viewToggle((prev) => !prev);
      }
    } catch (error) {
      console.log(error);
      toast.error(
        error.response.data.message
          ? error.response.data.message
          : error.response.data.message[0]
      );
    }
  };

  const formik = useFormik({
    initialValues: {
      firstname: user?.firstname,
      lastname: user?.lastname,
      username: user?.username,
      email: user?.email,
      country: user?.country,
      mobile: user?.mobile,
      state: user?.state,
      city: user?.city,
      zipcode: user?.zipcode,
      address: user?.address,
    },
    enableReinitialize: true,
    validationSchema: Yup.object({
      firstname: Yup.string()
        .required("Please enter the first name")
        .matches(name, "Invalid first name"),
      lastname: Yup.string()
        .required("Please enter the last name")
        .matches(name, "Invalid last name"),
      username: Yup.string()
        .required("Please enter the username")
        .matches(username, "Invalid username"),
      email: Yup.string()
        .required("Please enter the email")
        .matches(email, "Invalid email"),
      country: Yup.string().required("Please enter the country"),
      mobile: Yup.string()
        .required("Please enter the mobile")
        .matches(mobileNumber, "Invalid mobile number"),
      state: Yup.string().required("Please enter the state"),
      city: Yup.string().required("Please enter the city"),
      zipcode: Yup.string()
        .required("Please enter the zipcode")
        .matches(zipcode, "Invalid zipcode"),
      address: Yup.string()
        .required("Please enter the street address")
      // .matches(address, "Invalid street address"),
    }),
    onSubmit: (values) => {
      // console.log("test")
      handleEditAdminUser(values);
    },
  });

  useEffect(() => {
    if (formik.values.country !== "" && formik.values.country !== undefined) {

      let country = countries.filter((e, i) => {
        return e.name === formik.values.country;
      });
      if (country[0]?.id !== undefined) {
        handleStates(country[0]?.id);
      }
    }
  }, [formik.values?.country, countries]);

  useEffect(() => {
    if (formik.values?.state !== "" && formik.values.state !== undefined) {
      // console.log(formik.values.state)
      let state = states.filter((e, i) => {
        return e.name === formik.values?.state;
      });

      if (state[0]?.id !== undefined && state !== []) {

        handleCities(state[0]?.id);
      }

    }
  }, [formik.values?.state, states]);



  //  console.log(formik.values)
  let value = [0, 0, 0, 0];
  useEffect(() => {
    if (createPassword.values?.password.length > 7) {
      setChar(true);
      value[0] = 25;
    } else {
      setChar(false);
      value[0] = 0;
    }
    if (/[0-9]/.test(createPassword.values?.password)) {
      setOneNum(true);
      value[1] = 25;
    } else {
      setOneNum(false);
      value[1] = 0;
    }
    if (
      /[a-z]/.test(createPassword.values?.password) &&
      /[A-Z]/.test(createPassword.values?.password)
    ) {
      setUpLow(true);
      value[2] = 25;
    } else {
      setUpLow(false);
      value[2] = 0;
    }

    if (/[^\w]/.test(createPassword.values?.password)) {
      setSplChar(true);
      value[3] = 25;
    } else {
      setSplChar(false);
      value[3] = 0;
    }
    setWidth(
      value.reduce((acc, cur) => {
        return acc + cur;
      }, 0)
    );
  }, [createPassword.values?.password]);

  // console.log(formik.values)
  return (
    <>
      <div className="divoverlay">
        <div className="divoverlayBlock">
          <div className="popupheaderblock">
            <div> User Profile </div>

            <img
              src={popupclose}
              alt="pop"
              className="cursorPointer"
              onClick={() => viewToggle((prev) => !prev)}
            />
          </div>

          <div className="container-fluid">
            <div className="displayFlex  alignCenter gap15 w100per  mb15">
              <button
                className={
                  isedit
                    ? "roundorangeButton displayFlex  alignCenter gap15"
                    : "roundorangeButtonoutline displayFlex  alignCenter gap15"
                }
                onClick={() => {
                  setDisplayForm("");
                  setDisplayReset("none");
                  setIsedit(true);
                  setIsreset(false);
                }}
              >
                <div>
                  {" "}
                  <img src={editicon}
                    alt="edit" />{" "}
                </div>
                <div>Edit</div>
              </button>

              <button
                className={
                  isreset
                    ? "roundorangeButton displayFlex  alignCenter gap15"
                    : "roundorangeButtonoutline displayFlex  alignCenter gap15"
                }
                onClick={() => {
                  setDisplayForm("none");
                  setDisplayReset("");
                  setIsedit(false);
                  setIsreset(true);
                }}
              >
                <div>
                  {" "}
                  <img src={resetpassword} alt="reset" />{" "}
                </div>
                <div>Reset Password </div>
              </button>
            </div>
            <form
              onSubmit={formik.handleSubmit}
              style={{ display: displayForm }}
            >
              <div className="userprofile">
                <div className="overdivScroll">
                  <div className="row mb40">
                    <div className="col-md-6">
                      <div className="form-group mb15">
                        <label for="exampleInputEmail1">First Name</label>
                        <input
                          type="text"
                          className="form-control"
                          id="exampleInputEmail1"
                          aria-describedby="emailHelp"
                          placeholder="Enter First Name"
                          name="firstname"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.firstname}
                        />
                      </div>
                      {formik.errors.firstname && formik.touched.firstname ? (
                        <p className="error_text_admin">
                          {" "}
                          {formik.errors.firstname}
                        </p>
                      ) : null}
                    </div>

                    <div className="col-md-6">
                      <div className="form-group mb15">
                        <label for="exampleInputEmail1">Last name</label>
                        <input
                          type="text"
                          className="form-control"
                          aria-describedby="emailHelp"
                          placeholder="Enter Last Name"
                          name="lastname"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.lastname}
                        />
                      </div>
                      {formik.errors.lastname && formik.touched.lastname ? (
                        <p className="error_text_admin">
                          {" "}
                          {formik.errors.lastname}
                        </p>
                      ) : null}
                    </div>

                    <div className="col-md-6">
                      <div className="form-group mb15">
                        <label for="exampleInputEmail1">User Name</label>
                        <input
                          type="text"
                          className="form-control"
                          aria-describedby="emailHelp"
                          placeholder="Enter User Name"
                          name="username"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.username}
                        />
                      </div>
                      {formik.errors.username && formik.touched.username ? (
                        <p className="error_text_admin">
                          {" "}
                          {formik.errors.username}
                        </p>
                      ) : null}
                    </div>

                    <div className="col-md-6">
                      <div className="form-group mb15">
                        <label for="exampleInputEmail1">Email</label>
                        <input
                          type="text"
                          className="form-control"
                          aria-describedby="emailHelp"
                          placeholder="Enter Email ID"
                          name="email"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.email}
                        />
                      </div>
                      {formik.errors.email && formik.touched.email ? (
                        <p className="error_text_admin">
                          {" "}
                          {formik.errors.email}
                        </p>
                      ) : null}
                    </div>

                    <div className="col-md-6">
                      <div className="form-group mb15">
                        <label for="exampleInputEmail1">Country</label>
                        <select

                          className="form-select"
                          aria-describedby="emailHelp"

                          name="country"
                          onChange={(e) => {
                            // eslint-disable-next-line no-lone-blocks
                            {
                              // setSelectedCountry(e.target.value);
                              formik.setFieldValue("country", e.target.value);
                            }
                          }}
                          onBlur={formik.handleBlur}
                          value={formik.values.country}
                          selected={user?.country === formik.values.country}
                        >
                          <option>Select the Country</option>
                          {countries?.map((country, i) => {
                            return (
                              <option key={country.id} value={country.name}>
                                {" "}
                                {country.name}
                              </option>
                            );
                          })}
                        </select>
                      </div>
                      {formik.errors.country && formik.touched.country ? (
                        <p className="error_text_admin">
                          {" "}
                          {formik.errors.country}
                        </p>
                      ) : null}
                    </div>

                    <div className="col-md-6">
                      <div className="form-group mb15">
                        <label for="exampleInputEmail1">Phone no</label>
                        <input
                          type="text"
                          className="form-control"
                          aria-describedby="emailHelp"
                          placeholder="Enter Phone Number"
                          name="mobile"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.mobile}
                        />
                      </div>
                      {formik.errors.mobile && formik.touched.mobile ? (
                        <p className="error_text_admin">
                          {" "}
                          {formik.errors.mobile}
                        </p>
                      ) : null}
                    </div>

                    <div className="col-md-6">
                      <div className="form-group mb15">
                        <label for="exampleInputEmail1">State</label>
                        <select
                          type="text"
                          // name="cars"
                          // id="cars"
                          className="form-select"
                          // onfocus="this.size=4;"
                          // onblur="this.size=0;"
                          name="state"
                          onChange={(e) => {
                            // eslint-disable-next-line no-lone-blocks
                            {
                              // setSelectedState(e.target.value);
                              formik.setFieldValue("state", e.target.value);
                            }
                          }}
                          onBlur={formik.handleBlur}
                          value={formik.values.state}
                          selected={user?.state === formik.values?.state}
                        >
                          <option>Select the State</option>
                          {states?.map((state, i) => {
                            return (
                              <option key={state.id} value={state.name}>
                                {" "}
                                {state.name}
                              </option>
                            );
                          })}
                        </select>
                      </div>
                    </div>
                    {formik.errors.state && formik.touched.state ? (
                      <p className="error_text_admin"> {formik.errors.state}</p>
                    ) : null}

                    <div className="col-md-6">
                      <div className="form-group mb15">
                        <label for="exampleInputEmail1">City</label>
                        <select
                          // name="cars"
                          // id="cars"
                          className="form-select"
                          // onfocus="this.size=4;"
                          // onblur="this.size=0;"
                          name="city"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.city}
                          selected={user?.city === formik.values.city}
                        >
                          <option>Select the City</option>

                          {cities?.map((city, i) => {
                            return (
                              <option key={city.id} value={city.name}
                              >
                                {city.name}
                              </option>
                            );
                          })}
                        </select>
                      </div>
                    </div>
                    {formik.errors.city && formik.touched.city ? (
                      <p className="error_text_admin"> {formik.errors.city}</p>
                    ) : null}

                    <div className="col-md-6">
                      <div className="form-group mb15">
                        <label for="exampleInputEmail1">Zip Code</label>
                        <input
                          type="text"
                          className="form-control"
                          aria-describedby="emailHelp"
                          placeholder="Enter Zip Code"
                          name="zipcode"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.zipcode}
                        />
                      </div>
                      {formik.errors.zipcode && formik.touched.zipcode ? (
                        <p className="error_text_admin">
                          {" "}
                          {formik.errors.zipcode}
                        </p>
                      ) : null}
                    </div>

                    <div className="col-md-6">
                      <div className="form-group mb15">
                        <label for="exampleInputEmail1">Street Address</label>
                        <input
                          type="text"
                          className="form-control"
                          aria-describedby="emailHelp"
                          placeholder="Enter Street Address"
                          name="address"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.address}
                        />
                      </div>
                      {formik.errors.address && formik.touched.address ? (
                        <p className="error_text_admin">
                          {" "}
                          {formik.errors.address}
                        </p>
                      ) : null}
                    </div>
                  </div>
                </div>
                <div className="row mb40">
                  <div className="col-md-12">
                    <div className="displayFlex  alignCenter gap15 w100per   justifyflexend">
                      <button
                        className="primaryorangeButtonoutline"
                        onClick={() => viewToggle((prev) => !prev)}
                      >
                        {" "}
                        Close{" "}
                      </button>

                      <button className="primaryorangeButton" type="submit">
                        {" "}
                        Save{" "}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </form>

            <form onSubmit={createPassword.handleSubmit}>
              <div className="resetPassword" style={{ display: displayReset }}>
                <div className="overdivScroll">
                  <div className="row mb40">
                    <div className="col-md-12">
                      <div className="resetPasswordSection">
                        <div className="resetTitle">Reset Your Password</div>

                        <div className="form-group mb15  w100per">
                          <label for="exampleInputEmail1">
                            Enter New Password
                          </label>
                          <div className="input-group mb-3">
                            <input
                              type={passwordVisibilty1 ? "password" : "text"}
                              className="form-control"
                              placeholder="Enter new password"
                              aria-label="Recipient's username"
                              aria-describedby="basic-addon2"
                              name="password"
                              ref={passref}
                              value={createPassword.values.password}
                              onChange={createPassword.handleChange}
                              onBlur={createPassword.handleBlur}
                            />
                            <div
                              className="input-group-append"
                              onClick={() => {
                                if (passwordVisibilty1) {
                                  setPasswordVisibilty1(false);
                                } else {
                                  setPasswordVisibilty1(true);
                                }
                              }}
                            >
                              <span
                                className="input-group-text"
                                id="basic-addon2"
                              >
                                <img
                                  src={passwordVisibilty1 ? eyeicon : eyeopen}
                                  alt="eye icon"
                                />
                              </span>
                            </div>
                          </div>
                        </div>
                        {createPassword.errors.password &&
                          createPassword.touched.password ? (
                          <p className="error_text">
                            {createPassword.errors.password}{" "}
                          </p>
                        ) : (
                          ""
                        )}

                        <div className="form-group mb15  w100per">
                          <label for="exampleInputEmail1">
                            Confirm new password
                          </label>
                          <div className="input-group mb-3">
                            <input
                              type={passwordVisibilty2 ? "password" : "text"}
                              className="form-control"
                              placeholder="Confirm new password"
                              aria-label="Recipient's username"
                              aria-describedby="basic-addon2"
                              name="confirmPassword"
                              rel={cpassref}
                              value={createPassword.values.confirmPassword}
                              onChange={createPassword.handleChange}
                              onBlur={createPassword.handleBlur}
                            />
                            <div
                              className="input-group-append"
                              onClick={() => {
                                if (passwordVisibilty2) {
                                  setPasswordVisibilty2(false);
                                } else {
                                  setPasswordVisibilty2(true);
                                }
                              }}
                            >
                              <span
                                className="input-group-text"
                                id="basic-addon2"
                              >
                                <img
                                  src={passwordVisibilty2 ? eyeicon : eyeopen}
                                  alt="eye"
                                />
                              </span>
                            </div>
                          </div>
                        </div>
                        {createPassword.errors.confirmPassword &&
                          createPassword.touched.confirmPassword ? (
                          <p className="error_text">
                            {createPassword.errors.confirmPassword}{" "}
                          </p>
                        ) : null}

                        <div className="displayFlex  flexdirectioncolumn">
                          <div className="blackColor textAlignLeft fs14 fontWeight500">
                            {" "}
                            Password Strength{" "}
                          </div>
                          <div className="progressBlock mb15">
                            <div
                              className="track"
                              style={{ width: "100% !important" }}
                            >
                              {" "}
                            </div>
                            <div
                              className="thumb"
                              style={{ width: `${width}%` }}
                            >
                              {" "}
                            </div>
                          </div>
                        </div>

                        {/* <div className="displayFlex alignCenter mb40  flexWrap ">
                          <div className="displayFlex alignCenter gap5 mr15px">
                            <div>
                              {" "}
                              <img src={tickicon} />{" "}
                            </div>
                            <div className="grayText fs12"> 8 Character </div>
                          </div>
                          <div className="displayFlex alignCenter gap5 mr15px">
                            <div>
                              {" "}
                              <img src={tickicon} />{" "}
                            </div>
                            <div className="grayText fs12"> One Number </div>
                          </div>
                          <div className="displayFlex alignCenter gap5 mr15px">
                            <div>
                              {" "}
                              <img src={tickicon} />{" "}
                            </div>
                            <div className="grayText fs12">
                              {" "}
                              One Special Character{" "}
                            </div>
                          </div>
                          <div className="displayFlex alignCenter gap5 mr15px">
                            <div>
                              {" "}
                              <img src={tickicon} />{" "}
                            </div>
                            <div className="grayText fs12">
                              {" "}
                              One Upper & Smaller case Character{" "}
                            </div>
                          </div>
                        </div> */}
                        <div className="displayFlex alignCenter mb30 flexWrap ">
                          <div className="displayFlex alignCenter gap5 mr15px">
                            <div>
                              {" "}
                              <img src={char ? tickicongreen : tickicon}
                                alt="char" />{" "}
                            </div>
                            <div
                              className={char ? "green_text" : "grayText fs12"}
                            >
                              {" "}
                              8 Character{" "}
                            </div>
                          </div>
                          <div className="displayFlex alignCenter gap5 mr15px">
                            <div>
                              {" "}
                              <img
                                src={oneNum ? tickicongreen : tickicon}
                                alt="one"
                              />{" "}
                            </div>
                            <div
                              className={
                                oneNum ? "green_text" : "grayText fs12"
                              }
                            >
                              {" "}
                              One Number{" "}
                            </div>
                          </div>
                          <div className="displayFlex alignCenter gap5 mr15px">
                            <div>
                              {" "}
                              <img
                                src={splChar ? tickicongreen : tickicon}
                                alt="spl"
                              />{" "}
                            </div>
                            <div
                              className={
                                splChar ? "green_text" : "grayText fs12"
                              }
                            >
                              {" "}
                              One Special Character{" "}
                            </div>
                          </div>
                          <div className="displayFlex alignCenter gap5 mr15px">
                            <div>
                              {" "}
                              <img
                                src={upLow ? tickicongreen : tickicon}
                                alt="up"
                              />{" "}
                            </div>
                            <div
                              className={upLow ? "green_text" : "grayText fs12"}
                            >
                              {" "}
                              One Upper & Smaller case Character{" "}
                            </div>
                          </div>
                        </div>

                        <div className="displayFlex  alignCenter gap15 w100per   justifyflexend">
                          <button className="primaryorangeButton" type="submit">
                            {" "}
                            Submit{" "}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="row mb40">
                    <div className="col-md-12"></div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}

export default AdminUser;
