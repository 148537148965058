import React from 'react';
import { Routes, Route } from "react-router-dom";
import SignIn from '../../users/SignIn';
import SignUp from '../../users/SignUp';
import CreatePassword from '../../users/CreatePassword'
import ForgotPassword from '../../users/ForgotPassword'
import OTP from '../../users/OTP'
import TermsAndCondition from '../../users/TermsAndCondition'
import PrivacyPolicy from '../../users/PrivacyPolicy'
import AdminRoutes from './AdminRoutes'
import NotFound from '../NotFound'
import EnterMobile from '../../users/EnterMobile';
import AdminProtectedRoute from '../AdminProtectedRoute';
import StudentProtectedRoute from '../StudentProtectedRoute'
import StudentRoutes from './StudentRoutes';

function AppRoutes() {
  return <>
    <Routes>
        <Route path='sign-in' element={<SignIn/>}/>
        <Route path='sign-up' element={<SignUp/>}/>
        <Route path='create-password' element={<CreatePassword/>}/>
        <Route path='forgot-password' element={<ForgotPassword/>}/>
        <Route path='enter-mobile' element={<EnterMobile/>}/>
        <Route path='verify-otp' element={<OTP/>}/>
        <Route path='terms-and-conditions' element={<TermsAndCondition/>}/>
        <Route path='privacy-policy' element={<PrivacyPolicy/>}/>
        <Route path='admin/*' element={<AdminProtectedRoute><AdminRoutes/></AdminProtectedRoute>}/>
        <Route path='student/*' element={<StudentRoutes/>}/>
        <Route path='*' element={<NotFound/>}/>
    </Routes>
  </>
}

export default AppRoutes