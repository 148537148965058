import React, { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import wrench from "../../images/wrench.png";
import gologo from "../../images/go-logo.svg";
import adminuser from "../../images/admin-user-management.svg";
import businessowner from "../../images/business-owner.svg";
import reportIcon from "../../images/report-icon.svg";
import WrenchIcon from "../../images/wrenchWhite.png";

function Sidebar() {
  const navigate = useNavigate();
  const location = useLocation();
  const [drop, setDrop] = useState(false);
  return (
    <>
      <div className="adminmenu">
        <div>
          <ul>
          <li
              className={
                location.pathname === "/admin/reports" ? "menuactive" : ""
              }
              onClick={() => {
                navigate("/admin/reports");
              }}
            >
              <div>
                <img src={reportIcon} alt="reporticon" />
              </div>
              <div>Reports</div>
            </li>
            <li
              className={
                location.pathname === "/admin/admin-user-management"
                  ? "menuactive"
                  : ""
              }
              onClick={(e) => {
                navigate("/admin/admin-user-management");
              }}
            >
              <div>
                <img src={adminuser} alt="adminuser" />
              </div>
              <div>Admin User Management</div>
            </li>
            <li
              className={
                location.pathname === "/admin/business-owner-management"
                  ? "menuactive"
                  : ""
              }
              onClick={(e) => {
                navigate("/admin/business-owner-management");
              }}
            >
              <div>
                <img src={businessowner} alt="businessowner" />
              </div>
              <div>Business Owner Management</div>
            </li>

            <li
              onClick={() => {
                setDrop((prev) => !prev);
              }}

              className={`${location.pathname === "/admin/watch-latest-section" || location.pathname === "/admin/announcement-section" || location.pathname === "/admin/calendar-section" || location.pathname === "/admin/resource-section" ? "menuactive" : ""} ${drop ? 'open' : ''} d-block ps-0`}
            >
              <div className="d-flex align-items-center h-100 ps-30">
                <div className="pe-3">
                  <img src={WrenchIcon} alt="adminuser" />
                </div>
                <div>Content Management Tool <i className="fa fa-chevron-down ps-2" aria-hidden="true"></i></div>
              </div>
              {
                drop ? <div>
                  <ul className="submenu">
                    <li onClick={() => {
                      navigate("/admin/watch-latest-section");
                    }} className={
                      location.pathname === "/admin/watch-latest-section" ? "subMenuActive" : ""
                    }><i className="fa fa-chevron-right" aria-hidden="true"></i> Watch latest section</li>
                    <li onClick={() => {
                      navigate("/admin/announcement-section");
                    }} className={
                      location.pathname === "/admin/announcement-section" ? "subMenuActive" : ""
                    }><i className="fa fa-chevron-right" aria-hidden="true"></i> Announcement section</li>
                    <li onClick={() => {
                      navigate("/admin/calendar-section");
                    }} className={
                      location.pathname === "/admin/calendar-section" ? "subMenuActive" : ""
                    }><i className="fa fa-chevron-right" aria-hidden="true"></i> Calendar section</li>
                    {/* <li onClick={() => {
                      navigate("/admin/resource-section");
                    }} className={
                      location.pathname === "/admin/resource-section" ? "subMenuActive" : ""
                    }><i className="fa fa-chevron-right" aria-hidden="true"></i> Resource section</li> */}
                  </ul>
                </div> : <></>
              }

            </li>

          </ul>
        </div>
        <div className="flexSection footerlogo">
          <img src={gologo} alt="gologo" />
        </div>
      </div>
    </>
  );
}

export default Sidebar;
