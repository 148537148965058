import React, { useEffect, useState } from "react";
import plusIcon from "../../images/plus-icon.svg";
import actionIcon from "../../images/action-icon.svg";
import AddAdminUser from "./AddAdminUser";
import AdminUser from "./AdminUser";
import AxiosService from './../../service/ApiService'
import { toast } from 'react-toastify'
import { useLogout } from "../../hooks/useLogout";
import PaginatedTable from "../common/PaginatedTable";

function AdminUserManagement() {
  const [maxPageNumberLimit, setMaxPageNumberLimit] = useState(5);
  const [minPageNumberLimit, setMinPageNumberLimit] = useState(0);
  let [addToggle, setAddToggle] = useState(false);
  let [viewToggle, setViewToggle] = useState(false);
  let [totalCount, setTotalCount] = useState(0)
  let [currentPage, setCurrentPage] = useState(1)
  let [itemsPerPage, setItemsPerPage] = useState(10)
  let [searchKey, setSearchKey] = useState(null)
  let [users, setUsers] = useState([]);
  let [userid, setuserid] = useState("")
  let Logout = useLogout()
  let [isLoading,setIsLoading] = useState(true)

  let [statusFilter, setStatusFilter] = useState("All");

  const column = [
    {
      heading: () => '#',
      cell: (row,i) => {
        return i;
      },
      hidden: false,
      width: "1"
    },
    {
      heading: () => "First Name",
      cell: row => row['firstname'],
      hidden: false,
      width: "7"
    },
    {
      heading: () => "Last Name",
      cell: row => row['lastname'],
      hidden: false,
      width: "7"
    },
    {
      heading: () => "Email",
      cell: row => row['email'],
      hidden: false,
      width: "7"
    },
    {
      heading: () => "Phone Number",
      cell: row => row['mobile'],
      hidden: false,
      width: "7"
    },
    {
      heading: () => {
        return <select
          name="cars"
          id="cars"
          defaultValue={""}
          className="selectBlock cursor"
          onChange={(e) => {
            setStatusFilter(e.target.value);
          }}

        >
          <option value="" disabled>Status</option>
          <option value="All">All</option>
          <option value="Active">Active</option>
          <option value="Inactive">Inactive</option>
        </select>
      },
      cell: row => {
        return <>
          {" "}
          <label className="toggle">
            <input type="checkbox"
              name="active_flag"
              defaultChecked={row?.active_flag === "Y"}
              onChange={(ele) => {
                if (ele.target.checked) {
                  handleActiveToggle("Y", row.id);
                  setuserid(row.id)
                } else {
                  handleActiveToggle("N", row.id);
                  setuserid(row.id)
                }

              }}
              value={row.active_flag}
            />
            <span className="slider"></span>
            <span
              className="labels"
              data-on="Active"
              data-off="Inactive"
            ></span>
          </label>{" "}
        </>
      },
      hidden: false,
      width: "5"
    },
    {
      heading: () => "Actions",
      cell: row => {
        return <img
          src={actionIcon}
          className="cursorPointer"
          alt="actions"
          onClick={() => {
            setViewToggle(true);
            setuserid(row.id)
          }}
        />
      },
      hidden: false,
      width: "1"
    }

  ]


  const handleGetAllAdmin = async () => {
    try {
      setIsLoading(prev=>!prev)
      let res = await AxiosService.post(`/admin/all`, {
        pgno: currentPage,
        limit: itemsPerPage,
        status: statusFilter,
        searchKey
      })
      if (res.data.statusCode === 200) {
        setUsers(res.data.users)
        setTotalCount(res.data.count)
        setIsLoading(prev=>!prev)
      }

    } catch (error) {
      if (error.response.status === 401)
      Logout()
      toast.error(error.response.data.message ? error.response.data.message : error.response.data.message[0]);
      setIsLoading(prev=>!prev)
    }
  }

  /* eslint-disable */
  useEffect(() => {
    if (!addToggle && !viewToggle)
      handleGetAllAdmin()
  }, [addToggle, viewToggle, currentPage, itemsPerPage, searchKey, statusFilter])
  /* eslint-enable */

  const handleActiveToggle = async (status, id) => {
    try {
      let res = await AxiosService.post(`/admin/toggle`, {
        "userid": `${id}`,
        "togglestate": status === 'Y' ? true : false
      })
      if (res.status === 200) {
        handleGetAllAdmin()
        toast.success(res.data.message)
      }
    } catch (error) {
      toast.error(error.response.data.message ? error.response.data.message : error.response.data.message[0]);
    }
  }
  const handleAdminExport = async () => {
    try{
      let response = await AxiosService.get('/admin/download-admin',  {
        responseType: 'blob', // Set the response type to blob
      })
        
      // const blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      // const url = window.URL.createObjectURL(blob);

      // // Create a link element and simulate a click to download the file
      // const link = document.createElement('a');
      // link.href = url;
      // link.download = response.data.name;
      // link.click();

      // // Clean up the temporary URL
      // window.URL.revokeObjectURL(url);
     if(response.status === 200){
      const url = window.URL.createObjectURL(new Blob([response.data], 
         { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' }
        ));
      const link = document.createElement('a');
      
      link.href = url;
      link.setAttribute(
          'download',
          `Admin Users.xlsx`,
      );
      
      document.body.appendChild(link);
      link.click();
      
      link.remove();
     }
      
    }catch(error){
      if (error.response && error.response.data && error.response.data.message) {
        toast.error(error.response.data.message);
      } else if (error.response && error.response.status === 400) {
        toast.error('No data to Download');}
    }
  }
  return (
    <>
      <div className="adminContentSection">
        <div className="titleSection">
          <div className="titleText "> Admin User Management</div>
          <div className="flexSection">
            <div className="displayFlex alignCenter spaceBetween gap15">
              <button
                className="blackbuttonoutline"
                onClick={() => setAddToggle(true)}
              >
                <div>Add User </div>
                <div>
                  <img src={plusIcon} alt="plusicon" />
                </div>
              </button>
            </div>
          </div>
        </div>
        {isLoading?<PaginatedTable
          row={users}
          column={column}
          totalCount={totalCount}
          itemsPerPage={itemsPerPage}
          setItemsPerPage={setItemsPerPage}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          searchKey={searchKey}
          setSearchKey={setSearchKey}
          handleExport={handleAdminExport}
          maxPageNumberLimit={maxPageNumberLimit}
          setMaxPageNumberLimit={setMaxPageNumberLimit}
          minPageNumberLimit={minPageNumberLimit}
          setMinPageNumberLimit={setMinPageNumberLimit}
          isExport
        />:<div style={{textAlign:"center"}}>Loading...</div>}

      </div>

      {addToggle ? (
        <div>
          <AddAdminUser addToggle={setAddToggle} />
        </div>
      ) : (
        <></>
      )}

      {viewToggle ? (
        <div>
          <AdminUser viewToggle={setViewToggle} id={userid} />
        </div>
      ) : (
        <></>
      )}
    </>
  );
}

export default AdminUserManagement;
