import React from 'react'
import { Navigate } from 'react-router-dom'
// import { toast } from 'react-toastify'

function StudentProtectedRoute({children}) {
    
  let token = sessionStorage.getItem('token')
  let role = sessionStorage.getItem('role')

  return <>
    {
        (role==='student') && token!= null ?children:<Navigate to='sign-in'/>
    }
  </>
}

export default StudentProtectedRoute