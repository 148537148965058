import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import Header from './common/Header';
import Footer from './common/Footer';
import Copyright from './common/Copyright';
import AOS from 'aos';
import HubspotForm from 'react-hubspot-form';
import Banner from '../../images/landingPage/GetInvolved/banner.jpg';
import VolunteerIcon from '../../images/landingPage/GetInvolved/support.png';
import PartnershipIcon from '../../images/landingPage/GetInvolved/partnership-handshake.png';
import DonationIcon from '../../images/landingPage/GetInvolved/donation.png';
import SponserIcon from '../../images/landingPage/GetInvolved/hand.png';
import EventIcon from '../../images/landingPage/GetInvolved/calendar.png';
import { NavHashLink } from 'react-router-hash-link';
import 'aos/dist/aos.css';
import styles from './Partner.module.scss';

function GetInvolved() {
    useEffect(() => {
        AOS.init();
        window.scrollTo(0, 0);
    }, []);
    const scrollWithOffset = (el) => {
        const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset;
        const yOffset = -100;
        window.scrollTo({ top: yCoordinate + yOffset, behavior: 'smooth' });
    }

    return <>
        <Header />
        <section className={`${styles.section1} container-fluid`}>
            <div className={`container py-lg-5 py-xs-0`}>
                <div className='row'>
                    <div className='col-sm-6 col-xs-12'>
                        <h2 className='fs--36 mb-4 pt-lg-5 pt-xs-0 fontPoppins fw--600 lh-55' data-aos="fade-up"><span className='text--primary'>Get </span> Involved</h2>
                        <p className='fs--18 pb-4 text--white3 fontPoppins lh-35  pe-lg-5 pe-xs-0' data-aos="fade-up">Our approach is centered on ensuring that the businesses we enable and empower are truly setup for success. ​</p>
                        <div className='col-sm-12' data-aos="fade-up"><NavHashLink to="#joinus" scroll={el => scrollWithOffset(el)} className='btn--primary me-lg-2 me-xs-0' role='button' aria-label='Join us'>Join Us</NavHashLink> </div>
                    </div>
                    <div className='col-sm-6 col-xs-12 py-3 d-flex justify-content-end ps-lg-5 ps-xs-0 pe-lg-5 pe-xs-0 position-relative rightEffect'>
                        <img src={Banner} alt='Slider' className='w-100 height420 br--30' data-aos="fade-up" />
                    </div>
                </div>
            </div>
        </section>
        <section className={`${styles.section2} container-fluid bg--grey1`}>
            <div className={`container py-5`}>
                <div className='row d-flex justify-content-center pt-4'>
                    <div className='col-sm-4 col-xs-12 mb-4'>
                        <div className={`text-center bg-white br--10 p-4 ${styles.box}`}>
                            <div className={`${styles.icon} p-4 mb-4 bg--primary`}>
                                <img src={VolunteerIcon} alt='Volunteer'/>
                            </div>
                            <h3 className='fs--24 fw--600 pb-2 text-black fontPoppins lh-45' data-aos="fade-up">Volunteer </h3>
                            <p className='fs--18 pb-0 mb-0 text-black fontPoppins lh-35 ' data-aos="fade-up">Help entrepreneurs by offering your time and skills in mentoring or organizing events.</p>                            
                        </div>
                    </div>
                    <div className='col-sm-4 col-xs-12 mb-4'>
                        <div className={`text-center bg-white br--10 p-4 ${styles.box}`}>
                            <div className={`${styles.icon} p-4 mb-4 bg--primary`}>
                                <img src={PartnershipIcon} alt='Partnership'/>
                            </div>
                            <h3 className='fs--24 fw--600 pb-2 text-black fontPoppins lh-45' data-aos="fade-up">Partnership  </h3>
                            <p className='fs--18  pb-0 mb-0  text-black fontPoppins lh-35 ' data-aos="fade-up">Collaborate with GoStartUpGo to expand our mission, providing resources, expertise, or funding to support entrepreneurs.</p>
                        </div>
                    </div>
                    <div className='col-sm-4 col-xs-12 mb-4'>
                        <div className={`text-center bg-white br--10 p-4 ${styles.box}`}>
                            <div className={`${styles.icon} p-4 mb-4 bg--primary`}>
                                <img src={SponserIcon} alt='Volunteer'/>
                            </div>
                            <h3 className='fs--24 fw--600 pb-2 text-black fontPoppins lh-45' data-aos="fade-up">Sponsor an entrepreneur </h3>
                            <p className='fs--18  pb-0 mb-0  text-black fontPoppins lh-35 ' data-aos="fade-up">Directly support a budding entrepreneur by covering their business kit or training costs.</p>
                        </div>
                    </div>
                    <div className='col-sm-4 col-xs-12 mb-4'>
                        <div className={`text-center bg-white br--10 p-4 ${styles.box}`}>
                            <div className={`${styles.icon} p-4 mb-4 bg--primary`}>
                                <img src={DonationIcon} alt='DonationIcon'/>
                            </div>
                            <h3 className='fs--24 fw--600 pb-2 text-black fontPoppins lh-45' data-aos="fade-up">Donate Resources </h3>
                            <p className='fs--18  pb-0 mb-0  text-black fontPoppins lh-35 ' data-aos="fade-up">Provide equipment, tools, or other materials that can help our entrepreneurs succeed.</p>
                        </div>
                    </div>
                    <div className='col-sm-4 col-xs-12 mb-4'>
                        <div className={`text-center bg-white br--10 p-4 ${styles.box}`}>
                            <div className={`${styles.icon} p-4 mb-4 bg--primary`}>
                                <img src={EventIcon} alt='Volunteer'/>
                            </div>
                            <h3 className='fs--24 fw--600 pb-2 text-black fontPoppins lh-45' data-aos="fade-up">Host an Event </h3>
                            <p className='fs--18  pb-0 mb-0  text-black fontPoppins lh-35 ' data-aos="fade-up">Organize or sponsor events that bring together entrepreneurs and industry experts to share knowledge and resources.</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section className={`container p-lg-5 p-4`}  id="joinus">
                <div className={`row`}>
                    <div className='col-sm-8 col-xs-12 offset-sm-2'>
                    <div className={`${styles.section6}  p-lg-5 p-2`}>
                        <h2 className='fs--24 pb-3 px-3 text-black fontPoppins lh-25' data-aos="fade-up"><span>Get Involved Inquiries </span></h2>
                        <p className='fs--16 pb-3 px-3 text-black fontPoppins lh-25' data-aos="fade-up">Please fill out the form</p>
                        <div data-aos="fade-up">
                            <div className={`${styles.findOutMoreRight} px-3 h-100`}>
                                <HubspotForm
                                    portalId='23639501'
                                    formId='620c4f3e-b079-4d74-96bb-e03fb2320a40'
                                    onSubmit={() => console.log('Submit!')}
                                    onReady={(form) => console.log('Form ready!')}
                                    loading={<div>Loading...</div>}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        {/* <section className={`${styles.section5} container-fluid bg--grey1 py-5`} id="findOutMore">
            <div className={`container py-5 text-start br--20`}>
                <div className='row'>
                    <div className={`${styles.section5Inner} shadow-sm col-sm-12 col-xs-12 bg-white p-5 br--20`}>
                        <h3 className='fs--30 fw--600 pb-1 text-black fontPoppins lh-45 pe-lg-5 pe-xs-0' data-aos="fade-up">Newsletter Sign-Up </h3>
                        <p className='fs--18 text--white3' data-aos="fade-up">Stay updated on how your contributions are making a difference. Sign up for our newsletter to receive stories of success, updates on new programs, and opportunities to get involved.</p>
                        <div data-aos="fade-up">
                            <div className={`${styles.findOutMoreRight} pt-4 h-100`}>
                                <HubspotForm
                                    portalId='23639501'
                                    formId='07bd4aff-5810-45d7-b4c5-c001794afad8'
                                    onSubmit={() => console.log('Submit!')}
                                    onReady={(form) => console.log('Form ready!')}
                                    loading={<div>Loading...</div>}
                                />          
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section> */}
        <section className={`${styles.section10} container-fluid bgGradient--primary`}>
            <div className={`container py-5`}>
                <div className='row'>
                <div className='col-sm-6 col-xs-12'>
                    <h2 className='fs--30 mb-3 fontMontserrat text-white fw--600 lh-38' data-aos="fade-up">Go Start Up Go is the ideal partner to turn your small business dreams into reality.</h2>
                    <p className='fs--16 text--grey1 mb-0 fontMontserrat lh-25' data-aos="fade-up">With Go Start Up Go, you'll have the support and tools necessary to be the best version of yourself and realize your full potential.</p>
                </div>
                </div>
            </div>
        </section>
        <Footer />
        <Copyright />
    </>
}

export default GetInvolved