import React, { useState, useRef, useEffect } from "react";
import popupclose from "../../images/popupclose-icon.svg";
import editicon from "../../images/edit-icon.svg";
import resetpassword from "../../images/password-reset.svg";
import eyeicon from "../../images/eye-icon.svg";
import tickicon from "../../images/tick-icon.svg";
import countryicon from "../../images/country-icon.svg";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useLogout } from "../../hooks/useLogout";
import AxiosService from "./../../service/ApiService";
import tickicongreen from "../../images/tick-icon-green.svg";
import eyeopen from "../../images/eye-open.svg";
import { toast } from "react-toastify";
import { mobileNumber } from "../utils/Regex";
import { useNavigate } from "react-router-dom";

function BusinessOwner({ viewToggle, id }) {
  const navigate = useNavigate()
  const [user, setUser] = useState([]);

  const Logout = useLogout();
  //reset password
  const [isedit, setIsedit] = useState(true);
  const [isreset, setIsreset] = useState(false);
  const [displayForm, setDisplayForm] = useState("");
  const [displayReset, setDisplayReset] = useState("none");

  const passref = useRef("");
  const cpassref = useRef("");
  const [width, setWidth] = useState(0);
  const [char, setChar] = useState(false);
  const [oneNum, setOneNum] = useState(false);
  const [splChar, setSplChar] = useState(false);
  const [upLow, setUpLow] = useState(false);
  const [passwordVisibilty1, setPasswordVisibilty1] = useState(true);
  const [passwordVisibilty2, setPasswordVisibilty2] = useState(true);

  //select
  const [countries, setCountries] = useState([]);

  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);

  //getbussiness owner by id

  const handleGetUserById = async () => {
    try {
      let res = await AxiosService.get(`/business-owners/${id}`)
      // user?.appaccess=== "Y" ? true : user?.appAccess === "N" ? false : "naresh"
      if (res.data.statusCode === 200) {
        if (res?.data?.user[0].appaccess === "Y") {
          let data = res?.data?.user[0]
          data["appaccess"] = true
          // formik.setFieldValue("appAccess", true)
          setUser(data)
        } else if (res?.data?.user[0].appaccess === "N") {
        let data = res?.data?.user[0]
        data["appaccess"] = false
        // formik.setFieldValue("appAccess", false)
        setUser(data)
         }

        

      }
    } catch (error) {
      console.log(error);
      toast.error(
        error.response.data.message
          ? error.response.data.message
          : error.response.data.message[0]
      );
    }
  };
  /* eslint-disable */
  useEffect(() => {
    if (id) {
      handleGetUserById();
    }
  }, []);
  /* eslint-enable */
  const handleCountries = async () => {
    try {
      let res = await AxiosService.get("address/countries");
      if (res.data.statusCode === 200) {
        setCountries(res.data.country);
      }
    } catch (error) {
      console.log(error);
      toast.error(
        error.response.data.message
          ? error.response.data.message
          : error.response.data.message[0]
      );
    }
  };

  const handleStates = async (countryid) => {
    try {
      // console.log("countryId",countryId);
      let res = await AxiosService.get(`address/states/${countryid}`);
      // console.log("states",res);
      if (res.data.statusCode === 200) {
        setStates(res.data.state);
      }
    } catch (error) {
      console.log(error);
      toast.error(
        error.response.data.message
          ? error.response.data.message
          : error.response.data.message[0]
      );
    }
  };

  const handleCities = async (stateid) => {
    try {
      let res = await AxiosService.get(`address/cities/${stateid}`);

      if (res.data.statusCode === 200) {
        setCities(res.data.city);
        // console.log(res.data.city);
      }
    } catch (error) {
      console.log(error);
      toast.error(
        error.response.data.message
          ? error.response.data.message
          : error.response.data.message[0]
      );
    }
  };

  useEffect(() => {
    handleCountries();
  }, []);


  const handleEditBusinessOwner = async (values) => {
    try {
      let res = await AxiosService.put(`business-owners/edit/${id}`, values)
      if (res.data.statusCode === 200) {
        navigate('/admin/business-owner-management')
        handleClose()
        toast.success(res.data.message)
      }
    } catch (error) {
      console.log(error)
      toast.error(
        error.response.data.message
          ? error.response.data.message
          : error.response.data.message[0]
      );
    }
  };


  const formik = useFormik({
    initialValues: {
      dateOfSignUp: user?.createdAt?.split('-').reverse().join('-'),
      firstname: user?.firstname ? user?.firstname : "" ,
      lastname: user?.lastname ?  user?.lastname : "",
      businessName: user?.businessName ? user?.businessName  : "",
      email: user?.email ? user?.email : "",

      mobile: user?.mobile,
      country: user?.country ?  user?.country : "",
      address: user?.address ? user?.address : "",
      state: user?.state ? user?.state : "",
      city: user?.city ? user?.city : "",
      zipcode: user?.zipcode ? user?.zipcode : "",
      appAccess: user?.appaccess,
    },
    enableReinitialize: true,
    validationSchema: Yup.object({
      dateOfSignUp: Yup.string().required("Please enter the signup date"),
      firstname: Yup.string().required("Please enter the first name"),
      lastname: Yup.string().required("Please enter the last name"),
      businessName: Yup.string().required("Please enter the business name"),
      email: Yup.string().required("Please enter the email"),

      mobile: Yup.string()
        .required("Please enter the mobile")
        .matches(mobileNumber, "Invalid mobile number"),
      country: Yup.string().required("Please enter the country"),
      address: Yup.string().required("Please enter the street address"),
      state: Yup.string().required("Please enter the state"),
      city: Yup.string().required("Please enter the city"),
      zipcode: Yup.string().required("Please enter the zipcode"),
      // appAccess: Yup.boolean(),
    }),
    onSubmit: (values) => {
      handleEditBusinessOwner(values);
    },
  });


  const createPassword = useFormik({
    initialValues: {
      password: "",
      confirmPassword: "",
    },
    enableReinitialize: true,

    validationSchema: Yup.object({
      password: Yup.string()
        .required("Please enter the password")
        .min(8, "Password must be 8 characters long")
        .matches(/[0-9]/, "Password requires a number")
        .matches(/[a-z]/, "Password requires a lowercase letter")
        .matches(/[A-Z]/, "Password requires an uppercase letter")
        .matches(/[^\w]/, "Password requires a symbol"),
      confirmPassword: Yup.string()
        .required("Please enter the confirm password")
        .oneOf([Yup.ref("password"), null], "Must match Password field value"),
    }),
    onSubmit: (values) => {
      handleResetPassword(values);
    },
  });

  const handleResetPassword = async ({ password, confirmPassword }) => {
    try {
      // console.log(password, confirmPassword);
      let mobile = user?.mobile
      let res = await AxiosService.post("/users/set-password", {
        mobile,
        password,
        confirmPassword,
      });
      if (res.status === 200) {
        toast.success(res.data.message);
        viewToggle((prev) => !prev);
      }
    } catch (error) {
      if (error.response.status === 401) Logout();
      toast.error(
        error.response.data.message
          ? error.response.data.message
          : error.response.data.message[0]
      );
    }
  };

  useEffect(() => {
    if (formik.values.country !== "" && formik.values.country !== undefined) {
      let country = countries.filter((e, i) => {
        return e.name === formik.values.country;
      });
      if (country[0]?.id !== undefined) {
        handleStates(country[0]?.id);
      }
    }
  }, [formik.values?.country, countries]);

  useEffect(() => {
    if (formik.values?.state !== "" && formik.values.state !== undefined) {
      // console.log(formik.values.state)
      let state = states.filter((e, i) => {
        return e.name === formik.values?.state;
      });

      if (state[0]?.id !== undefined && state !== []) {
        handleCities(state[0]?.id);
      }
    }
  }, [formik.values?.state, states]);

  //dynamic slidebar
  let value = [0, 0, 0, 0];
  /* eslint-disable */
  useEffect(() => {
    if (createPassword.values?.password.length > 7) {
      setChar(true);
      value[0] = 25;
    } else {
      setChar(false);
      value[0] = 0;
    }
    if (/[0-9]/.test(createPassword.values?.password)) {
      setOneNum(true);
      value[1] = 25;
    } else {
      setOneNum(false);
      value[1] = 0;
    }
    if (
      /[a-z]/.test(createPassword.values?.password) &&
      /[A-Z]/.test(createPassword.values?.password)
    ) {
      setUpLow(true);
      value[2] = 25;
    } else {
      setUpLow(false);
      value[2] = 0;
    }

    if (/[^\w]/.test(createPassword.values?.password)) {
      setSplChar(true);
      value[3] = 25;
    } else {
      setSplChar(false);
      value[3] = 0;
    }
    setWidth(
      value.reduce((acc, cur) => {
        return acc + cur;
      }, 0)
    );
  }, [createPassword.values?.password]);
  /* eslint-enable */

  const handleClose = () => {
    viewToggle((prev) => !prev);
    formik.resetForm();
  };


  return (
    <>
      <div className="divoverlay">
        <div className="divoverlayBlock">
          <div className="popupheaderblock">
            <div> Business Owner </div>

            <img
              src={popupclose}
              alt="popup"
              className="cursorPointer"
              onClick={() => handleClose()}
            />
          </div>

          <div className="container-fluid">
            <div className="displayFlex  alignCenter gap15 w100per  mb15   ">
              <button
                className={
                  isedit
                    ? "roundorangeButton displayFlex  alignCenter gap15"
                    : "roundorangeButtonoutline displayFlex  alignCenter gap15"
                }
                onClick={() => {
                  setDisplayForm("");
                  setDisplayReset("none");
                  setIsedit(true);
                  setIsreset(false);
                }}
              >
                <div>
                  {" "}
                  <img src={editicon} alt="edit" />{" "}
                </div>
                <div>Edit</div>
              </button>

              <button
                className={
                  isreset
                    ? "roundorangeButton displayFlex  alignCenter gap15"
                    : "roundorangeButtonoutline displayFlex  alignCenter gap15"
                }
                onClick={() => {
                  setDisplayForm("none");
                  setDisplayReset("");
                  setIsedit(false);
                  setIsreset(true);
                }}
              >
                <div>
                  {" "}
                  <img src={resetpassword} alt="reset" />{" "}
                </div>
                <div>Reset Password </div>
              </button>
            </div>

            <form
              style={{ display: displayForm }}
              onSubmit={formik.handleSubmit}
            >
              <div className="userprofile">
                <div className="overdivScroll">
                  <div className="row mb40">

                    <div className="col-md-6">
                      <div className="form-group mb15">
                        <label htmlFor="exampleInputEmail1">
                          Date of Signup or Added
                        </label>
                        <input
                          type="date"
                          name="dateOfSignUp"
                          className="form-control"
                          // id="exampleInputEmail1"
                          aria-describedby="emailHelp"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.dateOfSignUp}
                          readOnly
                        />
                      </div>
                      {formik.errors.dateOfSignUp &&
                        formik.touched.dateOfSignUp ? (
                        <p className="error_text">
                          {formik.errors.dateOfSignUp}
                        </p>
                      ) : null}
                    </div>

                    <div className="col-md-6">
                      <div className="form-group mb15">
                        <label htmlFor="exampleInputEmail1">First name</label>
                        <input
                          type="text"
                          className="form-control"
                          aria-describedby="emailHelp"
                          placeholder="Enter First Name"
                          name="firstname"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.firstname}
                        />
                      </div>
                      {formik.errors.firstname && formik.touched.firstname ? (
                        <p className="error_text">{formik.errors.firstname}</p>
                      ) : null}
                    </div>

                    <div className="col-md-6">
                      <div className="form-group mb15">
                        <label htmlFor="exampleInputEmail1">Last name</label>
                        <input
                          type="text"
                          className="form-control"
                          aria-describedby="emailHelp"
                          placeholder="Enter Last Name"
                          name="lastname"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.lastname}
                        />
                      </div>
                      {formik.errors.lastname && formik.touched.lastname ? (
                        <p className="error_text">{formik.errors.lastname}</p>
                      ) : null}
                    </div>
                    <div className="col-md-6">
                      <div className="form-group mb15">
                        <label htmlFor="exampleInputEmail1">
                          Business Name
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          aria-describedby="emailHelp"
                          name="businessName"
                          placeholder="Enter Business Name"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.businessName}
                        />
                      </div>
                      {formik.errors.businessName &&
                        formik.touched.businessName ? (
                        <p className="error_text">
                          {formik.errors.businessName}
                        </p>
                      ) : null}
                    </div>
                    <div className="col-md-6">
                      <div className="form-group mb15">
                        <label htmlFor="exampleInputEmail1">Email</label>
                        <input
                          type="text"
                          className="form-control"
                          aria-describedby="emailHelp"
                          placeholder="Enter Email ID"
                          name="email"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.email}
                        />
                      </div>
                      {formik.errors.email && formik.touched.email ? (
                        <p className="error_text">{formik.errors.email}</p>
                      ) : null}
                    </div>
                    <div className="col-md-6">
                      <div className="phonenumber ">
                        <label htmlFor="exampleInputEmail1">Phone number</label>

                        <div className="displayFlex">
                          <div className="countryIcon">
                            {" "}
                            <img src={countryicon} alt="country" />
                          </div>
                          <div className="form-group mb15 w100per">
                            <input
                              type="text"
                              className="form-control borderleftcss"
                              id="exampleInputEmail1"
                              aria-describedby="emailHelp"
                              placeholder="Enter Phone Number"
                              name="mobile"
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              value={formik.values.mobile}
                            />
                          </div>
                        </div>
                        {formik.errors.mobile && formik.touched.mobile ? (
                          <p className="error_text">{formik.errors.mobile}</p>
                        ) : null}
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group mb15">
                        <label htmlFor="exampleInputEmail1">
                          Street Address
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          aria-describedby="emailHelp"
                          placeholder="Enter Street Address"
                          name="address"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.address}
                        />
                      </div>
                      {formik.errors.address && formik.touched.address ? (
                        <p className="error_text">{formik.errors.address}</p>
                      ) : null}
                    </div>
                    <div className="col-md-6">
                      <div className="form-group mb15">
                        <label htmlFor="exampleInputEmail1">Country</label>
                        <select
                          type="text"
                          className="form-select"
                          name="country"
                          aria-describedby="emailHelp"
                          placeholder="Enter Country"
                          onChange={(e) => {
                            // eslint-disable-next-line no-lone-blocks
                            {
                              // setSelectedCountry(e.target.value);
                              formik.setFieldValue("country", e.target.value);
                            }
                          }}
                          onBlur={formik.handleBlur}
                          value={formik.values.country}
                          selected={user?.country === formik.values.country}
                        >
                          <option>Select the Country</option>
                          {countries?.map((country, i) => {
                            return (
                              <option key={country.id} value={country.name}>
                                {" "}
                                {country.name}
                              </option>
                            );
                          })}
                        </select>
                      </div>
                      {formik.errors.country && formik.touched.country ? (
                        <p className="error_text_admin">
                          {" "}
                          {formik.errors.country}
                        </p>
                      ) : null}
                    </div>

                    <div className="col-md-6">
                      <div className="form-group mb15">
                        <label htmlFor="exampleInputEmail1">State</label>
                        <select
                          className="form-select"
                          name="state"
                          onChange={(e) => {
                            // eslint-disable-next-line no-lone-blocks
                            {
                              // setSelectedState(e.target.value);
                              formik.setFieldValue("state", e.target.value);
                            }
                          }}
                          onBlur={formik.handleBlur}
                          value={formik.values.state}
                          selected={user?.state === formik.values?.state}
                        >
                          <option>Select the State</option>
                          {states?.map((state, i) => {
                            return (
                              <option key={state.id} value={state.name}>
                                {" "}
                                {state.name}
                              </option>
                            );
                          })}
                        </select>
                      </div>
                      {formik.errors.state && formik.touched.state ? (
                        <p className="error_text_admin">
                          {" "}
                          {formik.errors.state}
                        </p>
                      ) : null}
                    </div>

                    <div className="col-md-6">
                      <div className="form-group mb15">
                        <label htmlFor="exampleInputEmail1">City</label>
                        <select
                          className="form-select"
                          name="city"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.city}
                          selected={user?.city === formik.values.city}
                        >
                          <option>Select the City</option>

                          {cities?.map((city, i) => {
                            return (
                              <option key={city.id} value={city.name}>
                                {city.name}
                              </option>
                            );
                          })}
                        </select>
                      </div>
                      {formik.errors.city && formik.touched.city ? (
                        <p className="error_text_admin">
                          {" "}
                          {formik.errors.city}
                        </p>
                      ) : null}
                    </div>

                    <div className="col-md-6">
                      <div className="form-group mb15">
                        <label htmlFor="exampleInputEmail1">Zip Code</label>
                        <input
                          type="text"
                          className="form-control"
                          aria-describedby="emailHelp"
                          placeholder="Enter Zip Code"
                          name="zipcode"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.zipcode}
                        />
                      </div>
                      {formik.errors.zipcode && formik.touched.zipcode ? (
                        <p className="error_text">{formik.errors.zipcode}</p>
                      ) : null}
                    </div>

                    <div className="col-md-6">
                      <div className="grandAccessbox">
                        <div> Grant App Access </div>
                        <div>
                          <label className="toggle">
                            {/* {user?.appaccess ? */}
                              <input
                                type="checkbox"
                                name="appAccess"
                                defaultChecked={user?.appaccess}
                                onClick={(e) => {
                                  if (e.target.checked) {
                                    formik.setFieldValue("appAccess", true)
                                  } else {
                                    formik.setFieldValue("appAccess", false)
                                  }
                                }}
                                // onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.appAccess}
                              />
                              {/* :
                              ""
                            } */}

                            <span className="slider"></span>
                            <span
                              className="labels"
                              data-on="Active"
                              data-off="Inactive"
                            ></span>
                          </label>

                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row mb40">
                  <div className="col-md-12">
                    <div className="displayFlex  alignCenter gap15 w100per   justifyflexend">
                      <button
                        className="primaryorangeButtonoutline"
                        onClick={() => {
                          handleClose();
                        }}
                      >
                        {" "}
                        Close{" "}
                      </button>

                      <button className="primaryorangeButton" type="submit">
                        {" "}
                        Save{" "}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </form>



            <form onSubmit={createPassword.handleSubmit}>
              <div className="resetPassword" style={{ display: displayReset }}>
                <div className="overdivScroll">
                  <div className="row mb40">
                    <div className="col-md-12">
                      <div className="resetPasswordSection">
                        <div className="resetTitle">Reset Your Password</div>

                        <div className="form-group mb15  w100per">
                          <label htmlFor="exampleInputEmail1">
                            Enter New Password
                          </label>
                          <div className="input-group mb-3">
                            <input
                              type={passwordVisibilty1 ? "password" : "text"}
                              className="form-control"
                              placeholder="Enter new password"
                              aria-label="Recipient's username"
                              aria-describedby="basic-addon2"
                              name="password"
                              ref={passref}
                              value={createPassword.values.password}
                              onChange={createPassword.handleChange}
                              onBlur={createPassword.handleBlur}
                            />
                            <div
                              className="input-group-append"
                              onClick={() => {
                                if (passwordVisibilty1) {
                                  setPasswordVisibilty1(false);
                                } else {
                                  setPasswordVisibilty1(true);
                                }
                              }}
                            >
                              <span
                                className="input-group-text"
                                id="basic-addon2"
                              >
                                <img
                                  src={passwordVisibilty1 ? eyeicon : eyeopen}
                                  alt="eye icon"
                                />
                              </span>
                            </div>
                          </div>
                        </div>
                        {createPassword.errors.password &&
                          createPassword.touched.password ? (
                          <p className="error_text">
                            {createPassword.errors.password}{" "}
                          </p>
                        ) : (
                          ""
                        )}

                        <div className="form-group mb15  w100per">
                          <label htmlFor="exampleInputEmail1">
                            Confirm new password
                          </label>
                          <div className="input-group mb-3">
                            <input
                              type={passwordVisibilty2 ? "password" : "text"}
                              className="form-control"
                              placeholder="Confirm new password"
                              aria-label="Recipient's username"
                              aria-describedby="basic-addon2"
                              name="confirmPassword"
                              rel={cpassref}
                              value={createPassword.values.confirmPassword}
                              onChange={createPassword.handleChange}
                              onBlur={createPassword.handleBlur}
                            />
                            <div
                              className="input-group-append"
                              onClick={() => {
                                if (passwordVisibilty2) {
                                  setPasswordVisibilty2(false);
                                } else {
                                  setPasswordVisibilty2(true);
                                }
                              }}
                            >
                              <span
                                className="input-group-text"
                                id="basic-addon2"
                              >
                                <img
                                  src={passwordVisibilty2 ? eyeicon : eyeopen}
                                  alt="eye"
                                />
                              </span>
                            </div>
                          </div>
                        </div>
                        {createPassword.errors.confirmPassword &&
                          createPassword.touched.confirmPassword ? (
                          <p className="error_text">
                            {createPassword.errors.confirmPassword}{" "}
                          </p>
                        ) : null}

                        <div className="displayFlex  flexdirectioncolumn">
                          <div className="blackColor textAlignLeft fs14 fontWeight500">
                            {" "}
                            Password Strength{" "}
                          </div>
                          <div className="progressBlock mb15">
                            <div
                              className="track"
                              style={{ width: "100% !important" }}
                            >
                              {" "}
                            </div>
                            <div
                              className="thumb"
                              style={{ width: `${width}%` }}
                            >
                              {" "}
                            </div>
                          </div>
                        </div>

                        {/* <div className="displayFlex alignCenter mb40  flexWrap ">
                          <div className="displayFlex alignCenter gap5 mr15px">
                            <div>
                              {" "}
                              <img src={tickicon} />{" "}
                            </div>
                            <div className="grayText fs12"> 8 Character </div>
                          </div>
                          <div className="displayFlex alignCenter gap5 mr15px">
                            <div>
                              {" "}
                              <img src={tickicon} />{" "}
                            </div>
                            <div className="grayText fs12"> One Number </div>
                          </div>
                          <div className="displayFlex alignCenter gap5 mr15px">
                            <div>
                              {" "}
                              <img src={tickicon} />{" "}
                            </div>
                            <div className="grayText fs12">
                              {" "}
                              One Special Character{" "}
                            </div>
                          </div>
                          <div className="displayFlex alignCenter gap5 mr15px">
                            <div>
                              {" "}
                              <img src={tickicon} />{" "}
                            </div>
                            <div className="grayText fs12">
                              {" "}
                              One Upper & Smaller case Character{" "}
                            </div>
                          </div>
                        </div> */}
                        <div className="displayFlex alignCenter mb30 flexWrap ">
                          <div className="displayFlex alignCenter gap5 mr15px">
                            <div>
                              {" "}
                              <img
                                src={char ? tickicongreen : tickicon}
                                alt="char"
                              />{" "}
                            </div>
                            <div
                              className={char ? "green_text" : "grayText fs12"}
                            >
                              {" "}
                              8 Character{" "}
                            </div>
                          </div>
                          <div className="displayFlex alignCenter gap5 mr15px">
                            <div>
                              {" "}
                              <img
                                src={oneNum ? tickicongreen : tickicon}
                                alt="one"
                              />{" "}
                            </div>
                            <div
                              className={
                                oneNum ? "green_text" : "grayText fs12"
                              }
                            >
                              {" "}
                              One Number{" "}
                            </div>
                          </div>
                          <div className="displayFlex alignCenter gap5 mr15px">
                            <div>
                              {" "}
                              <img
                                src={splChar ? tickicongreen : tickicon}
                                alt="spl"
                              />{" "}
                            </div>
                            <div
                              className={
                                splChar ? "green_text" : "grayText fs12"
                              }
                            >
                              {" "}
                              One Special Character{" "}
                            </div>
                          </div>
                          <div className="displayFlex alignCenter gap5 mr15px">
                            <div>
                              {" "}
                              <img
                                src={upLow ? tickicongreen : tickicon}
                                alt="up"
                              />{" "}
                            </div>
                            <div
                              className={upLow ? "green_text" : "grayText fs12"}
                            >
                              {" "}
                              One Upper & Smaller case Character{" "}
                            </div>
                          </div>
                        </div>

                        <div className="displayFlex  alignCenter gap15 w100per   justifyflexend">
                          <button className="primaryorangeButton" type="submit">
                            {" "}
                            Submit{" "}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="row mb40">
                    <div className="col-md-12"></div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}

export default BusinessOwner;
