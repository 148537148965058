import React, { useRef, useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom';
import UserBackdrop from './UserBackdrop'
import logo from "../../images/logo.svg";
import eyeicon from "../../images/eye-icon.svg";
import eyeopen from "../../images/eye-open.svg";
import tickicon from "../../images/tick-icon.svg";
import tickicongreen from "../../images/tick-icon-green.svg";
import { useFormik } from 'formik';
import * as Yup from 'yup';
import TermsAndPrivacyWrapper from './TermsAndPrivacyWrapper';
import AxiosService from './../../service/ApiService'
import {toast} from 'react-toastify'


function CreatePassword() {
  const [mobile, setMobile] = useState("")
  const passref = useRef("")
  const cpassref = useRef("")
  const [width, setWidth] = useState(0)
  const [char, setChar] = useState(false)
  const [oneNum, setOneNum] = useState(false)
  const [splChar, setSplChar] = useState(false)
  const [upLow, setUpLow] = useState(false)
  const [termsToggle,setTermsToggle] = useState(false)
  const [check,setCheck] = useState(false)

  const [passwordVisibilty1, setPasswordVisibilty1] = useState(true)
  const [passwordVisibilty2, setPasswordVisibilty2] = useState(true)


  const navigate = useNavigate();
  const createPassword = useFormik({
    initialValues: {
      password: "",
      confirmPassword: "",
      terms : ""

    },
    enableReinitialize: true,

    validationSchema: Yup.object({
      password: Yup.string().required("Please enter the password")
      .min(8, 'Password must be 8 characters long')
      .matches(/[0-9]/, 'Password requires a number')
      .matches(/[a-z]/, 'Password requires a lowercase letter')
      .matches(/[A-Z]/, 'Password requires an uppercase letter')
      .matches(/[^\w]/, 'Password requires a symbol'),
      confirmPassword: Yup.string().required("Please enter the confirm password")
        .oneOf([Yup.ref('password'), null], 'Must match Password field value'),
      terms: Yup.string()
      .required("Please select the terms and conditions you want to continue")


    }),
    onSubmit: (values) => {

      handleCreateAccount(values)
    }
  })
 

  const handleCreateAccount =async ({password,confirmPassword}) => {
    try {
      let mobile = sessionStorage.getItem('mobile')
      let res = await AxiosService.post('/users/set-password',{mobile,password,confirmPassword})
      if(res.status===200)
      {
        toast.success(res.data.message)
        navigate('/sign-in');
      }
    } catch (error) {
      console.error(error)
      toast.error(error.response.data.message?error.response.data.message:error.response.data.message[0])
    }
  }
  let value = [0, 0, 0, 0]
useEffect(() =>{
    if(createPassword.values?.password.length > 7){
      setChar(true)
      value[0] = 25
      
    }else{
      setChar(false)
      value[0] = 0
    
    }
    if(/[0-9]/.test(createPassword.values?.password)){
      
      setOneNum(true)
      value[1] = 25

    }else{
      setOneNum(false)
      value[1] = 0

      
    }
    if(/[a-z]/.test(createPassword.values?.password) && /[A-Z]/.test(createPassword.values?.password) ){
      
      setUpLow(true)
      value[2] = 25 
      
    }else{
      setUpLow(false)
      value[2] = 0 
      
      
    }

    if(/[^\w]/.test(createPassword.values?.password)){
      
      setSplChar(true)
      value[3] = 25 
    
    }else{
      setSplChar(false)
      value[3] = 0 
      
    }
    setWidth(value.reduce((acc, cur) =>{
      return acc + cur
    }, 0))


  
},[createPassword.values?.password])

useEffect(()=>{
  if(sessionStorage.getItem('mobile')){
    let mobileNumber = sessionStorage.getItem('mobile');

    // let fourDigits = String(mobileNumber).slice(-4)
    setMobile(String(mobileNumber).slice(0, -4).replace(/./g,"X")+String(mobileNumber).slice(-4))
  }
},[mobile]) 




  return <>
    <form onSubmit={createPassword.handleSubmit}>
      <div className='displayFlex'>
        <UserBackdrop />
        <div className='leftSection'>
          <div className='logoSection' >
            <img
              src={logo}
              alt='logo'

            />

          </div>

          <div className='signSection'>


            <div className='signBlock'>

              <div className='flexSection'>
                <div className='blackButton m15'>
                  Create New Password
                </div>

              </div>

              <div className='verificationText m15'>Welcome to Go Startup Go</div>

              <div className='content m15 underline   fontWeight600'>{mobile}</div>

              <div className="form-group mb15  w100per">


                <label htmlFor="exampleInputEmail1">Enter Password</label>
                <div className="input-group mb-3">

                  <input type={passwordVisibilty1 ? "password" : "text"} className="form-control"
                    name="password"
                    ref={passref}
                    placeholder="Enter Password"
                    aria-label="Recipient's username"
                    aria-describedby="basic-addon2"
                    value={createPassword.values.password}
                    onChange={
                     createPassword.handleChange
                  }
                    onBlur={createPassword.handleBlur}
                  />
                  <div className="input-group-append" onClick={()=>{
                    if(passwordVisibilty1){
                      setPasswordVisibilty1(false)
                  }else{
                    setPasswordVisibilty1(true)
                  }
                }}
                >
                    <span className="input-group-text" id="basic-addon2">

                      <img
                        src={passwordVisibilty1 ? eyeicon : eyeopen}
                        alt='eye icon'


                      />


                    </span>
                  </div>

                </div>
              </div>
              {
                createPassword.errors.password && createPassword.touched.password ? (
                  <p className="error_text">{createPassword.errors.password} </p>

                ) : ""

              }
              <div className="form-group mb15  w100per">


                <label htmlFor="exampleInputEmail1">Re-Enter Password</label>
                <div className="input-group mb-3">

                  <input type={passwordVisibilty2 ? "password" : "text"}
                    className="form-control"
                    placeholder="Re-Enter Password"
                    name="confirmPassword"
                    aria-label="Recipient's username"
                    aria-describedby="basic-addon2"
                    rel={cpassref}

                    value={createPassword.values.confirmPassword}
                    onChange={createPassword.handleChange}
                    onBlur={createPassword.handleBlur}

                  />
                  <div className="input-group-append" onClick={()=>{
                    if(passwordVisibilty2){
                      setPasswordVisibilty2(false)
                  }else{
                    setPasswordVisibilty2(true)
                  }
                }}>
                    <span className="input-group-text" id="basic-addon2">

                      <img
                        src={passwordVisibilty2 ? eyeicon : eyeopen}
                        alt='eye icon'

                      />


                    </span>
                  </div>
                </div>
              </div>
              {
                createPassword.errors.confirmPassword && createPassword.touched.confirmPassword ? (
                  <p className="error_text">{createPassword.errors.confirmPassword} </p>

                ) : null

              }

              <div className='displayFlex  flexdirectioncolumn'>
                <div className='blackColor textAlignLeft fs14 fontWeight500'> Password Strength </div>
                <div className='progressBlock mb15' >
                  <div className='track' style={{width:"100% !important"}}> </div>
                  <div className='thumb' style={{width:`${width}%`}}> </div>

                </div>

              </div>


              <div className='displayFlex alignCenter mb30 flexWrap '>
                <div className='displayFlex alignCenter gap5 mr15px'>
                  <div> <img src={char? tickicongreen : tickicon} alt='tick' />  </div>
                  <div className={char ? "green_text" : 'grayText fs12'}> 8 Character   </div>
                </div>
                <div className='displayFlex alignCenter gap5 mr15px'>
                  <div> <img src={oneNum? tickicongreen : tickicon} alt='tick'/>  </div>
                  <div className={oneNum ? "green_text" : 'grayText fs12'}> One Number   </div>
                </div>
                <div className='displayFlex alignCenter gap5 mr15px'>
                  <div> <img src={splChar? tickicongreen : tickicon} alt='tick'/>  </div>
                  <div className={splChar ? "green_text" : 'grayText fs12'}> One Special Character   </div>
                </div>
                <div className='displayFlex alignCenter gap5 mr15px'>
                  <div> <img src={upLow? tickicongreen : tickicon} alt='tick'/>  </div>
                  <div className={upLow ? "green_text" : 'grayText fs12'}> One Upper & Smaller case Character   </div>
                </div>


              </div>

              <div className='displayFlex alignCenter gap15 flexStart mb30' >
                <div>
                  <input type='checkbox'
                  name = "terms"
                  checked = {check}
                  onChange={()=>{
                    check ? setCheck(false) : setCheck(true)
                    if(check){
                      createPassword.setFieldValue(
                        "terms",
                        false
                      );
                    }else{
                      createPassword.setFieldValue(
                        "terms",
                        true);
                    }
                  }}
                  onBlur={createPassword.handleBlur}
                  value={createPassword.values.terms}

                  />  </div>
                <div className='fs14' > By clicking this box, I agree with the <span className='underline cursorPointer' onClick={() => {
                  setTermsToggle(prev=>!prev)
                }}>Term & Condition</span>  and <span className='underline cursorPointer' onClick={() => {
                  setTermsToggle(prev=>!prev)
                }}>Privacy Policy</span> .</div>
              </div>
              {
                createPassword.errors.terms && createPassword.touched.terms && (
                  <p className="error_text">{createPassword.errors.terms} </p>

                ) 

              }
              <button className='orangeButton' type='submit'>Set Password</button>
            </div>
          </div>
        </div>
      </div>
    </form>

    {
      termsToggle?<TermsAndPrivacyWrapper setTermsToggle = {setTermsToggle} setCheck = {setCheck}/>:<></>
    }



  </>
}

export default CreatePassword