/* eslint-disable default-case */
import React, {useState} from "react";
import { useNavigate } from "react-router-dom";
import UserBackdrop from "./UserBackdrop";
import logo from "../../images/logo.svg";
import countryicon from "../../images/country-icon.svg";
import eyeicon from "../../images/eye-icon.svg";
import eyeopen from "../../images/eye-open.svg";
import { useFormik } from "formik";
import * as Yup from "yup";
import { email, mobileNumber } from "../utils/Regex";

import AxiosService from './../../service/ApiService'
import {toast} from 'react-toastify'


function SignIn() {
  const navigate = useNavigate();
  const [pass, setPass] = useState(true);
  const [iserr, setiserr] = useState(false)

  const handleSignIn = async ({mobile,password}) =>{
    try{
        let res = await AxiosService.post('/users/signin',{mobile:mobile.toString(),password,device:'WEB'})
        if(res.status===200)
        {
          toast.success(res.data.message)
          sessionStorage.setItem('mobile',res.data.mobile)
          sessionStorage.setItem('token',res.data.token)
          sessionStorage.setItem('role',res.data.role)
          sessionStorage.setItem('username',res.data.name !== undefined ?res.data.name : res.data.firstname+ " " + res.data.lastname  )

          if(res.data.role === 'admin' || res.data.role === 'superadmin')
          {
            if(res.data.verify_flag==='Y')
            {
              navigate('/admin')
            }
            else
            {
              navigate('/create-password')
            }
          }
          else if(res.data.role === 'student')
          {
            if(res.data.verify_flag==='Y')
            {
              navigate('/student')
            }
            else
            {
              navigate('/create-password')
            }
          }
          else
          {
            toast.error("Invalid User")
          }
        }
    }catch(error){
      toast.error(error.response.data.message?error.response.data.message:error.response.data.message[0]);
    }
  }

  const formik = useFormik({
    initialValues:{
      mobile : "",
      password : "",

    },
    enableReinitialize : true,

    validationSchema : Yup.object({
      mobile : Yup.string()
      .required("Please enter the phone number/email")
      
      
      
  ,
      password : Yup.string()
      .required("Please enter the password")
      
    }),
    onSubmit : (values)=>{
      if(!iserr)
        handleSignIn(values);
    }
  });




  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <div className="displayFlex">
          <UserBackdrop />
          <div className="leftSection">
            <div className="logoSection">
              <img src={logo} alt="logo" />
            </div>

            <div className="signSection">
              <div className="signTextblock">
                <div
                  className="signtext cursor"
                  onClick={() => {
                    navigate("/sign-up");
                  }}
                >
                  Sign up
                </div>
                <div className="signtext signtextActive cursor"> Sign IN</div>
              </div>

              <div className="signBlock">
                <div className="welcometext pb30">
                  {" "}
                  Welcome to Go Startup Go
                </div>

                <div className="phonenumber ">
                  <label htmlFor="exampleInputEmail1">Phone number/Email</label>

                  <div className="displayFlex">
                    <div className="countryIcon">
                      {" "}
                      <img src={countryicon} alt="country icon" />
                    </div>
                    <div className="form-group mb15 w100per">
                      <input
                        type="text"
                        name="mobile"
                        className="form-control borderleftcss"
                        id="exampleInputEmail1"
                        aria-describedby="emailHelp"
                        placeholder="Enter Phone Number/Email"
                        value={formik.values.mobile}
                        onBlur={formik.handleBlur}
                        onChange={(e) => {
                          switch(e.target.value !== ""){
                            case mobileNumber.test(e.target.value) : 
                            formik.setFieldValue("mobile", e.target.value)
                            setiserr(false)
                            break;
                            case email.test(e.target.value) : 
                            formik.setFieldValue("mobile", e.target.value)
                            setiserr(false)
                            break;
                            case /^[0-9]*$/g.test(e.target.value) && e.target.value?.length <= 10:
                              formik.setFieldValue("mobile", e.target.value)
                              setiserr(false)
                              break
                            default : 
                            formik.setFieldValue("mobile", e.target.value)
                            setiserr(true)
                          }                          
                        }}
                       
                                              
                     />

                    </div>
                  </div>
                  {
                formik.errors.mobile && formik.touched.mobile ? (
                  <p className="error_text">{formik.errors.mobile} </p>

                ) : <></>

              }
              {
                iserr ?  <p className="error_text">Invalid phone number/email</p>

                 : <></>
              }
                </div>
              


                <div className="form-group mb10  w100per">
                  <label htmlFor="exampleInputEmail1">Password</label>
                  <div className="input-group mb-3">
                    <input
                      type={pass? "password" : "text"}
                      name="password"
                      className="form-control"
                      placeholder="Enter Password"
                      aria-label="Recipient's username"
                      aria-describedby="basic-addon2"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.password}
                    />
                    <div className="input-group-append" onClick={()=>{
                      pass? setPass(false):setPass(true)
                    }}>
                      <span className="input-group-text" id="basic-addon2">
                        <img src={pass? eyeicon : eyeopen} alt="eye icon" />
                      </span>
                    </div>
                  </div>
                  {
                formik.errors.password && formik.touched.password ? (
                  <p className="error_text">{formik.errors.password} </p>

                ) : <></>

              }
                </div>
                <div className="textAlignRight forgetpassword">
                  <span className="cursor"
                    onClick={() => {
                      navigate("/enter-mobile");
                    }}
                  >Forgot password</span>
                </div>


                <button className="orangeButton" type="submit">Sign In</button>
              </div>
            </div>
          </div>
        </div>
      </form>
    </>
  );
}

export default SignIn;
