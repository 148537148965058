import React, { useState } from 'react'
import { Routes, Route, Navigate } from "react-router-dom";
import AdminUserManagement from '../../admin/AdminUserManagement'
import BusinessOwnerManagement from '../../admin/BusinessOwnerManagement'
import Reports from '../../admin/Reports'
import Navbar from '../../admin/Navbar';
import Sidebar from '../../admin/Sidebar';
import AnnouncementSection from '../../admin/ContentManagementTool/AnnouncementSection';
import CalendarSection from '../../admin/ContentManagementTool/CalendarSection/CalendarSection';
import ResourceSection from '../../admin/ContentManagementTool/ResourceSection';
import WatchLatestSection from '../../admin/ContentManagementTool/WatchLatestSection';
import CalendarById from '../../admin/ContentManagementTool/CalendarSection/CalendarById';
import AddWatchLatestSection from '../../admin/ContentManagementTool/AddWatchLatestSection';
import AddAnnouncementSection from '../../admin/ContentManagementTool/AddAnnouncementSection';
import AddCalendar from '../../admin/ContentManagementTool/CalendarSection/AddCalendar';
function AdminRoutes() {
  let [toggleSideBar, setToggleSideBar] = useState(true)
  return <>
    <Navbar toggleSideBar={setToggleSideBar} />
    <div className="displayFlex">
      <div className='leftsection'>
        {toggleSideBar ? <div><Sidebar /></div> : <></>}

      </div>
      <div className='rightsection'>
        <Routes>
          <Route path='admin-user-management' element={<AdminUserManagement />} />
          <Route path='business-owner-management' element={<BusinessOwnerManagement />} />
          <Route path='reports' element={<Reports />} />
          <Route path='announcement-section' element={<AnnouncementSection />} />
          <Route path='calendar-section' element={<CalendarSection />} />
          <Route path='calendar-id' element={<CalendarById />} />
          <Route path='resource-section' element={<ResourceSection />} />
          <Route path='watch-latest-section' element={<WatchLatestSection />} />
          <Route path='watch-latest-section/add-watch' element={<AddWatchLatestSection />} />
          <Route path='watch-latest-section/edit/:id' element={<AddWatchLatestSection />} />
          <Route path='announcement-section/add-announcement' element={<AddAnnouncementSection />} />
          <Route path='announcement-section/edit/:id' element={<AddAnnouncementSection />} />
          <Route path='calendar-section/add-calendar' element={<AddCalendar />} />
          <Route path='calendar-section/edit/:id' element={<AddCalendar />} />
          <Route path='/' element={<Navigate to='reports' />} />
        </Routes>
      </div>


    </div>
  </>
}

export default AdminRoutes