/* eslint-disable default-case */
import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import Form from "react-bootstrap/Form";
import saveIcon from "../../../images/save.png";
import previewIcon from "../../../images/preview.png";
import previewImg from "../../../images/previewImg.jpg";
import Editor from "react-simple-wysiwyg";
import { useFormik } from "formik";
import AxiosService from "../../../service/ApiService";
import * as Yup from "yup";
import { url } from "../../utils/Regex";
import { toast } from 'react-toastify';
import { useEffect } from "react";
import deleteIcon from "../../../images/delete.png";

function AddWatchLatestSection() {
  const navigate = useNavigate();
  const [inputKey, setInputKey] = useState(Date.now());
  const [videoError, setvideoError] = useState(false)
  const [imageError, setimageError] = useState(false)
 

  const [errors, seterrors] = useState(false);
  const [reupload, setreupload] = useState(false);
  const [imgreupload, setimgreupload] = useState(false);
  const params =  useParams()
  const [watchlatest, setWatchLatest] = useState({})
  const [html, setHtml] = useState("");

  const clock = <i class="fa fa-text-width"></i>;
  const image = <i class="fa fa-picture-o"></i>;
  const video = <i class="fa fa-file-video-o"></i>;
    //video upload

    const [videoFile, setVideoFile] = useState();
    const [isVideoPicked, setIsVideoPicked] = useState(false);
    const [previewVideo, setpreviewVideo] = useState();

  function onChange(e) {
    if(e.target.value.length <= 160){
      setHtml(e.target.value);
    }

  }
  const handleWatchLatestById = async () => {
    try {
      let { data } = await AxiosService.get(`/content-management-tool/watchlatest/${params?.id}`)

      if (data.statusCode === 200) {
        // setEvent(data.event[0])
        setWatchLatest(data.watchLatest[0])
        setHtml(data.watchLatest[0].text)
        setIsFilePicked(true)



        // setpreviewImg(URL.createObjectURL(data.watchlatest[0].file));
      }

    } catch (error) {
      console.log(error);
      toast.error(
        error.response.data.message
          ? error.response.data.message
          : error.response.data.message[0]
      );

    }
  }

  const handleAddWatchLatest = async (formData) =>{
    try{

      let { data } = await AxiosService.post("/content-management-tool/watchlatest/add", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      if (data.statusCode === 200) {
        navigate('/admin/watch-latest-section')
        // handleClose()
        toast.success(data.message)
      }

    }catch(error){
      console.log(error);
      toast.error(
        error.response.data.message
          ? error.response.data.message
          : error.response.data.message[0]
      );
    }
  }

  //image upload
  
  const getFileExtension = (filename) => {
    return filename?.slice(filename.lastIndexOf('.'));
  };
  const [imageFile, setImageFile] = useState(null);

  const [isFilePicked, setIsFilePicked] = useState(false);
  const [previewImage, setpreviewImage] = useState();

  const changeHandler = (event) => {
   
    // console.log(event.target.files[0])
    // setImageFile(event.target.files[0]);
    // setpreviewImage(URL.createObjectURL(event.target.files[0]));
    // if(params?.id){
    //   setimgreupload(true)
    // }

    // event.target.files[0] && setIsFilePicked(true);
    const file = event.target.files[0]; // Assuming you are uploading a single file
    const maxSizeInBytes = 500 * 1024; // 500 KB
    const allowedExtensions = ['.jpg', '.jpeg', '.png'];

   
      if (file?.size <= maxSizeInBytes && allowedExtensions.includes(getFileExtension(file?.name)?.toLowerCase())) {
        setImageFile(event.target.files[0]);
        setpreviewImage(URL.createObjectURL(event.target.files[0]));
        if (params?.id) {
          setreupload(true)
        }
  
        event.target.files[0] && setIsFilePicked(true) && setInputKey(new Date())
      } else if (file?.size > maxSizeInBytes) {
        toast.error('The file size is greater than 500 KB.')
      } else if (!allowedExtensions.includes(getFileExtension(file?.name)?.toLowerCase())) {
        toast.error('Invalid file type. Only JPG and PNG files are allowed.')
      } else {
        toast.error('File size and type are valid.')
      }
    



  };
  const imageFormik = useFormik({
    initialValues: {
      alt: (watchlatest?.imageAlt !== "" && watchlatest?.imageAlt !== 'undefined' && watchlatest?.imageAlt !== 'null')
      ? watchlatest?.imageAlt
      : "",
      url: (watchlatest?.imageRedirectUrl !== "" && watchlatest?.imageRedirectUrl !== 'undefined' && watchlatest?.imageRedirectUrl !== 'null')
      ? watchlatest?.imageRedirectUrl
      : "",
    },
    enableReinitialize: true,
    validationSchema: Yup.object({
      alt: Yup.string(),
      url: Yup.string()
      
    })
    });



  const changeVideoHandler = (event) => {
    // setVideoFile(event.target.files[0]);
    // setpreviewVideo((window.URL || window.webkitURL).createObjectURL(event.target.files[0]));
    // if(params?.id){
    //   setreupload(true)
    // }

    // event.target.files[0] && setIsVideoPicked(true);
    const allowedVideoExtensions = ['.mp4', 'mkv', 'mov']
    const file = event.target.files[0]; // Assuming you are uploading a single file
    const maxSizeInBytes = 50 * 1024 * 1024; // 50 MB
   
  
    if (file?.size <= maxSizeInBytes && allowedVideoExtensions.includes(getFileExtension(file?.name)?.toLowerCase())) {
      setVideoFile(event.target.files[0]);
      setpreviewVideo((window.URL || window.webkitURL).createObjectURL(event.target.files[0]));
      event.target.files[0] && setIsVideoPicked(true);
      if(params?.id){
        setreupload(true)
      }

      // event.target.files[0] && setIsVideoPicked(true);
    } else if (file?.size > maxSizeInBytes) {
      toast.error('The file size is greater than 50 MB.')
    } else if (!allowedVideoExtensions.includes(getFileExtension(file?.name)?.toLowerCase())) {
      toast.error('Invalid file type. Only mp4, mkv, mov files are allowed.')
    } else {
      toast.error('File size and type are valid.')
    }
    setInputKey(new Date())
  };
  const handleEditWatchLatestById = async(formData) => {
    try {
      let { data } = await AxiosService.put(`/content-management-tool/watchlatest/edit/${params?.id}`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      if (data.statusCode === 200) {
        navigate('/admin/watch-latest-section')
        toast.success(data?.message)
      }
    } catch (error) {
      console.log(error);
      toast.error(
        error.response.data.message
          ? error.response.data.message
          : error.response.data.message[0]
      );

    }
  }

  const videoFormik = useFormik({
    initialValues: {
      url: (watchlatest?.videoRedirectUrl !== "" && watchlatest?.videoRedirectUrl !== 'undefined' && watchlatest?.videoRedirectUrl !== 'null')
      ? watchlatest?.videoRedirectUrl
      : "",
    },
    enableReinitialize: true,
    validationSchema: Yup.object({
      url: Yup.string()
      
    }),
  });
  const handleImageVideo = () => {

    const formData = new FormData();
    let files = []
    if(imageFile !== undefined && videoFile !== undefined){
     files = [imageFile, videoFile]
    }else if(imageFile!== undefined){
      files = [imageFile]
    }else if (videoFile!== undefined){
      files = [videoFile]
    }
    
    files.forEach((file) => {
      formData.append(`file`, file);
    });
    if(videoFormik.values.url !== "" && videoFormik.values.url !== undefined && videoFormik.values.url !== null){
      formData.append("videoRedirectUrl", videoFormik.values.url) 
    }

    formData.append("text", html);
    if(imageFormik.values.alt !== "" && imageFormik.values.alt !== undefined && imageFormik.values.alt !== null){
      formData.append("imageAlt", imageFormik.values.alt)
    }

    if(imageFormik.values.url !== ""  && imageFormik.values.url !== undefined && imageFormik.values.url !== null){
      formData.append("imageRedirectUrl", imageFormik.values.url)
    }
    
    params?.id ? handleEditWatchLatestById(formData) :
    handleAddWatchLatest(formData)
  }

  const handleWithoutImageVideo= () => {


      let data = {
        "text": html
      };
      
      if (videoFormik.values.url !== "" && videoFormik.values.url !== undefined && videoFormik.values.url !== null) {
        data.videoRedirectUrl = videoFormik.values.url;
      }
      
      if (imageFormik.values.alt !== "" && imageFormik.values.alt !== undefined && imageFormik.values.alt !== null) {
        data.imageAlt = imageFormik.values.alt;
      }
      
      if (imageFormik.values.url !== ""  && imageFormik.values.url !== undefined && imageFormik.values.url !== null){
        data.imageRedirectUrl = imageFormik.values.url;
      }

      if(params?.id) handleEditWatchLatestById(data) 
  } 

  function submission (){
     if(imageFile || videoFile) {
      handleImageVideo()
     }else if(params?.id && !imgreupload && !reupload){
        handleWithoutImageVideo()
       }


    }
  

  const validateVideo = () =>{
    if (videoFormik?.values.url === "" ){
      toast.error("Please fill the required details in Video tab")
    }else if(!(url.test(videoFormik.values.url))  ){
      toast.error("Please fill the valid details in Video tab")
      setvideoError(true)
    }else{
      setvideoError(false)
      seterrors(false)
      submission()
    }
  }

  const validateImage = () => {
   
    if (imageFormik.values.alt === "" || imageFormik.values.url === ""){
      toast.error("Please fill the required details in Image tab")
    }else if(!(url.test(imageFormik.values.url)) &&  (imageFormik.values.alt !== "" || imageFormik.values.url !== "")){
      toast.error("Please fill the valid details in Image tab")
      setimageError(true)
    }else{
      setimageError(false)
      seterrors(false)
      submission()
    }
  }

  const validateBothVideoAndImage = () => {
    if (imageFormik.values.alt === "" || imageFormik.values.url === ""){
      toast.error("Please fill the required details in Image tab")
    }else if(!(url.test(imageFormik.values.url)) &&  (imageFormik.values.alt !== "" || imageFormik.values.url !== "")){
      toast.error("Please fill the valid details in Image tab")
      setimageError(true)
    }else if (videoFormik?.values.url === "" ){
      toast.error("Please fill the required details in Video tab")
    }else if(!(url.test(videoFormik.values.url))  ){
      toast.error("Please fill the valid details in Video tab")
      setvideoError(true)
    }else{
      setimageError(false)
      setvideoError(false)
      seterrors(false)
      submission()
    }
  }


  const handleFormValidations = () =>{
    if(!(params?.id)){
      
    if (!isFilePicked && !isVideoPicked) {
      toast.error("Please select either image or video");
    } else if (isFilePicked && isVideoPicked) {
      validateBothVideoAndImage();
    } else if (isFilePicked && !isVideoPicked) {
      
      validateImage();
    } else if (isVideoPicked && !isFilePicked) {
      validateVideo();
    }
    }else if (params?.id){

     if(reupload && !imgreupload){
          validateVideo()
         
      }else if(imgreupload && !reupload){
          validateImage()
      
      }else if(reupload && imgreupload){
        validateBothVideoAndImage()

      }else if (!reupload && !imgreupload){
        if(watchlatest?.imageUrl){
          validateImage()
        }else if (watchlatest?.videoUrl){
          validateVideo()
        }

        
      }
    }
  }











  useEffect(() => {

    if(url.test(imageFormik.values.url)){
      setimageError(false)
    }
    if(url.test(videoFormik.values.url)){
      setvideoError(false)
    }
  },[setHtml, imageFormik.values, videoFormik.values])


  const Image = () => {
    return (
      <div>
        <Form className="row adminForm">
          <div className="col-sm-6 col-xs-12">
            <label class="form-label" for="Upload Image">
              Upload Image
            </label>
            <Form.Group className="mb-3">
              <Form.Control
              key = {inputKey}
                type="file"
                id="file"
                name="file"
                accept="*/image"
                onChange={changeHandler}
              />

              <Form.Label htmlFor="file">Choose file</Form.Label>
              <small className="fs--12 text--grey6">
                *Image must be in png,jpeg,jpg format.
                <br/>
                Less than 500kb
              </small>
              {imageFile ? (
            <div>
              {imageFile.name} {" "}
              <img src={deleteIcon} alt="delete" onClick={() => {

                setInputKey(new Date())
                setImageFile(null) 
                setIsFilePicked(false)
              }}/>
            </div>
          ) : null}
            </Form.Group>
            {imageFormik.errors.file && imageFormik.touched.file ? (
              <p className="error_text">{imageFormik.errors.file}</p>
            ) : null}
           
          </div>
          <div className="col-sm-6 col-xs-12">
            <Form.Group className="mb-3">
              <Form.Label htmlFor="alt">Alt</Form.Label>
              <Form.Control
                id="alt"
                type="text"
                name="alt"
                value={imageFormik.values.alt}
                onChange={imageFormik.handleChange}
                onBlur={imageFormik.handleBlur}
              />
            </Form.Group>
            {imageFormik.errors.alt && imageFormik.touched.alt ? (
              <p className="error_text">{imageFormik.errors.alt}</p>
            ) : null}
                                    {
              isFilePicked && imageFormik.values.alt === "" ?  <p className="error_text">Required</p>  : null
            }
                        
          </div>
          <div className="col-sm-12 col-xs-12">
            <Form.Group className="mb-3">
              <Form.Label htmlFor="url">URL</Form.Label>
              <Form.Control
                type="text"
                id="url"
                name="url"
                value={imageFormik.values.url}
                onChange={(e) => {
                  imageFormik.setFieldValue("url", e.target.value)
                  setimageError(false)
                }}
                onBlur={imageFormik.handleBlur}
              />
            </Form.Group>
            {imageFormik.errors.url && imageFormik.touched.url ? (
              <p className="error_text">{imageFormik.errors.url}</p>
            ) : null}
                        {
              isFilePicked && imageFormik.values.url === "" ?  <p className="error_text">Required</p>  : null
            }
             {
              imageError ? <p className="error_text">Invalid Url</p>  : <></>
            }
          </div>
        </Form>
      </div>
    );
  };

  const Video = () => {
    return (
      <div>
        <Form className="row adminForm">
          <div className="col-sm-12 col-xs-12 ">
            <label class="form-label " for="video">
              Upload Video
            </label>
            <Form.Group className="mb-3 ">
              <Form.Label htmlFor="video" className="video_box">
                Choose file{" "}
              </Form.Label>
              <Form.Control
                type="file"
                key={inputKey}
                id="video"
                name="video"
                accept="video/mp4,video/x-m4v,video/*"
                placeholder="click to upload"
                onChange={changeVideoHandler}
                hidden
                style={{ cursor: "pointer" }}
              />

              <small className="fs--12 text--grey6">
                *Video must be in mp4, mkv, mov format.
                <br/>
                Less than 50Mb
              </small>
              <div>
              {videoFile ? (
            <div>
              {videoFile.name} {" "}
              <img src={deleteIcon} alt="delete" onClick={() => {
                setInputKey(new Date())
                setVideoFile(null) 
                setIsVideoPicked(false)
              }}/>
            </div>
          ) : null}
              
              </div>
            </Form.Group>
            {}
            {/* {videoFormik.errors.file && videoFormik.touched.file ? (
              <p className="error_text">{videoFormik.errors.file}</p>
            ) : null} */}
          </div>
          <div className="col-sm-12 col-xs-12">
            <Form.Group className="mb-3">
              <Form.Label htmlFor="url">URL</Form.Label>
              <Form.Control
                type="text"
                id="url"
                name="url"
                value={videoFormik.values.url}
                onChange={(e) => {
                  videoFormik.setFieldValue("url", e.target.value)
                  setvideoError(false)
                }}
                onBlur={videoFormik.handleBlur}
              />
            </Form.Group>
            {videoFormik.errors.url && videoFormik.touched.url ? (
              <p className="error_text">{videoFormik.errors.url}</p>
            ) : null}
            {
              isVideoPicked && videoFormik.values.url === "" ?  <p className="error_text">Required</p>  : null
            }
            {
              videoError ? <p className="error_text">Invalid Url</p>  : null
            }
          </div>
        </Form>
      </div>
    );
  };
  useEffect(() => {

    if (params.id)
      handleWatchLatestById()
  }, [])
  return (
    <>
      <div className="adminContentSection">
        <div className="titleSection">
          <div className="titleText "> Watch latest section</div>
          <div className="flexSection">
            <div className="displayFlex alignCenter spaceBetween gap15">
              <button
                className="btnDark d-flex align-items-center"
                
                onClick={ () => {
                  if(html === ""){
                    toast.error("Please fill the required details in Text tab")
                  }else{
                    handleFormValidations()
                  }
                }}
              >
                <img src={saveIcon} alt="plusicon" className="pe-1" /> Save
              </button>
            </div>
          </div>
        </div>

        <div className="mt30">
          <div className="row">
            <div className="col-sm-6 col-xs-12">
              <div className="cmsWLeft1 mb-4 ">
                <Tabs
                  defaultActiveKey="text"
                  id="watch-tab"
                  className="watchTab"
                >
                  <Tab eventKey="text" title={<span>{clock} Text</span>} >
                    <Editor value={html} onChange={onChange} id="editRef" />

                    <small className="fs--12 text--grey6">
                    *{html.length} of 160 Characters
              </small>
                  </Tab>
                  <Tab
                    eventKey="image"
                    title={
                      <span
                      >
                        {image} Image
                      </span>
                    }
                  >
                    {/* <Image /> */}
                    {Image()}
                  </Tab>
                  <Tab
                    eventKey="video"
                    title={
                      <span
                      >
                        {video} Video
                      </span>
                    }
                  >
                    {Video()}
                  </Tab>
                </Tabs>
              </div>
            </div>
            <div className="col-sm-6 col-xs-12">
              <div className="cmsWRight1">
                <div className="header p-3">
                  <img src={previewIcon} alt="Preview icon" className="pe-1" />{" "}
                  <span className="fs--14">Preview</span>
                </div>
                <div className="body p-3 d-flex justify-content-center mt-3">
                  <div className="inner p-3  mw-100 previewContainer a-c" >
                    {
                      (params?.id) && (!reupload) && (!imgreupload) && (watchlatest?.videoUrl !== null) ?  
                        // <video src={previewVideo} alt="Preview" className="w-100" />
  
                        <video
                          width="200"
                          height="120"
                          controls
                          src={watchlatest?.videoUrl}
                       
                        >
                          Your browser does not support the video tag.
                        </video>
                       :  (params?.id) && (!reupload) &&   (!imgreupload) &&(watchlatest?.imageUrl !== null) ? 
                       <img src={watchlatest?.imageUrl} alt="Preview" className="w-100" />
                      
                      
                      
                      : (params?.id) && (reupload) ?                          <video
                      width="200"
                      height="120"
                      controls
                      src={previewVideo}

                   
                    >
                      Your browser does not support the video tag.
                    </video> : (params?.id) && (!reupload)  && (imgreupload)? 
                    <img src={previewImage} alt="Preview" className="w-100" /> :  
                      
                      
                      
                      
                      isFilePicked && !isVideoPicked  ? (
                        <img src={previewImage} alt="Preview" className="w-100" />
                      ) : isVideoPicked ? (
                      
  
                        <video
                        width="200"
                        height="120"
                          controls
                          src={previewVideo}
                       
                        >
                          Your browser does not support the video tag.
                        </video>
                      ) :  (
                        <img src={previewImg} alt="Preview" className="w-100" />
                        
                      )}
                    

                    <div dangerouslySetInnerHTML={{ __html: html }} className="setHtml"></div>
                    {/* <p className="fs--16 py-2">{html}</p> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default AddWatchLatestSection;
