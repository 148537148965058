import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import Banner from '../../images/landingPage/partner/banner.jpg';
import Section2 from '../../images/landingPage/partner/section1.jpg';
import Section3 from '../../images/landingPage/partner/section3.jpg';
import Section4 from '../../images/landingPage/partner/section4.jpg';
import SASqlogo from '../../images/landingPage/partner/SASqlogo.png';
import Spacepointe from '../../images/landingPage/partner/Spacepointe.png';
import Givecare from '../../images/landingPage/partner/givecare.png';
import Header from './common/Header';
import Footer from './common/Footer';
import Copyright from './common/Copyright';
import HubspotForm from 'react-hubspot-form';
import AOS from 'aos';
import 'aos/dist/aos.css';
import styles from './Partner.module.scss';
import { NavHashLink } from 'react-router-hash-link';

function Partner() {
    useEffect(() => {
        AOS.init();
        window.scrollTo(0, 0);
    }, []);
    const scrollWithOffset = (el) => {
        const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset;
        const yOffset = -220;
        window.scrollTo({ top: yCoordinate + yOffset, behavior: 'smooth' });
    }

    return <>
        <Header />
        <section className={`${styles.section1} container-fluid`}>
            <div className={`container py-lg-5 py-xs-0`}>
                <div className='row'>
                    <div className='col-sm-6 col-xs-12'>
                        <h2 className='fs--36 mb-4 pt-lg-5 pt-xs-0 fontPoppins fw--600 lh-55' data-aos="fade-up"><span className='text--primary'>Partner </span> with us</h2>
                        <p className='fs--18 pb-4 text--white3 fontPoppins lh-35  pe-lg-5 pe-xs-0' data-aos="fade-up">Our approach is centered on ensuring that the businesses we enable and empower are truly setup for success. ​</p>
                        <div className='col-sm-12' data-aos="fade-up"><Link to="/donate" className='btn--primary me-lg-2 me-xs-0' role='button' aria-label='Make an Impact'>Make an Impact</Link> </div>
                    </div>
                    <div className='col-sm-6 col-xs-12 py-3 d-flex justify-content-end'>
                        <img src={Banner} alt='Slider' data-aos="fade-up" className='w-100 h-auto ps-lg-5 ps-xs-0' />
                    </div>
                </div>
            </div>
        </section>
        <section className={`${styles.section2} container-fluid`}>
            <div className={`container py-5`}>
                <div className='row'>
                    <div className='col-sm-6 col-xs-12 pe-lg-5 pe-xs-0 m-mb-10 order-lg-1 order-xs-2'>
                        <img src={Section2} alt='Micro Venture Funding' className='w-100 h-auto br--10' data-aos="fade-right" />
                    </div>
                    <div className='col-sm-6 col-xs-12 order-lg-2 order-xs-1'>
                        <h3 className='fs--30 fw--600 pb-4 text-black fontPoppins lh-45 ps-lg-5 ps-xs-0' data-aos="fade-up">Micro Venture Funding</h3>
                        <p className='fs--18 pb-4 text-black fontPoppins lh-35  ps-lg-5 ps-xs-0' data-aos="fade-up">Your partnership will provide funding opportunities for certified GSG foundation ventures that have been through the readiness vetting process. Readiness including going through the basic training, certification and specialized training where required, and then competing with other Counterpart to earn a funding opportunity. This will do two things, increase their chances of success when they open their business and increase the probability the venture capitalist getting a good return on their investment. </p>
                        <div className='col-sm-12 ps-lg-5 ps-xs-0' dxaata-aos="fade-up"> <NavHashLink to="#findOutMore" scroll={el => scrollWithOffset(el)} className='btn--secondary px-5' role='button' aria-label='Find Out More'>Find Out More</NavHashLink></div>
                    </div>
                </div>
            </div>
        </section>
        <section className={`${styles.section3} container-fluid`}>
            <div className={`container py-5`}>
                <div className='row'>
                    <div className='col-sm-6 col-xs-12'>
                        <h3 className='fs--30 fw--600 pb-4 text-black fontPoppins lh-45 pe-lg-5 pe-xs-0' data-aos="fade-up">Specialized Skill Training </h3>
                        <p className='fs--18 pb-1 text-black fontPoppins lh-35  pe-lg-5 pe-xs-0' data-aos="fade-up">We are looking to partner with organizations that can offer our certified members advanced specialized skills training across multiple categories. </p>
                        <p className='fs--18 pb-4 text-black fontPoppins lh-35  pe-lg-5 pe-xs-0' data-aos="fade-up">If you offer paid training in one of our approved categories, then we would be glad to have a conversation about a partnership with the GSG foundation where we can send out students to get a quality education with the intent to start their own businesses upon graduation.</p>
                        <div className='col-sm-12' data-aos="fade-up"> <NavHashLink to="#findOutMore" scroll={el => scrollWithOffset(el)} className='btn--secondary px-5' role='button' aria-label='Find Out More'>Find Out More</NavHashLink></div>
                    </div>
                    <div className='col-sm-6 col-xs-12 ps-lg-5 ps-xs-0'>
                        <img src={Section3} alt='Specialized Skill Training ' className='w-100 h-auto br--10' data-aos="fade-right" />
                    </div>
                </div>
            </div>
        </section>
        <section className={`${styles.section4} container-fluid`}>
            <div className={`container py-5`}>
                <div className='row'>
                    <div className='col-sm-6 col-xs-12 pe-lg-5 pe-xs-0 m-mb-10 order-lg-1 order-xs-2'>
                        <img src={Section4} alt='Donate' className='w-100 h-auto br--10' data-aos="fade-right" />
                    </div>
                    <div className='col-sm-6 col-xs-12 order-lg-2 order-xs-1'>
                        <h3 className='fs--30 fw--600 pb-4 text-black fontPoppins lh-45 ps-lg-5 ps-xs-0' data-aos="fade-up">Donate</h3>
                        <p className='fs--18 pb-4 text-black fontPoppins lh-35  ps-lg-5 ps-xs-0' data-aos="fade-up">There are lots of qualified members that require some funding to help them get trained or startup their business. Join our cause to bring help and hope to them. Those that get funded for starting up their business via donations will not be charged an interest but will be required to play it forward by paying at least half of the grant back to be used in helping up and coming members requiring the same kind of help. This means that for every two ventures funded via donations, a third will be funded as well. It's the gift that keeps on giving really.  </p>
                        <div className='col-sm-12 ps-lg-5 ps-xs-0' data-aos="fade-up"> <NavHashLink to="/donate" scroll={el => scrollWithOffset(el)} className='btn--primary px-5' role='button' aria-label='Donate Now'>Donate Now</NavHashLink></div>
                    </div>
                </div>
            </div>
        </section>
        <section className={`${styles.section31} container-fluid`}>
            <div className={`container py-5`}>
                <div className='row'>
                    <div className='col-sm-10 offset-sm-1 col-xs-12 text-center mb-4'>
                        <h3 className='fs--30 fw--600 pb-3 text-black fontPoppins lh-45 pe-lg-5 pe-xs-0' data-aos="fade-up">Meet Our Partners</h3>
                        <p className='fs--18 pb-1 text-black fontPoppins lh-35  pe-lg-5 pe-xs-0' data-aos="fade-up">Together, we are changing the narrative, one entrepreneurial journey at a time. Join us and be part of a movement that empowers individuals to take control of their destinies, uplift communities, and drives sustainable economic growth.</p>
                    </div>
                </div>
                <div className='row'>
                    <div className='col-sm-4 col-xs-12 ps-lg-5 ps-xs-0 text-center'>
                      <div className={`${styles.section31Inner} d-flex align-items-center justify-content-center h-100 p-5`}>
                         <img src={SASqlogo} alt='SASquared Ventures' className='h-auto br--10 w-100 px-3' data-aos="fade-right" />
                       </div>  
                    </div>
                    <div className='col-sm-4 col-xs-12 ps-lg-5 ps-xs-0  text-center'>
                      <div className={`${styles.section31Inner} d-flex align-items-center justify-content-center h-100 p-5`}>
                        <img src={Givecare} alt='Givecare' className='h-auto br--10 w-100 px-3' data-aos="fade-right" />
                      </div>  
                    </div>
                    <div className='col-sm-4 col-xs-12 ps-lg-5 ps-xs-0  text-center'>
                       <div className={`${styles.section31Inner} d-flex align-items-center justify-content-center h-100 p-5`}>
                            <img src={Spacepointe} alt='SpacePointe ' className='w-100 h-auto br--10' data-aos="fade-right" />
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section className={`${styles.section5} container-fluid bg--grey1 py-5`} id="findOutMore">
            <div className={`container py-5 text-start br--20`}>
                <div className='row'>
                    <div className={`${styles.section5Inner} shadow-sm col-sm-12 col-xs-12 bg-white p-5 br--20`}>
                        <h3 className='fs--30 fw--600 pb-1 text-black fontPoppins lh-45 pe-lg-5 pe-xs-0' data-aos="fade-up">Find out More </h3>
                        <p className='fs--18 text--white3' data-aos="fade-up">Fill in the form and we will send you a brochure</p>
                        <div data-aos="fade-up">
                            <div className={`${styles.findOutMoreRight} pt-4 h-100`}>
                                <HubspotForm
                                    portalId='23639501'
                                    formId='07bd4aff-5810-45d7-b4c5-c001794afad8'
                                    onSubmit={() => console.log('Submit!')}
                                    onReady={(form) => console.log('Form ready!')}
                                    loading={<div>Loading...</div>}
                                />          
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <Footer />
        <Copyright />
    </>
}

export default Partner