import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import LandingPage from "./components/landingpage/LandingPage";
import AppRoutes from './components/utils/Routes/AppRoutes'
// import WhoWeAre from "./components/landingpage/WhoWeAre";
// import WhatWeDo from "./components/landingpage/WhatWeDo";
// import OurImpact from "./components/landingpage/OurImpact";
// import News from "./components/landingpage/News";
// import Blog1 from "./components/landingpage/Blog1";
import Partner from "./components/landingpage/Partner";
// import Blog2 from "./components/landingpage/Blog2";
import GetInvolved from "./components/landingpage/GetInvolved";
import { TermsofUse } from "./components/landingpage/TermsofUse";
import { PrivacyPolicy } from "./components/landingpage/PrivacyPolicy";
// import PaymentVerification from "./components/landingpage/PaymentVerification";
import AboutUs from "./components/landingpage/About";
// import SuccessStories from "./components/landingpage/SuccessStories";
import { MyChoice } from "./components/landingpage/MyChoice";
// import { Shop } from "./components/landingpage/Shop";
import ContactUs from "./components/landingpage/ContactUs";
import PressReleases  from "./components/landingpage/PressReleases";
import Blog3 from "./components/landingpage/Blog3";
import BecomeAPartner from "./components/landingpage/BecomeAPartner";
// import { Donate } from "./components/landingpage/Donate";
// import { DonateStripe } from "./components/landingpage/DonateStripe";

function App() {
  return <>
    <BrowserRouter>
      <Routes>
          <Route path="/" element={<LandingPage/>}/>
          <Route path="/about-us" element={<AboutUs/>}/>
          {/* <Route path="/who-we-are" element={<WhoWeAre/>}/> */}
          {/* <Route path="/what-we-do" element={<WhatWeDo/>}/>
          <Route path="/success-stories" element={<SuccessStories/>}/> */}
          {/* <Route path="/our-impact" element={<OurImpact/>}/> */}
          {/* <Route path="/shop" element={<Shop/>}/> */}
          {/* <Route path="/news" element={<News/>}/> */}
          {/* <Route path="/news/blog1" element={<Blog1/>}/>
          <Route path="/news/blog2" element={<Blog2/>}/> */}
          <Route path="/news/blog3" element={<Blog3/>}/>
          <Route path="/partner" element={<Partner/>}/>
          <Route path="/get-involved" element={<GetInvolved/>}/>
          <Route path="/my-choices" element={<MyChoice/>}/>
          <Route path="/terms-of-use" element={<TermsofUse/>}/>
          <Route path="/privacy-policy" element={<PrivacyPolicy/>}/>
          {/* <Route path='/payment-success' element={<PaymentVerification/>}/> */}
          <Route path='/contact-us' element={<ContactUs/>}/>
          <Route path='/stay-in-the-Loop' element={<BecomeAPartner/>}/>
          <Route path='/press-releases' element={<PressReleases/>}/>
          <Route path="/*" element={<AppRoutes/>}/>
          {/* <Route path="/donate-stripe" element={<Donate/>}/> */}
          {/* <Route path="/donate" element={<DonateStripe/>}/> */}
      </Routes>
    </BrowserRouter>
  </>
}

export default App;
