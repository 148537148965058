import React from "react";
import { useNavigate } from "react-router-dom";
import UserBackdrop from "./UserBackdrop";
import logo from "../../images/logo.svg";
import countryicon from "../../images/country-icon.svg";
// import eyeicon from "../../images/eye-icon.svg";
import { useFormik } from "formik";
import * as Yup from "yup";
import AxiosService from './../../service/ApiService'
import {toast} from 'react-toastify'

function EnterMobile() {
  const navigate = useNavigate();

  const handleEnterMobile = async({mobile}) =>{
    try{

      let res = await AxiosService.post('/users/get-otp',{mobile})
      if(res.status === 200)
      {
          sessionStorage.setItem('mobile',mobile)
          sessionStorage.setItem('userid',res.data.userid)
          toast.success(res.data.message)
          navigate("/verify-otp");
      }
    }catch(error){
      toast.error(error.response.data.message?error.response.data.message:error.response.data.message[0]);
    }
  }
  const formik = useFormik({
    initialValues: {
      mobile : ""
    },
    enableReinitialize: true,
    validationSchema: Yup.object({
      mobile : Yup.string()
      .required("Please enter the mobile number")


    }),
    onSubmit: (values) => {
      handleEnterMobile(values);
    },
  });
  // const handleSubmit = () => {
  //   navigate("/verify-otp");
  // };
  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <div className="displayFlex">
          <UserBackdrop />
          <div className="leftSection">
            <div className="logoSection">
              <img src={logo} alt="logo" />
            </div>

            <div className="signSection">
              {/* <div className='signTextblock'>
              <div className='signtext ' onClick={()=>{navigate('/sign-up')}}>Sign up</div>
              <div className='signtext signtextActive'> Sign IN</div>
            </div> */}

              <div className="signBlock_mobile">
                <div className="welcometext pb30">
                  {" "}
                  Enter your registered mobile number
                </div>

                <div className="phonenumber ">
                  <label for="exampleInputEmail1">Phone number</label>

                  <div className="displayFlex">
                    <div className="countryIcon">
                      {" "}
                      <img src={countryicon} alt="country icon" />
                    </div>
                    <div className="form-group mb15 w100per">
                      <input
                        type="text"
                        name ="mobile"
                        className="form-control borderleftcss"
                        id="exampleInputEmail1"
                        aria-describedby="emailHelp"
                        placeholder="Enter Phone Number"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.mobile}

                      />
                    </div>
                  </div>
                  {
                    formik.errors.mobile && formik.touched.mobile ? (
                      <p className="error_text">{formik.errors.mobile} </p>
                    ) : ""
                  }
                </div>

                <button
                  className="orangeButton"
                  // onClick={() => {
                  //   handleSubmit();
                  // }}
                  type="submit"
                >
                  Submit
                </button>
              </div>
            </div>
          </div>
        </div>
      </form>
    </>
  );
}

export default EnterMobile;
