export const numberOnly = /^[0-9]{0,}$/ig

export const name = /^[a-zA-Z]{1}[a-zA-Z ]{0,}$/ig //characters and numbers

export const mobileNumber = /^[0-9]{0,10}$/g //10 numbers

export const email = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

export const zipcode = /^[0-9]{0,6}$/g

export const address = /^[a-zA-Z0-9 ]{0, }$/i

export const username = /^[a-zA-Z0-9]{0,}$/ig

// export const url =/(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}(\.[a-z]{2,6}|:[0-9]{3,4})\b([-a-zA-Z0-9@:%_\+.~#?&\/\/=]*)/
// export const url = /^(http(s)?:\/\/)?(localhost|[\w-]+(\.[\w-]+)+)(:\d+)?(\/\S*)?$/;
export const url = /^(https?:\/\/(localhost|[\w-]+(\.[\w-]+)+)|http(s)?:\/\/[\w-]+(\.[\w-]+)+)(:\d+)?(\/\S*)?$/;

