import React, {useEffect } from 'react';
import { Link } from 'react-router-dom';
import Banner from '../../images/landingPage/press-releases/banner.jpg';
import SaSquared from '../../images/landingPage/news/SaSquared.jpg';
import Section2 from '../../images/landingPage/news/section2.jpg';
import Section3 from '../../images/landingPage/press-releases/blog3.jpg';
import Header from './common/Header';
import Footer from './common/Footer';
import Copyright from './common/Copyright';
import AOS from 'aos';
import 'aos/dist/aos.css';
import styles from './News.module.scss';

function PressReleases() {
    useEffect(() => {
        AOS.init();
        window.scrollTo(0, 0);
    }, []);
    return <>
        <Header />
        <section className={`${styles.section1} container-fluid`}>
            <div className={`container py-lg-5 py-xs-0`}>
                <div className='row'>
                    <div className='col-sm-6 col-xs-12'>
                        <h2 className='fs--36 mb-4 pt-lg-5 pt-xs-0 fontPoppins fw--600 lh-55' data-aos="fade-up">Stay updated with the latest <span className='text--primary'>news and announcements</span> from  GoStartUpGo Foundation.</h2>
                        <p className='fs--18 pb-4 text--white3 fontPoppins lh-35  pe-lg-5 pe-xs-0' data-aos="fade-up">Here, you’ll find press releases about our partnerships, program developments, and the impact we are making in empowering entrepreneurs in developing countries. Explore our recent collaborations and how they’re driving real change in communities around the world.</p>
                    </div>
                    <div className='col-sm-6 col-xs-12 py-3 d-flex justify-content-end ps-lg-5 ps-xs-0 pe-lg-5 pe-xs-0 position-relative rightEffect'>
                        <img src={Banner} alt='Slider' className='w-100 height420 br--30' data-aos="fade-up" />
                    </div>
                </div>
            </div>
        </section>
        <section className={`${styles.section2} container-fluid`}>
            <div className={`container py-5`}>
                <div className='row'>
                    <div className='col-sm-6 col-xs-12 pe-lg-5 pe-xs-0 m-mb-10 order-lg-1 order-xs-2'>
                        <div className='position-relative'>
                            <img src={Section3} alt='GoStartUpGo Foundation Partners with SasquaredVentures Limited to Empower Entrepreneurs in Nigeria' className='w-100 h-auto br--10' data-aos="fade-right" />
                            <div className={`${styles.date} bg--primary position-absolute p-2  br--10 text-white`}>September 15th, 2024</div>
                        </div>
                       
                    </div>
                    <div className='col-sm-6 col-xs-12 order-lg-2 order-xs-1 position-relative ps-0'>
                        <div className={`${styles.date} d-inline-block mb-3 ms-lg-5 ms-xs-0 bg--secondary px-4 py-2  br--10 text-white`} data-aos="fade-up">Partnerships</div>
                        <h3 className='fs--30 fw--600 pb-4 text-black fontPoppins lh-45 ps-lg-5 ps-xs-0' data-aos="fade-up">GoStartUpGo Foundation Partners with SasquaredVentures Limited to Empower Entrepreneurs in Nigeria</h3>
                        <p className='fs--18 pb-1 text-black fontPoppins lh-35  ps-lg-5 ps-xs-0' data-aos="fade-up"><span className='text--primary'>Atlanta, GA</span><br/>GoStartUpGo Foundation, a U.S.-based 501(c)(3) nonprofit focused on fostering entrepreneurship in developing countries, proudly announces its partnership with <strong>SasquaredVentures Limited</strong>, a leading Nigerian business solutions provider, to manage its entrepreneurship programs in Nigeria. This partnership will enable GoStartUpGo’s mission of empowering entrepreneurs in Africa by providing them with the tools, mentorship, and financial resources needed to build sustainable businesses.  <Link to='/news/blog3' className='text-underline text--grey2 fs--18 link--hover'>Read more</Link></p>
                    </div>
                </div>
            </div>
        </section>
        {/* <section className={`${styles.section2} container-fluid`}>
            <div className={`container py-5`}>
                <div className='row'>
                    
                    <div className='col-sm-6 col-xs-12'>
                        <div className={`${styles.date} d-inline-block mb-3 bg--secondary px-4 py-2  br--10 text-white`} data-aos="fade-up">Partnerships</div>
                        <h3 className='fs--30 fw--600 pb-4 text-black fontPoppins lh-45 pe-lg-5 pe-xs-0' data-aos="fade-up">GoStartupGo partners with SaSquared Ventures to fund micro entrepreneurs</h3>
                        <p className='fs--18 pb-1 text-black fontPoppins lh-35  pe-lg-5 pe-xs-0' data-aos="fade-up">“SaSquared Ventures believes in what is considered “Good Business Pressure” that drives the business owner to startup and succeed. To that effect, we require the micro ventures to pay back the capital with a very small interest. This “good business pressure” not only drives them to work hard, but also gives an upcoming venture the opportunity to get funded as well” <span className='text--primary'>- Osato Osayande, COO, SaSquared Ventures</span> . <Link to='/news/blog1' className='text-underline text--grey2 fs--18 link--hover'>Read more</Link></p>
                    </div>
                    <div className='col-sm-6 col-xs-12 ps-lg-5 ps-xs-0'>
                        <div className='position-relative'>
                            <img src={SaSquared} alt='GoStartupGo partners with SaSquared Ventures to fund micro entrepreneurs' className='w-100 h-auto br--10' data-aos="fade-right" />
                            <div className={`${styles.date} bg--primary position-absolute p-2  br--10 text-white`}>January 3rd, 2023</div>
                        </div>
                       
                    </div>  
                </div>
            </div>
        </section>
        <section className={`${styles.section3} container-fluid`}>
            <div className={`container py-5`}>
                <div className='row'>
                    <div className='col-sm-6 col-xs-12 pe-lg-5 pe-xs-0 m-mb-10 order-lg-1 order-xs-2'>
                        <div className='position-relative'>
                            <img src={Section2} alt='GoStartupGo partners with SpacePointe to provide business management EkiKart' className='w-100 h-auto br--10' data-aos="fade-right" />
                            <div className={`${styles.date} bg--primary position-absolute p-2  br--10 text-white`}>Feb 13th, 2023</div>
                        </div>
                    </div>
                   <div className='col-sm-6 col-xs-12 order-lg-2 order-xs-1 position-relative ps-0'>
                        <div className={`${styles.date} d-inline-block mb-3 ms-lg-5 ms-xs-0 bg--secondary px-4 py-2  br--10 text-white`} data-aos="fade-up">Partnerships</div>
                        <h3 className='fs--30 fw--600 pb-4 text-black fontPoppins lh-45 ps-lg-5 ps-xs-0' data-aos="fade-up">GoStartupGo partners with SpacePointe to provide business management EkiKart</h3>
                        <p className='fs--18 pb-1 text-black fontPoppins lh-35 ps-lg-5 ps-xs-0' data-aos="fade-up"><span className='text--primary'>Edo State Nigeria</span><br/> The GoStarupGo (GSG) foundation, a leading business enablement non-profit organization has entered into a strategic partnership with SpacePointe Inc, a global financial technology firm. This partnership will provide business management and point of sales tools for the GoStartupGo foundation members. <Link to='/news/blog2' className='text-underline text--grey2 fs--18 link--hover'>Read more</Link></p>
                    </div>
                </div>
            </div>
        </section>  */}
        <section className={`${styles.section10} container-fluid bgGradient--primary`}>
            <div className={`container py-5`}>
                <div className='row'>
                <div className='col-sm-6 col-xs-12'>
                    <h2 className='fs--30 mb-3 fontMontserrat text-white fw--600 lh-38' data-aos="fade-up">Go Start Up Go is the ideal partner to turn your small business dreams into reality.</h2>
                    <p className='fs--16 text--grey1 mb-0 fontMontserrat lh-25' data-aos="fade-up">With Go Start Up Go, you'll have the support and tools necessary to be the best version of yourself and realize your full potential.</p>
                </div>
                </div>
            </div>
        </section>
        <Footer />
        <Copyright />
    </>
}

export default PressReleases