import React, {useState, useEffect} from 'react';
import CalIcon from '../../images/landingPage/student/calendar.png';
import AxiosService from '../../service/ApiService';
import { toast } from 'react-toastify';
import { useLogout } from "../../hooks/useLogout";
import { NoData } from './NoData';

export const WhatsAhead = () => {
    let [events, setEvents] = useState([]);
    let Logout = useLogout()
    const handleGetAllEvents = async () =>{
        try{
          let res  = await AxiosService.get('/content-management-tool/student/whatsahead')
          if (res.data.statusCode === 200) {
            setEvents(res.data.events)
            // setTotalCount(res.data.count)
          }
    
        }catch(error){
          if (error.response.status === 401)
          Logout()
        toast.error(error.response.data.message ? error.response.data.message : error.response.data.message[0]);
      
        }
      }
    useEffect(() => {
        handleGetAllEvents()
    }, [])

    return (
        <div className='bg-white fontPoppins pb-1'>
            <h4 className='fs--20 fw--500 text-uppercase position-relative whatsAheadHeading  p-4 mb-0'>WHAT'S <span className='text--primary'>AHEAD</span></h4>
            {
              events.length!==0?<div className='whatsHeadContainer'>
              {
                  events?.map((event) => {
                      return <div className='px-4 mb-3' key={event.ref_id}>
                       
                          <h4><img src={CalIcon} alt='Icon' className='me-2'/> <span className='fs--16 fw--600'>{new Date(event?.date).toLocaleString("en-us", {
                    month: "long",
                    day: "numeric",
                  })}</span></h4>
                       

                     <div className='fs--12 text--grey6' dangerouslySetInnerHTML={{ __html: event?.text }}></div>
                  </div>
                  })
              }
      </div>:<NoData/>
            }
        </div>
    )
}
