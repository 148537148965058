import React, {useEffect, useState} from 'react'
import { useNavigate } from 'react-router-dom';
import UserBackdrop from './UserBackdrop'
import logo from "../../images/logo.svg";

function ForgotPassword() {
  const [mobile, setMobile] = useState("")
  const navigate =useNavigate()

  const handleVerifyOtp = () => {
    navigate('/create-password')
  }

  useEffect(()=>{
    if(sessionStorage.getItem('mobile')){
      let mobileNumber = sessionStorage.getItem('mobile');

      // let fourDigits = String(mobileNumber).slice(-4)
      setMobile(String(mobileNumber).slice(0, -4).replace(/./g,"X")+String(mobileNumber).slice(-4))
    }
  },[mobile]) 
  return <>
    <div className='displayFlex'>
      <UserBackdrop />
      <div className='leftSection'>
        <div className='logoSection' >
          <img
            src={logo}
            alt='logo'

          />

        </div>

        <div className='signSection'>


          <div className='signBlock'>

            <div className='flexSection'>
              <div className='blackButton m15'>

                Forgot password
              </div>

            </div>

            <div className='verificationText m15'>A verification code has been sent</div>

            <div className='content m15 underline pb65 fontWeight600'>XXX XXX 7890</div>

            <div className='content m15  '> Enter the 6 digit code </div>

            <div className='flexSection gap5 mb30'>
              <input type="text" className="form-control textAlignCenter" />
              <input type="text" className="form-control textAlignCenter" />
              <input type="text" className="form-control textAlignCenter" />
              <input type="text" className="form-control textAlignCenter" />
              <input type="text" className="form-control textAlignCenter" />
              <input type="text" className="form-control textAlignCenter" />
            </div>

            <button className='orangeButton' onClick={() =>{
              handleVerifyOtp()
            }}>Verify</button>





          </div>


        </div>





      </div>
    </div>
  </>
}

export default ForgotPassword