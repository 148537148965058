/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-lone-blocks */
/* eslint-disable no-undef */
import React, { useEffect, useState } from "react";
import AxiosService from "./../../service/ApiService";
import { utils, writeFile} from 'xlsx';

import rightarrow from "../../images/right-arrow.svg";
import fileexport from "../../images/file-export.svg";

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";

import { toast } from 'react-toastify'

function Reports() {
  ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Filler,
    Legend
  );

  const [countries, setCountries] = useState([]);
 
  const [selectedCountry, setSelectedCountry] = useState("")
  const [dateCount, setdateCount] = useState([])
  const [iscount, setiscount] = useState(false)
  const [count, setCount] = useState([])
  const [labels, setLabels] = useState([])
  const [countryName, setcountryName] = useState("All Countries")
  const [labelfilter, setlabelfilter] = useState("month")
  const [exportLabel, setexportLabel] = useState([])
  const [ownersCount, setownersCount] = useState("")
  const [accessownersCount, setaccessownersCount] = useState("")
  const [iscustom, setIscustom] =  useState(false)
  const [ownersDetails, setownersDetails] = useState([])

  const handleCountries = async () => {
    try {
      let data = await AxiosService.get("/reports/countries");
      if (data.data.statusCode === 200) {
        setCountries(data.data.businessOwnersCountries);
      }

    } catch (error) {
      console.log(error);
    }
  };

  const handleFormatDate = (date) => {
    var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2)
      month = '0' + month;
    if (day.length < 2)
      day = '0' + day;

    return [year, month, day].join('-');
  }

  const [startdate, setstartdate] = useState(handleFormatDate(new Date().setDate(new Date().getDate() - 6)));
  const [enddate, setenddate] = useState(handleFormatDate(new Date()));

  const handleCount = () => {

    let counts = []

    for (let i = 0; i < exportLabel?.length; i++) {
      let date = exportLabel[i];
      let found = false;



      for (let j = 0; j < dateCount?.length; j++) {
        let obj = dateCount[j];
        let key = Object.keys(obj)[0]

        if (date === key) {
          counts.push(obj[key])
          found = true;
          break;
        }
      }

      if (!found) {
        counts.push(0)
      }

    }
    setCount(counts)

  }

const handleDayFilter = (result) =>{
  let labelArray = [], i;
  for (i = 0; i < result.length; i++) {
    labelArray.push(`${new Date(result[i]).toString().slice(0, 3)}(${new Date(result[i]).toString().slice(8, 10)})`)
  }
  setLabels(labelArray)

}
const handleWeekFilter = (result) =>{
  let labelArray = [], i;
  const handleWeekNumber = (date) => {
    let currentDate = new Date(date);
    let startDate = new Date(currentDate.getFullYear(), 0, 1);
    var days = Math.floor((currentDate - startDate) /
      (24 * 60 * 60 * 1000));

    var weekNumber = Math.ceil(days / 7);
    return ` ${weekNumber} (${new Date(date).toString().slice(8, 10)})`
  }
  for (i = 0; i < result.length; i++) {
    labelArray.push(handleWeekNumber(result[i]))
  }
  setLabels(labelArray)

}

const handleMonthFilter = (result) =>{
  let labelArray = [], i;
  for (i = 0; i < result.length; i++) {
    labelArray.push(`${new Date(result[i]).toString().slice(4, 7)}(${new Date(result[i]).toString().slice(8, 10)})`)
  }
  setLabels(labelArray)  

}

  function datesArray(start, end) {
    let result = [], current = new Date(start);
    

    while (current <= end) {
      result.push(current.toISOString().slice(0, 10)) && (current = new Date(current)) && current.setDate(current.getDate() + 1);
    }
    // eslint-disable-next-line default-case
    switch(labelfilter !== ""){
      case labelfilter === "day" :
       handleDayFilter(result)
        break;
      case labelfilter === "week" :
        handleWeekFilter(result)        
        break;
      case labelfilter === "month" :
        handleMonthFilter(result)   
        break;
      // default :
      //   return true
        
    }
    setiscount(true)    
    setexportLabel(result);
    return result;

  }

  // const handleAllDays = ()=>{
  //     setstartdate("")
  //     setenddate("")
  // }

  const handletoday = (date) => {
    try {
      setstartdate(handleFormatDate(date))
      setenddate(handleFormatDate(date))
      // handleGenerateDates()


    } catch (error) {
      console.log(error)
    }
  }

  const handleyesterday = (date) => {
    try {
      let yesterday = date.setDate(date.getDate() - 1)
      setstartdate(handleFormatDate(yesterday))
      setenddate(handleFormatDate(yesterday))
      // handleGenerateDates()

    } catch (error) {
      console.log(error)
    }

  }

  const handlelast7days = (date) => {
    try {
      let newdate = new Date(date);
      let startdate = newdate.setDate(newdate.getDate() - 6);
      setstartdate(handleFormatDate(startdate))
      setenddate(handleFormatDate(date))

    } catch (error) { 
      console.log(error)
    }
  }

  const handlelast30days = (date) => {
    try {

      let newdate = new Date(date);
      let startdate = newdate.setDate(newdate.getDate() - 30);
      setstartdate(handleFormatDate(startdate))
      setenddate(handleFormatDate(date))

    } catch (error) {
      console.log(error)
    }
  }
  const handlethismonth = (date) => {
    
    try {
      let startdate = new Date(date.getFullYear(), date.getMonth(), 1);
      let enddate = new Date(date.getFullYear(), date.getMonth() + 1, 0);
      setstartdate(handleFormatDate(startdate))
      setenddate(handleFormatDate(enddate))
    } catch (error) {
      console.log(error)
    }
  }
  const handlelastmonth = (date) => {
    try {

      let startdate = new Date(date.getFullYear(), date.getMonth() - 1, 1);
      let enddate = new Date(date.getFullYear(), date.getMonth(), 0);
      setstartdate(handleFormatDate(startdate))
      setenddate(handleFormatDate(enddate))
    } catch (error) {
      console.log(error)
    }
  }
  const handleTimeRange = (text) => {
    let date = new Date()
    switch (text) {

      case text = "today":
        handletoday(date)
        break;

      case text = "yesterday":
        handleyesterday(date)
        break;
      case text = "last7days":
        handlelast7days(date)
        break;
      case text = "last30days":
        handlelast30days(date)
        break;
      case text = "thismonth":
        handlethismonth(date)
        break;
      case text = "lastmonth":
        handlelastmonth(date)
        break;

      default:
        setstartdate("")
        setenddate("")
    }
  }
  const handleShowData = async () => {
    try {
      let res = await AxiosService.post('/reports/filter-owners', {
        country: selectedCountry,
        startdate: startdate,
        enddate: enddate

      })
      if (res.data.statusCode === 200) {
        setdateCount(res.data.dateCount)
        setownersCount(res.data.ownersCount)
        setaccessownersCount(res.data.grantedAccessOwnersCount)
        setownersDetails(res.data.ownersDetails)
        datesArray(
          new Date(startdate?startdate:res.data.dates[0]),
          new Date(enddate?enddate:res.data.dates[res.data.dates.length-1])
        )
        setiscount(true)


      }
    } catch (error) {
      console.log(error)
    }
  }


  useEffect(() => {
    handleCountries();
    // handlelast7days(new Date())
  }, []);

  useEffect(() => {
      setlabelfilter("month")
      
      handleShowData()
  }, [selectedCountry, enddate, startdate])

  useEffect(() => {
    if (iscount === true)
      handleCount()
  }, [dateCount, iscount])



  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: "top",
      },
      title: {
        display: true,
        // text: "Total Business Owners Count",
      },
    },
    scales:{
      y: {min: 0},
    }
  };



  const data = {
    labels,
    datasets: [
      {
        fill: true,
        label: "BussinessOwners",
        data: count,
        borderColor: "rgb(53, 162, 235)",
        backgroundColor: "rgba(53, 162, 235, 0.5)",
      },
    ],
  };


  const handleExport = () => {
    if (dateCount.length > 0) {
      const headerTitle = 'GSG Business Owners Report';
      const merge = [{ s: { c: 0, r: 0 }, e: { c: 1, r: 1 } }];
      const colWidths = [{ wch: 60 }, { wch: 60 }];
  
      const headings = [
        [
          'Date of Signup',
          'First Name',
          'Last Name',
          'Email',
          'Phone Number',
          'App Access Status',
          'Access Granted Date'
        ]
      ];
      const reports = [];
  
      for (let i = 0; i < ownersDetails.length; i++) {
        reports.push({
          'Date of Signup': ownersDetails[i]?.createdAt,
          'First Name': ownersDetails[i]?.firstname,
          'Last Name': ownersDetails[i]?.lastname,
          'Email': ownersDetails[i]?.email,
          'Phone Number': ownersDetails[i]?.mobile,
          'App Access Status': ownersDetails[i]?.appaccess === 'Y' ? 'Active' : 'Inactive',
          'Access Granted Date': ownersDetails[i]?.accessGrantedAt ? ownersDetails[i]?.accessGrantedAt : "-"
        });
      }
  
      const wb = utils.book_new();
      const ws = utils.json_to_sheet([], {
        header: [headerTitle]
      });
      utils.sheet_add_aoa(ws, [[
        'Business Owners Count', ownersCount
      
      ]], { origin: 'A3' });
      utils.sheet_add_aoa(ws, [[
        'Access Granted Business Owners Count', accessownersCount
      ]], { origin: 'A4' });
  
      ws['!cols'] = colWidths;
      ws['!merges'] = merge;
  
      utils.sheet_add_aoa(ws, headings, { origin: 'A6' });
      utils.sheet_add_json(ws, reports, { origin: 'A7', skipHeader: true });
      utils.book_append_sheet(wb, ws, 'Report');
  
      writeFile(wb, `GSG Business Owner Report_${countryName}_${startdate}_${enddate}.csv`);
    }
  };

  
  


  return <>
    {/* <Line options={options} data={data} /> */}
    <div className="adminContentSection">
      <div className="displayFlex alignCenter spaceBetween">
        <div className="titleText "> Reports</div>
        <div className="displayFlex alignCenter spaceBetween gap30">
          <div>
            <select defaultValue={""} onChange={(e) => {
              setSelectedCountry(e.target.value)
              setcountryName(e.target.value)
            }} className="form-select">
              <option>Select Country</option>
              <option value={""} >All Countries</option>
              {countries?.map((e, i) => {
                return <option key={i} value={e} name={e}>{e}</option>;
              })}
            </select>
          </div>

              <div style={{display : "block"}}>
              <div className="displayFlex alignCenter spaceBetween gap30">
            <div>Business   Owners Count:  </div>
            <div className="countNumber">  {ownersCount} </div>
            
          </div>
          
          <div className="displayFlex alignCenter spaceBetween gap30">

          <div>App Accessible Business Owners Count:  </div>
            <div className="countNumber">  {accessownersCount} </div>
            
          </div>
              </div>





        </div>
      </div>
      <div className="maintable mt30">

        <div className="displayFlex alignCenter spaceBetween">
          <div className="displayFlex alignCenter spaceBetween  ">
            {/* ------------------------------do not delete---------------------------------------- */}
            {/* <div className="whitespace">
              Filter by Date: &nbsp;
            </div> */}
            {/* <div className="displayFlex alignCenter spaceBetween  dateGraybg">
              <input type="date" name="startdate" className="form-control" value={startdate} onChange={(e) => {
                setstartdate(e.target.value)
              }}></input>
              <img
                src={rightarrow}
                alt="rightarrow"


              />
              <input type="date" name="enddate" value={enddate} onChange={(e) => {
                setenddate(e.target.value)
              }} className="form-control"></input>



            </div> */}


          </div>
          <div> <button className="exportButton btn" onClick={() => {
            handleExport()
          }}>
            Export     <img
              src={fileexport}
              alt="fileexport"


            />
          </button></div>

        </div>





        <div>



        </div>

        <div className="canvaBlock">

          <div>
            <div className="displayFlex alignCenter spaceBetween">
              <div>Total Business Owners Count</div>
              <div className="displayFlex alignCenter spaceBetween gap5">
                {
                  iscustom?
                  <div className="displayFlex alignCenter spaceBetween  dateGraybg">
                  <input type="date" name="startdate" className="form-control customWidth" value={startdate} onChange={(e) => {
                    setstartdate(e.target.value)
                  }}></input>
                  <img
                    src={rightarrow}
                    alt="rightarrow"
    
    
                  />
                  <input type="date" name="enddate" className="form-control customWidth" value={enddate} onChange={(e) => {
                    setenddate(e.target.value)
                  }} ></input>
    
    
    
                </div> :<></>
    
                }

                <select className="form-select"
                defaultValue = "last7days"
                onChange={(e) => {
                  {
                    handleTimeRange(e.target.value);
                    e.target.value === "customrange" ? setIscustom(true) : setIscustom(false);
                  }

                }}>
                  <option>Select Time Range</option>
                  <option value="all">All</option>
                  <option value="today">Today</option>
                  <option value="yesterday">Yesterday</option>
                  <option value="last7days">Last 7 Days</option>
                  <option value="last30days">Last 30 Days</option>
                  <option value="thismonth">This Month</option>
                  <option value="lastmonth">Last Month</option>
                  <option value="customrange">Custom Range</option>
                </select>
                {/* <select className="form-select" onChange={(e) => {
                  {
                    setlabelfilter(e.target.value);
                    handleShowData()
                  }
                }}>
                  <option value={"day"}>Day</option>
                  <option value={"week"}>Week</option>
                  <option value={"month"}>Month</option>
                </select>*/}
                
                </div> 
            </div>


          </div>




          <Line options={options} data={data} />
        </div>

      </div>
    </div>
  </>

}

export default Reports;
