import React from "react";
import { useNavigate } from "react-router-dom";
import UserBackdrop from "./UserBackdrop";
import logo from "../../images/logo.svg";
import countryicon from "../../images/country-icon.svg";
import { useFormik } from "formik";
import * as Yup from "yup";
import { email, mobileNumber, name } from "../utils/Regex";
import AxiosService from "../../service/ApiService";
import {toast} from 'react-toastify'
function SignUp() {
  const navigate = useNavigate();
  const handleSignUp = async (values) => {
    try {
      sessionStorage.setItem('mobile',values.mobile)
      let res = await AxiosService.post('/users/signup',values)
      if(res.status ===201)
      {
        sessionStorage.setItem('userid',res.data.userid)
        toast.success(res.data.message)
        navigate("/verify-otp");
      }
    
    } catch (err) {
      toast.error(err.response.data.message)
      console.log(err);
    }
  };

  const formik = useFormik({
    initialValues: {
      firstname : "",
      lastname : "",
      mobile : "",
      email : "",
      country : "Nigeria"
    },
    enableReinitialize: true,
    validationSchema: Yup.object({
      firstname : Yup.string()
      .required("Please enter the first name")
      .matches(name, "Invalid first name"),
      
      lastname : Yup.string()
      .required("Please enter the last name")
      .matches(name, "Invalid last name"),
      mobile : Yup.string()
      .required("Please enter the mobile number")
      .matches(mobileNumber, "Invalid mobile number"),
      email : Yup.string()
      .required("Please enter the email")
      .matches(email, "Invalid email")
    }),
    onSubmit: (values) => {
      handleSignUp(values);
    },
  });
  return (
    <>
    <form onSubmit={formik.handleSubmit}>
      <div className="displayFlex">
        <UserBackdrop />
        <div className="leftSection">
          <div className="logoSection">
            <img src={logo} alt="logo" />
          </div>

          <div className="signSection">
            <div className="signTextblock">
              <div className="signtext  signtextActive cursor">Sign up</div>
              <div
                className="signtext cursor"
                onClick={() => {
                  navigate("/sign-in");
                }}
              >
                {" "}
                Sign IN
              </div>
            </div>

            <div className="signBlock">
              <div className="welcometext pb30"> Welcome to Go Startup Go</div>
              <div className="form-group mb15">
                <label htmlFor="exampleInputEmail1">First Name</label>
                <input
                  type="text"
                  name="firstname"
                  className="form-control"
                  id="exampleInputEmail1"
                  aria-describedby="emailHelp"
                  placeholder="Enter First Name"
                  onChange={formik.handleChange}
                  onBlur = {formik.handleBlur}
                  value={formik.values.firstname}
                />
              </div>
              {
                formik.errors.firstname && formik.touched.firstname ? (
                  <p className="error_text">{formik.errors.firstname} </p>

                ) : ""

              }

              <div className="form-group mb15">
                <label htmlFor="exampleInputEmail1">Last Name</label>
                <input
                  type="text"
                  name="lastname"
                  className="form-control"
                  id="exampleInputEmail1"
                  aria-describedby="emailHelp"
                  placeholder="Enter Last Name"
                  onChange={formik.handleChange}
                  onBlur = {formik.handleBlur}
                  value={formik.values.lastname}
                />
              </div>
              {
                formik.errors.lastname && formik.touched.lastname ? (
                  <p className="error_text">{formik.errors.lastname} </p>

                ) : ""

              }
                            <div className="form-group mb15">
                <label htmlFor="exampleInputEmail1">Email</label>
                <input
                  type="text"
                  name="email"
                  className="form-control"
                  id="exampleInputEmail1"
                  aria-describedby="emailHelp"
                  placeholder="Enter email"
                  onChange={formik.handleChange}
                  onBlur = {formik.handleBlur}
                  value={formik.values.email}
                />
              </div>
              {
                formik.errors.email && formik.touched.email ? (
                  <p className="error_text">{formik.errors.email} </p>

                ) : ""

              }

              <div className="phonenumber ">
                <label htmlFor="exampleInputEmail1">Phone number</label>

                <div className="displayFlex">
                  <div className="countryIcon">
                    {" "}
                    <img src={countryicon} alt="country icon" />
                  </div>
                  <div className="form-group mb15 w100per">
                    <input
                      type="text"
                      name="mobile"
                      className="form-control borderleftcss"
                      id="exampleInputEmail1"
                      aria-describedby="emailHelp"
                      placeholder="Enter Phone Number"
                      onChange={formik.handleChange}
                      onBlur = {formik.handleBlur}
                      value={formik.values.mobile}
                    />

                  </div>

                </div>
                {
                formik.errors.mobile && formik.touched.mobile ? (
                  <p className="error_text">{formik.errors.mobile} </p>

                ) : ""

              }
              </div>


              <button
                className="orangeButton"
                type="submit"
              >
                Send OTP
              </button>
            </div>
          </div>
        </div>
      </div>
      </form>
    </>
  );
}

export default SignUp;
