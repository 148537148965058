import React from 'react'
// import loginBanner from "../../images/login-banner.jpg";


function UserBackdrop() {
  return (
 <>


<div className='loginbanner'></div>


 
    

    </>
  )
}

export default UserBackdrop