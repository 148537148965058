import React from 'react'
import { useNavigate, useLocation} from 'react-router-dom';

function Sidebar() {
  const navigate = useNavigate();
  const location = useLocation();
  return <>
  <div className='resourcesLeft'>
        <div className='bg-white p-4 fontPoppins inner'>
        <a href='https://www.gostartupgo.co/' style={{textDecoration:"none"}} target='_blank' rel='noreferrer'>
          <h3 className='fs--20 fw--600 text--primary mt-0 mb-4 resourcesHeading ps-0'>Resources</h3>
          </a>
          <ul>
          
            <li className={location.pathname === '/' ? "menuactive" : ""} >
              <a href='https://www.gostartupgo.co/category/get-inspired/' style={{textDecoration:"none", color:"black"}} target='_blank' rel='noreferrer'>
              Get Inspired</a>
            </li>
            
            <li className={location.pathname === '/' ? "menuactive" : ""} >
              <a href='https://www.gostartupgo.co/category/business-startup/' style={{textDecoration:"none", color:"black"}} target='_blank' rel='noreferrer'>Starting your Business</a>
            </li>
            
            <li className={location.pathname === '/' ? "menuactive" : ""} >
              <a href='https://www.gostartupgo.co/category/business-scale/' style={{textDecoration:"none", color:"black"}} target='_blank' rel='noreferrer'>Growing your Business</a>
            </li>
            
            <li className={location.pathname === '/' ? "menuactive" : ""} >
              <a href='https://www.gostartupgo.co/category/product-development/' style={{textDecoration:"none", color:"black"}} target='_blank' rel='noreferrer'>Product Development</a>
            </li>
            
            <li className={location.pathname === '/' ? "menuactive" : ""} >
              <a href='https://www.gostartupgo.co/category/get-inspired/' style={{textDecoration:"none", color:"black"}} target='_blank' rel='noreferrer'>Success Stories</a>
            </li>
            
            {/* <li className={location.pathname === '/' ? "menuactive" : ""} >
              <a href='https://www.gostartupgo.co/' style={{textDecoration:"none", color:"black"}} target='_blank' rel='noreferrer'>Apply to the  Academy  
              </a>
            </li>
            
            <li className={location.pathname === '/' ? "menuactive" : ""} >
              <a href='https://www.gostartupgo.co/' style={{textDecoration:"none", color:"black"}} target='_blank' rel='noreferrer'>Apply for funding</a>
            </li>
            
            <li className={location.pathname === '/' ? "menuactive" : ""} >
              <a href='https://www.gostartupgo.co/' style={{textDecoration:"none", color:"black"}} target='_blank' rel='noreferrer'>Rescue my business</a>
            </li> */}
          </ul>
        </div>
      </div>
    </>
}

export default Sidebar