import React, { useEffect, useState } from "react";
import popupclose from "../../images/popupclose-icon.svg";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useLogout } from "../../hooks/useLogout";
import {
  
  email,
  mobileNumber,
  name,
  username,
  zipcode,
} from "../utils/Regex";
import AxiosService from "./../../service/ApiService";
import { toast } from "react-toastify";

function AddAdminUser({ addToggle }) {
  let Logout = useLogout()
  const [countries, setCountries] = useState([]);
  
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);

  const [selectedCountry, setSelectedCountry] = useState("");
  const [selectedState, setSelectedState] = useState("");
  //get countries

  const handleCountries = async () => {
    try {
      let res = await AxiosService.get("address/countries");
      if (res.data.statusCode === 200) {
        setCountries(res.data.country);
        
      }
      
    } catch (error) {
      console.log(error);
      toast.error(
        error.response.data.message
          ? error.response.data.message
          : error.response.data.message[0]
      );
    }
  };
  // console.log(countries);
  const handleStates = async (countryid) => {
    try {
      // console.log("countryId",countryId);
      let res = await AxiosService.get(`address/states/${countryid}`);
      // console.log("states",res);
      if (res.data.statusCode === 200) {
        setStates(res.data.state);
      }
    } catch (error) {
      console.log(error);
      toast.error(
        error.response.data.message
          ? error.response.data.message
          : error.response.data.message[0]
      );
    }
  };

  const handleCities = async (stateid) => {
    try {
      console.log("calling handleCities")
      let res = await AxiosService.get(`address/cities/${stateid}`);
      console.log(res)
      if (res.data.statusCode === 200) {

        setCities(res.data.city);
        console.log(res.data.city);
      }
    } catch (error) {
      console.log(error);
      toast.error(
        error.response.data.message
          ? error.response.data.message
          : error.response.data.message[0]
      );
    }
  };
  useEffect(() => {
    handleCountries();
    
  }, []);

    

  useEffect(() => {
    if(selectedCountry !== ""){
     let country=  countries.filter((e, i)=>{
        return e.name === selectedCountry
    })
    
    handleStates(country[0].id)
    }
  }, [selectedCountry])

  useEffect(() => {
    if(selectedState !== ""){
     let state=  states.filter((e, i)=>{
        return e.name === selectedState
    })
    
    handleCities(state[0].id)
    }
  }, [selectedState])

  // const handleAddAdminUser = async (values) => {
  //   try {
  //     // console.log(values);
  //     let res = await AxiosService.post("admin/create", values);
  //     if (res.data.statusCode === 200) {
  //       addToggle(false);
  //       toast.success(res.data.message);
  //     }
  //   } catch (error) {
  //     console.log(error);
  //     toast.error(
  //       error.response.data.message
  //         ? error.response.data.message
  //         : error.response.data.message[0]
  //     );}}






  
  const handleAddAdminUser = async(values) => {
    try{
      console.log(values);
      let res = await AxiosService.post('admin/create', values);
      if(res.data.statusCode === 200){
        addToggle(false);
        toast.success(res.data.message);
      }
    }catch(error){
      if(error.response.status===401)
        Logout()
      toast.error(error.response.data.message?error.response.data.message:error.response.data.message[0]);
    }
  };

  const formik = useFormik({
    initialValues: {
      firstname: "",
      lastname: "",
      username: "",
      email: "",
      country: "",
      mobile: "",
      state: "",
      city: "",
      zipcode: "",
      address: "",
    },
    enableReinitialize: true,
    validationSchema: Yup.object({
      firstname: Yup.string()
        .required("Please enter the first name")
        .matches(name, "Invalid first name"),
      lastname: Yup.string()
        .required("Please enter the last name")
        .matches(name, "Invalid last name"),
      username: Yup.string()
        .required("Please enter the username")
        .matches(username, "Invalid username"),
      email: Yup.string()
        .required("Please enter the email")
        .matches(email, "Invalid email"),
      country: Yup.string().required("Please select the country"),
      mobile: Yup.string()
        .required("Please enter the mobile")
        .matches(mobileNumber, "Invalid mobile number"),
      state: Yup.string().required("Please select the state"),
      city: Yup.string().required("Please select the city"),
      zipcode: Yup.string()
        .required("Please enter the zipcode")
        .matches(zipcode, "Invalid zipcode"),
      address: Yup.string()
        .required("Please enter the street address")
        // .matches(address, "Invalid street address"),
    }),
    onSubmit: (values) => {
      handleAddAdminUser(values);
    },
  });




  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <div className="divoverlay">
          <div className="divoverlayBlock">
            <div className="popupheaderblock">
              <div> Add User </div>

              <img
                src={popupclose}
                alt="Close"
                className="cursorPointer"
                onClick={() => addToggle((prev) => !prev)}
              />
            </div>

            <div className="container-fluid">
              <div className="overdivScroll">
                <div className="row mb40">
                  <div className="col-md-6">
                    <div className="form-group mb15">
                      <label htmlFor="exampleInputEmail1">First Name</label>
                      <input
                        type="text"
                        className="form-control"
                        id="exampleInputEmail1"
                        aria-describedby="emailHelp"
                        placeholder="Enter First Name"
                        name="firstname"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values?.firstname}
                      />
                    </div>
                    {formik.errors.firstname && formik.touched.firstname ? (
                      <p className="error_text">{formik.errors.firstname}</p>
                    ) : null}
                  </div>

                  <div className="col-md-6">
                    <div className="form-group mb15">
                      <label htmlFor="exampleInputEmail1">Last name</label>
                      <input
                        type="text"
                        className="form-control"
                        aria-describedby="emailHelp"
                        placeholder="Enter Last Name"
                        name="lastname"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.lastname}
                      />
                    </div>
                    {formik.errors.lastname && formik.touched.lastname ? (
                      <p className="error_text">{formik.errors.lastname}</p>
                    ) : null}
                  </div>
                  <div className="col-md-6">
                    <div className="form-group mb15">
                      <label htmlFor="exampleInputEmail1">User Name</label>
                      <input
                        type="text"
                        className="form-control"
                        aria-describedby="emailHelp"
                        placeholder="Enter User Name"
                        name="username"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.username}
                      />
                    </div>
                    {formik.errors.username && formik.touched.username ? (
                      <p className="error_text">{formik.errors.username}</p>
                    ) : null}
                  </div>
                  <div className="col-md-6">
                    <div className="form-group mb15">
                      <label htmlFor="exampleInputEmail1">Email</label>
                      <input
                        type="email"
                        className="form-control"
                        aria-describedby="emailHelp"
                        placeholder="Enter Email ID"
                        name="email"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.email}
                      />
                    </div>
                    {formik.errors.email && formik.touched.email ? (
                      <p className="error_text">{formik.errors.email}</p>
                    ) : null}
                  </div>
                  <div className="col-md-6">
                    <div className="form-group mb15">
                      <label htmlFor="exampleInputEmail1">Country</label>
                      <select
                        
                        className="form-select"
                        // aria-describedby="emailHelp"
                        // placeholder="Select the country"
                        name="country"
                        onChange={(e)=>{
                          // eslint-disable-next-line no-lone-blocks
                          {setSelectedCountry(e.target.value); 
                            formik.setFieldValue("country", e.target.value);
                          }
                        }}
                        onBlur={formik.handleBlur}
                        value={formik.values.country}
                      >
                        <option>Select the Country</option>
                        {countries?.map((country, i) => {
                          return (
                            <option
                              key={country.id}
                              value={country.name}
                            >
                              {" "}
                              {country.name}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                    {formik.errors.country && formik.touched.country ? (
                      <p className="error_text">{formik.errors.country}</p>
                    ) : null}
                  </div>
                  <div className="col-md-6">
                    <div className="form-group mb15">
                      <label htmlFor="exampleInputEmail1">Phone no</label>
                      <input
                        type="text"
                        className="form-control"
                        aria-describedby="emailHelp"
                        placeholder="Enter Phone Number"
                        name="mobile"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.mobile}
                      />
                    </div>
                    {formik.errors.mobile && formik.touched.mobile ? (
                      <p className="error_text">{formik.errors.mobile}</p>
                    ) : null}
                  </div>
                  <div className="col-md-6">
                    <div className="form-group mb15">
                      <label htmlFor="exampleInputEmail1">State</label>
                      <select
                        className="form-select"
                        // onfocus="this.size=4;"
                        // onblur="this.size=0;"
                        name="state"
                        onChange={(e)=>{
                            // eslint-disable-next-line no-lone-blocks
                          {setSelectedState(e.target.value);
                            formik.setFieldValue("state", e.target.value);
                          }
                        }}
                        onBlur={formik.handleBlur}
                        value={formik.values.state}
                      >
                        <option >Select the State</option>
                        {states?.map((state, i) => {
                          return (
                            <option
                              key={state.id}
                              value={state.name}
                            >
                              {" "}
                              {state.name}
                            </option>
                          );
                        })}

                        
                      </select>
                    </div>
                    {formik.errors.state && formik.touched.state ? (
                      <p className="error_text">{formik.errors.state}</p>
                    ) : null}
                  </div>

                  <div className="col-md-6">
                    <div className="form-group mb15">
                      <label htmlFor="exampleInputEmail1">City</label>
                      <select
                        className="form-select"
                        // onfocus="this.size=4;"
                        // onblur="this.size=0;"
                        name="city"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values?.city}
                      >
                        <option >Select the City</option>

                        {
                          cities?.map((city, i) =>{
                            return <option
                             key={city.id}
                             value={city.name}
                            >
                              {city.name}
                            </option>

                          })
                        }
                      </select>
                    </div>
                    {formik.errors.city && formik.touched.city ? (
                      <p className="error_text">{formik.errors.city}</p>
                    ) : null}
                  </div>

                  <div className="col-md-6">
                    <div className="form-group mb15">
                      <label htmlFor="exampleInputEmail1">Zip Code</label>
                      <input
                        type="text"
                        className="form-control"
                        aria-describedby="emailHelp"
                        placeholder="Enter Zip Code"
                        name="zipcode"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.zipcode}
                      />
                    </div>
                    {formik.errors.zipcode && formik.touched.zipcode ? (
                      <p className="error_text">{formik.errors.zipcode}</p>
                    ) : null}
                  </div>

                  <div className="col-md-6">
                    <div className="form-group mb15">
                      <label htmlFor="exampleInputEmail1">Street Address</label>
                      <input
                        type="text"
                        className="form-control"
                        aria-describedby="emailHelp"
                        placeholder="Enter Street Address"
                        name="address"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.address}
                      />
                    </div>
                    {formik.errors.address && formik.touched.address ? (
                      <p className="error_text">{formik.errors.address}</p>
                    ) : null}
                  </div>
                </div>
              </div>
              <div className="row mb40">
                <div className="col-md-12">
                  <div className="displayFlex  alignCenter gap15 w100per   justifyflexend">
                    <button className="primaryorangeButtonoutline" onClick={() => {addToggle((prev) => !prev);
                    formik.resetForm();
                    }}>
                      {" "}
                      Close{" "}
                    </button>

                    <button className="primaryorangeButton" type="submit">
                      {" "}
                      Save{" "}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </>
  );
}

export default AddAdminUser;
