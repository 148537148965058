import React, { useState } from 'react'
import { Routes, Route, Navigate } from "react-router-dom";
import Home from '../../students/Home'
import Navbar from '../../students/Navbar';
import Sidebar from '../../students/Sidebar';
// import Post from '../../students/Post';
// import PostDetails from '../../students/PostDetails';
function StudentRoutes() {
  let [toggleSideBar, setToggleSideBar] = useState(true)
  return <>
    <div className="displayFlex studentBodyContainer">
      <Navbar toggleSideBar={setToggleSideBar} />
      <div className='container studentContainer'>
        <div className='row'>
          {toggleSideBar ? <Sidebar /> : <></>}
          <Routes>
            <Route path='dashboard' element={<Home />} />
            <Route path='/' element={<Navigate to='dashboard' />} />
            {/* <Route path='/post' element={<Post/>} />
            <Route path='/post-details' element={<PostDetails/>} /> */}
          </Routes>
        </div>
      </div>
    </div>
  </>
}

export default StudentRoutes