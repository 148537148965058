import React, { useEffect } from 'react';
import Section3 from '../../images/landingPage/press-releases/blog3.jpg';
import Gsglogo from '../../images/landingPage/gsglogo.png';
import Section6 from '../../images/landingPage/press-releases/Building-Sustainable-Businesses.jpg';
import SaSquared from '../../images/landingPage/sasquaredventureslogo.png';
import CircleCheckIcon from '../../images/landingPage/CircleCheck.svg';
import Header from './common/Header';
import Footer from './common/Footer';
import Copyright from './common/Copyright';
import AOS from 'aos';
import 'aos/dist/aos.css';
import styles from './News.module.scss';

function Blog3() {    

    useEffect(() => {
        AOS.init();
        window.scrollTo(0, 0);
    }, []);

    return <>
        <Header />
        <section className={`${styles.blogSection2} container-fluid`}>
            <div className={`container py-5`}>
                <div className='row'>
                    <div className='col-sm-6 col-xs-12 pe-lg-5 pe-xs-0 m-mb-10'>
                        <div className='position-relative'>
                            <img src={Section3} alt='GoStartupGo partners with SaSquared Ventures to fund micro entrepreneurs' className='w-100 h-auto br--10' data-aos="fade-right" />
                            <div className={`${styles.date} bg--primary position-absolute p-2  br--10 text-white`}>September 15th, 2024</div>
                        </div>
                    </div>
                    <div className='col-sm-6 col-xs-12'>
                        <div className={`${styles.date} d-inline-block mb-3 ms-lg-5 ms-xs-0 bg--secondary px-4 py-2  br--10 text-white`} data-aos="fade-up">Partnerships</div>
                        <h3 className='fs--30 fw--600 pb-4 text-black fontPoppins lh-45 ps-lg-5 ps-xs-0' data-aos="fade-up">GoStartUpGo Foundation Partners with SasquaredVentures Limited to Empower Entrepreneurs in Nigeria</h3>
                        <p className='fs--18 pb-1 text-black fontPoppins lh-35  ps-lg-5 ps-xs-0' data-aos="fade-up"><span className='text--primary'>Atlanta, GA</span><br/>GoStartUpGo Foundation, a U.S.-based 501(c)(3) nonprofit focused on fostering entrepreneurship in developing countries, proudly announces its partnership with <strong>SasquaredVentures Limited</strong>, a leading Nigerian business solutions provider, to manage its entrepreneurship programs in Nigeria. This partnership will enable GoStartUpGo’s mission of empowering entrepreneurs in Africa by providing them with the tools, mentorship, and financial resources needed to build sustainable businesses.  </p>
                        <p className='fs--18 pb-1 text-black fontPoppins lh-35  ps-lg-5 ps-xs-0' data-aos="fade-up"><strong>SasquaredVentures Limited</strong>, a trusted name in business development and financial management in Nigeria, will spearhead the program's local operations. As part of this collaboration, SasquaredVentures will oversee the day-to-day management of GoStartUpGo’s Nigerian entrepreneurship program, which includes the disbursement of funds, program administration, and tracking of the entrepreneurs' progress.</p>
                    </div>
                </div>
            </div>
        </section>
        <section className={`${styles.section5} container-fluid`}>
            <div className={`container py-5`}>
                <div className='row'>
                   <div className='col-sm-6 col-xs-12'>
                        <h3 className='fs--30 fw--600 pb-4 text-black fontPoppins lh-45 pe-lg-5 pe-xs-0' data-aos="fade-up">The Program: Building Sustainable Businesses </h3>
                        <p className='fs--18 pb-1 text-black fontPoppins lh-35  pe-lg-5 pe-xs-0' data-aos="fade-up">The program will provide Nigerian entrepreneurs with access to:</p>
                        <ul className='ps-1'>
                            <li className='list-unstyled pb-3 fs--18 d-flex align-items-start' data-aos="fade-up">
                                <img src={CircleCheckIcon} alt='Circle Check' className='me-3 pt-1' />
                                <div className='fontPoppins'>
                                    <p className='fs--16 text--white3 lh-30 mb-0'>Intensive business training covering areas such as financial literacy, product development, and marketing.</p>
                                </div> 
                                
                            </li>
                            <li className='list-unstyled pb-3 fs--18 d-flex align-items-start' data-aos="fade-up">
                                <img src={CircleCheckIcon} alt='Circle Check' className='me-3 pt-1' />
                                <div className='fontPoppins'>
                                    <p className='fs--16 text--white3 lh-30 mb-0'>Ongoing mentorship from experienced business leaders.</p>
                                </div> 
                            </li>
                            <li className='list-unstyled pb-3 fs--18 d-flex align-items-start' data-aos="fade-up">
                                <img src={CircleCheckIcon} alt='Circle Check' className='me-3 pt-1' />
                                <div className='fontPoppins'>
                                    <p className='fs--16 text--white3 lh-30 mb-0'>Funding and grants to help entrepreneurs start and grow their businesses.</p>
                                </div> 
                            </li>
                        </ul>
                        <p className='fs--18 pb-1 text-black fontPoppins lh-35  pe-lg-5 pe-xs-0' data-aos="fade-up"><strong>SasquaredVentures’ Role in the Partnership</strong> SasquaredVentures will play a key role in ensuring that all funds are allocated appropriately and that the program is running efficiently. By leveraging their expertise in the Nigerian market, SasquaredVentures will provide crucial oversight, ensuring the success and sustainability of the businesses that GoStartUpGo supports. This partnership allows GoStartUpGo Foundation to focus on expanding its reach, while SasquaredVentures handles the local execution and management.</p>
                       
                    </div>
                    <div className='col-sm-6 col-xs-12 ps-lg-5 ps-xs-0'>
                        <img src={Section6} alt='Building Sustainable Businesses' className='w-100 height420 br--10' data-aos="fade-right" />
                    </div>
                    <div className='col-sm-12 col-xs-12'>
                    <p className='fs--18 pb-1 text-black fontPoppins lh-35 pe-lg-5 pe-xs-0' data-aos="fade-up"><strong>Empowering Entrepreneurs for a Stronger Future</strong> The partnership comes at a critical time, as entrepreneurship in Nigeria continues to be a key driver of economic development and job creation. With this collaboration, GoStartUpGo Foundation aims to help more entrepreneurs in Nigeria overcome obstacles, access the resources they need, and build businesses that positively impact their communities.<br/>
                    “We are excited to partner with SasquaredVentures Limited to bring our entrepreneurship program to life in Nigeria,” said Sayu Abend, Founder and Executive Director of GoStartUpGo Foundation. “Their expertise and deep knowledge of the local market will ensure that we can help more entrepreneurs build successful businesses and strengthen their communities."</p>
                    </div>
                </div>
            </div>
        </section>
        <section className={`container-fluid bg--grey1 py-3`}>
            <div className={`container py-5`}>
                <div className={`d-grid pt-2 ${styles.gridContainer}`}>
                    <div className={`br--10 bg-white px-4 py-3 br--10 text-center pb-3`}>
                        <img src={Gsglogo} alt='GoStartUpGo' width={260} className='py-4'/>
                        <h3 className='fs--30 fw--600 pb-2 text-black fontPoppins lh-45 ' data-aos="fade-up">About GoStartUpGo Foundation </h3>
                        <p className='fs--18 pb-1 text-black fontPoppins lh-35  ' data-aos="fade-up">GoStartUpGo Foundation is a nonprofit organization dedicated to empowering entrepreneurs in developing countries through training, mentorship, and access to funding. By helping people build sustainable businesses, GoStartUpGo is committed to creating lasting economic and social change.  </p>
                    </div>
                    <div className={`br--10 bg-white px-4 py-3 br--10 text-center pb-3`}>
                        <img src={SaSquared} alt='GoStartUpGo' width={'53%'} className='pt-4 pb-5 mb-3'/>
                        <h3 className='fs--30 fw--600 pb-2 text-black fontPoppins lh-45' data-aos="fade-up">About SasquaredVentures Limited </h3>
                        <p className='fs--18 pb-1 text-black fontPoppins lh-35' data-aos="fade-up">SasquaredVentures Limited is a Nigerian company that provides innovative business solutions, financial management, and consulting services to help businesses grow and thrive. With a deep understanding of the Nigerian market and a commitment to fostering entrepreneurship, SasquaredVentures is the ideal partner to manage GoStartUpGo’s programs in Nigeria.</p>
                    </div>
                </div>
            </div>
        </section>
        {/* <section className='container'>
            <div className={` ${styles.formSection}`}>
                <div className={`row`}>
                   <div className='col-sm-6 col-xs-12 py-5 px-lg-5 px-xs-0'>
                        <img src={GetInTouch} alt='get involved' className='w-100 h-auto br--20'/>
                    </div>
                    <div className='col-sm-6 col-xs-12 ps-4'>
                        <h2 className='fs--24 pt-5 pb-3 px-4 text-black fontPoppins lh-25 pe-lg-5 pe-xs-0' data-aos="fade-up"><span>For more information, please contact </span></h2>
                        <div data-aos="fade-up">
                            <div className={`px-4 h-100`}>
                                <HubspotForm
                                    portalId='23639501'
                                    formId='8e3570fa-d3ce-4f48-9560-a57d6ba67654'
                                    onSubmit={() => console.log('Submit!')}
                                    onReady={(form) => console.log('Form ready!')}
                                    loading={<div>Loading...</div>}
                                />
                            </div>
                        </div>
                    </div>
                    
                </div>
            </div>
        </section> */}
        <Footer />
        <Copyright />
    </>
}

export default Blog3