import axios from "axios";
import pageloader from "../images/pageloader.gif"
const url = process.env.REACT_APP_API_URL;

const isExclude = (url)=>{
  return true
}
const AxiosService = axios.create({
  baseURL: url,
  headers: {
    'Content-Type': 'application/json'
  }
});

AxiosService.interceptors.request.use(
  config => {
    // console.log(config)
    const token = sessionStorage.getItem('token')
    if (token) {
      config.headers.Authorization = `Bearer ${token || null}`
    } 

    let div = document.createElement('div')
    div.setAttribute('class','pageLoader')
    div.setAttribute('id','loader')

    let image = document.createElement('img')
    image.setAttribute('src',pageloader)

    div.appendChild(image)
    document.body.appendChild(div)

    return config;
  },
  error => {
    const element = document.getElementById("loader");
    element.remove();
    return Promise.reject(error)
  }
);

AxiosService.interceptors.response.use(
  response => {
    const element = document.getElementById("loader");
    element.remove();
    return response;
  },
  error => {
    const element = document.getElementById("loader");
    element.remove();
   return Promise.reject(error)
  }
);
export default AxiosService;
