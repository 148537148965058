/* eslint-disable no-lone-blocks */
import React, {useEffect, useRef, useState} from 'react'
import { useNavigate } from 'react-router-dom';
import UserBackdrop from './UserBackdrop'
import logo from "../../images/logo.svg";

import AxiosService from './../../service/ApiService'
import {toast} from 'react-toastify'

function OTP() {
  const navigate = useNavigate();
  let [error, setError] = useState(false)
  let [mobile, setMobile] = useState("")
  let [value, setValue] = useState({
    num1 : "",
    num2 : "",
    num3 : "",
    num4 : "",
    num5 : "",
    num6 : ""
  })

  let inputRef1 = useRef();
  let inputRef2 = useRef();
  let inputRef3 = useRef();
  let inputRef4 = useRef();
  let inputRef5 = useRef();
  let inputRef6 = useRef();


// let lastFourDigits = mobile.

 const handleInputFocus = (text) =>{
 
  if(text === "inputRef1"){
    if(inputRef1.current.value !== ""){
      inputRef2.current.focus()
    }else{
      inputRef1.current.focus();
    }
  }else if(text === "inputRef2"){
    if(inputRef2.current.value !== ""){
      inputRef3.current.focus()
    }else{
      inputRef2.current.focus();
    }
  }else if(text === "inputRef3"){
    if(inputRef3.current.value !== ""){
      inputRef4.current.focus()
    }else{
      inputRef3.current.focus();
    }
  }else if(text === "inputRef4"){
    if(inputRef4.current.value !== ""){
      inputRef5.current.focus()
    }else{
      inputRef4.current.focus();
    }
  }else if(text === "inputRef5"){
    if(inputRef5.current.value !== ""){
      inputRef6.current.focus()
    }else{
      inputRef5.current.focus();
    }
  }else if(text === "inputRef6"){
    if(inputRef6.current.value === ""){
      inputRef6.current.focus();
    }else if(inputRef5.current.value !== "" && inputRef6.current.value === ""){
      inputRef5.current.focus();
    }
  }
 
 }

  const handleResendOtp = async()=>{
    try{
      let mobile = sessionStorage.getItem('mobile')
      let res = await AxiosService.post('/users/get-otp',{mobile})
      if(res.status === 200)
      {
          toast.success(res.data.message)
      }
    }catch(error){
      toast.error(error.response.data.message?error.response.data.message:error.response.data.message[0]);
    }
  }

  const handleVerifyOtp = async (e)=>{
    e.preventDefault();
    try{
      
      let otp =  value.num1 + value.num2 + value.num3 + value.num4 + value.num5 + value.num6 
      console.log(otp.length)
      if(otp?.length === 6){
        setError(false);
        let userid = sessionStorage.getItem('userid')
        let res = await AxiosService.post('/users/verify-otp',{userid:Number(userid),otp})
        if(res.status===200)
        {
            toast.success(res.data.message)
        }
        setValue({
         num1 : "",
         num2 : "",
         num3 : "",
         num4 : "",
         num5 : "",
         num6 : ""
     
       } )
        navigate('/create-password')

      }else{
        setError(true)
      }
    }catch(error){
      toast.error(error.response.data.message?error.response.data.message:error.response.data.message[0])
    }
    
    
   
    
  }

   const handleBackFocus = async (event, text) =>{
    
   
    
    const key = event.key;
    if(text ==="inputRef2" && value.num2 === ""){
      if (key === "Backspace" || key === "Delete") {
        setValue({...value,  num2 : ""});
        inputRef1.current.focus();
    }
    }else if(text ==="inputRef3" && value.num3 === ""){
      if (key === "Backspace" || key === "Delete") {
        setValue({...value,  num3 : ""});
        inputRef2.current.focus();
    }
    }else if(text ==="inputRef4" && value.num4 === ""){
      if (key === "Backspace" || key === "Delete") {
        setValue({...value,  num4 : ""});
        inputRef3.current.focus();
    }
    }else if(text ==="inputRef5" && value.num5 === ""){
      if (key === "Backspace" || key === "Delete") {
        setValue({...value,  num5 : ""});
        inputRef4.current.focus();
    }
    }else if(text ==="inputRef6" && value.num6 === ""){
      if ((key === "Backspace" || key === "Delete")) {
        setValue({...value,  num6 : ""});
        inputRef5.current.focus();
        
    }
    
    }
   }
    
  useEffect(()=>{
    if(sessionStorage.getItem('mobile')){
      let mobileNumber = sessionStorage.getItem('mobile');

      // let fourDigits = String(mobileNumber).slice(-4)
      setMobile(String(mobileNumber).slice(0, -4).replace(/./g,"X")+String(mobileNumber).slice(-4))
    }
  },[mobile]) 
  return <>
    <div className='displayFlex'>
      <UserBackdrop />
      <div className='leftSection'>
        <div className='logoSection' >
          <img
            src={logo}
            alt='logo'


          />

        </div>

        <div className='signSection'>


          <div className='signBlock'>

            <div className='flexSection'>
              <div className='blackButton m15'>

                Complete Signup
              </div>

            </div>

            <div className='verificationText m15'>A verification code has been sent</div>

            <div className='content m15 underline pb30'>{mobile}</div>

            <div className='content m15  '> Enter the 6 digit code </div>
<form>
            <div className='flexSection gap5 mb30 '>
              <input type="text" className="form-control textAlignCenter" maxLength={1} value={value?.num1?.replace(/[^0-9]/g, "")} ref={inputRef1} id='inputRef1'  onChange={(e) => {{
                setValue({...value,  num1 : e.target.value});

                     handleInputFocus("inputRef1")} 
              }} required onKeyDown={(e) =>{
                
                handleBackFocus(e, "inputRef1")
              }} />
              <input type="text" className="form-control textAlignCenter"  maxLength={1} ref={inputRef2} value={value.num2?.replace(/[^0-9]/g, "")} id='inputRef2' onChange={(e) =>{{
                setValue({...value,  num2 : e.target.value});
                handleInputFocus("inputRef2")
              }}}required onKeyDown={(e) =>{
                
                handleBackFocus(e, "inputRef2")
              }}/>
              <input type="text" className="form-control textAlignCenter" maxLength={1} ref={inputRef3} value={value.num3?.replace(/[^0-9]/g, "")} onChange={(e) => {{
                setValue({...value,  num3 : e.target.value});
                handleInputFocus("inputRef3")}
              }} required  onKeyDown={(e) =>{
                
                handleBackFocus(e, "inputRef3")
              }} />
              <input type="text" className="form-control textAlignCenter" maxLength={1} ref={inputRef4} value={value.num4?.replace(/[^0-9]/g, "")} onChange={ (e) =>{{
              setValue({...value,  num4 : e.target.value});
              handleInputFocus("inputRef4")}
              }}required  onKeyDown={(e) =>{
                
                handleBackFocus(e, "inputRef4")
              }} />
              <input type="text" className="form-control textAlignCenter" maxLength={1} ref={inputRef5} value={value.num5?.replace(/[^0-9]/g, "")} onChange={ (e) =>{{
              setValue({...value,  num5 : e.target.value});
              handleInputFocus("inputRef5")}
              }}required  onKeyDown={(e) =>{
                
                handleBackFocus(e, "inputRef5")
              }} />
              <input type="text" className="form-control textAlignCenter" maxLength={1} ref={inputRef6} value={value.num6?.replace(/[^0-9]/g, "")} onChange={ (e) =>{{
                setValue({...value,  num6 : e.target.value});
                handleInputFocus("inputRef6")}
              }} onKeyDown={(e) =>{
      
                handleBackFocus(e, "inputRef6")
              }} required/>
            </div>
            {error ? <p className="error_text">*required</p>: ""}

            <div 
            className='pb30 cursorPointer' 
            style={{textAlign:"right"}}
            
            onClick={()=>{
              handleResendOtp()
            }}
            >
              Resend
            </div>

            <button className='orangeButton' type="submit" onClick={(e)=>{
              handleVerifyOtp(e)

            }}>Verify</button>
            </form>
          </div>
        </div>
      </div>
    </div>
  </>
}

export default OTP