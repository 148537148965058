import React, { useState, useRef, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {Tab, Tabs } from "react-bootstrap";
import saveIcon from "../../../../images/save.png";
import previewIcon from "../../../../images/preview.png";
// import previewImg from "../../../../images/previewImg.jpg";
import Editor from "react-simple-wysiwyg";
import CalendarIcon from "../../../../images/calendar.png";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import AxiosService from "../../../../service/ApiService";
import { toast } from 'react-toastify';

function AddCalendar() {
  const navigate = useNavigate();
  const [html, setHtml] = useState("");

  const [date, setDate] = useState(new Date());
  const [flash, setflash] = useState("")
  const clock = <i class="fa fa-text-width"></i>;
  // const image = <i class="fa fa-picture-o"></i>;
  // const video = <i class="fa fa-file-video-o"></i>;

  function onChange(e) {

    setHtml(e.target.value);
    setflash("")
  }

  // const Image = () => {
  //   return (
  //     <div>
  //       <Form className="row adminForm">
  //         <div className="col-sm-6 col-xs-12">
  //           <label class="form-label" for="Upload Image">
  //             Upload Image
  //           </label>
  //           <Form.Group className="mb-3" controlId="UploadImage">
  //             <Form.Control type="file" id="file" />
  //             <Form.Label for="file">Choose file</Form.Label>
  //             <small className="fs--12 text--grey6">
  //               *Image must be in png,jpeg,jpg format.
  //             </small>
  //           </Form.Group>
  //         </div>
  //         <div className="col-sm-6 col-xs-12">
  //           <Form.Group className="mb-3" controlId="Alt">
  //             <Form.Label>Alt</Form.Label>
  //             <Form.Control type="text" />
  //           </Form.Group>
  //         </div>
  //         <div className="col-sm-12 col-xs-12">
  //           <Form.Group className="mb-3" controlId="URL">
  //             <Form.Label>URL</Form.Label>
  //             <Form.Control type="text" />
  //           </Form.Group>
  //         </div>
  //       </Form>
  //     </div>
  //   );
  // };

  // const Video = () => {
  //   return (
  //     <div>
  //       <Form className="row adminForm">
  //         <div className="col-sm-12 col-xs-12">
  //           <label class="form-label" for="Upload Video">
  //             Upload Video
  //           </label>
  //           <Form.Group className="mb-3" controlId="UploadImage">
  //             <Form.Control type="file" id="file" />
  //             <Form.Label for="file">Choose file</Form.Label>
  //             <small className="fs--12 text--grey6">
  //               *Video must be in mp4, mkv, mov format.
  //             </small>
  //           </Form.Group>
  //         </div>
  //         <div className="col-sm-12 col-xs-12">
  //           <Form.Group className="mb-3" controlId="URL">
  //             <Form.Label>URL</Form.Label>
  //             <Form.Control type="text" />
  //           </Form.Group>
  //         </div>
  //       </Form>
  //     </div>
  //   );
  // };

  const handleCalenderSubmit = async () => {
    try {
      // console.log(html, date);
      if(html !== ""){
        let res =  await AxiosService.post('/content-management-tool/calender/add', {
          text : html,
          date : date
  
        })
  
  
        if(res.data.statusCode === 200){
          navigate('/admin/calendar-section')
          // handleClose()
          toast.success(res.data.message)
        }
      }else{
        // setflash("solid 1px #ff0000")
        toast.error("Please fill the required details")
      }


    } catch (error) {
      // console.log(error);
      toast.error(
        error.response.data.message
          ? error.response.data.message
          : error.response.data.message[0]
      );
    }
  };

  const CustomInput = React.forwardRef(({ value, onClick }, ref) => (
    <input
      type="text"
      value={value}
      onClick={onClick}
      readOnly
      ref={ref}
      style={{
        position: "absolute",
        top: "-9999px",
        left: "-9999px",
        opacity: 0,
        pointerEvents: "none",
      }}
    />
  ));
  const datePickerRef = useRef(null);

  //edit event by id
  const params = useParams()
  // const [event, setEvent] = useState({})

  const handleEventById = async () => {
    try{
      let {data} = await AxiosService.get(`/content-management-tool/calender/${params?.id}`)
      
      if(data.statusCode === 200){
        // setEvent(data.event[0])
        setHtml(data.event[0].text)
        setDate(new Date((data.event[0].date)))
      }

    }catch(error){
       console.log(error);
       toast.error(
        error.response.data.message
          ? error.response.data.message
          : error.response.data.message[0]
      );

    }
  }

  useEffect(() => {
    if(params.id)
      handleEventById()

  }, [params])
  const handleFormatDate = (date) => {
    var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2)
      month = '0' + month;
    if (day.length < 2)
      day = '0' + day;

    return [year, month, day].join('-');
  }


  const handleEditEventById = async () => {
    try{
      if(html !== ""){
        let {data} = await AxiosService.put(`/content-management-tool/calender/edit/${params?.id}`, {
          text : html,
          date : handleFormatDate(date)
        })
        if(data.statusCode === 200){
          navigate('/admin/calendar-section')
          toast.success(data?.message)
        }
      }else{
        // setflash("solid 1px #ff0000")
        toast.error("Please fill the required details")
      }
    }catch(error){
       console.log(error);
       toast.error(
        error.response.data.message
          ? error.response.data.message
          : error.response.data.message[0]
      );

    }
  }
  return (
    <>
      <div className="adminContentSection">
        <div className="titleSection">
          <div className="titleText "> Calendar section</div>
          <div className="flexSection">
            <div className="displayFlex alignCenter spaceBetween gap15">
              <button
                className="btnDark d-flex align-items-center"
                onClick={() => {
                  // navigate('/admin/calendar-section')
                  params.id ? handleEditEventById() : 
                  handleCalenderSubmit();
                }}
              >
                <img src={saveIcon} alt="plusicon" className="pe-1" /> Save
              </button>
            </div>
          </div>
        </div>

        <div className="mt30">
          <div className="row">
            <div className="col-sm-6 col-xs-12">
              <div className="cmsWLeft mb-4">
                <Tabs
                  defaultActiveKey="text"
                  id="watch-tab"
                  className="watchTab"
                >
                  
                  <Tab eventKey="text" title={<span>{clock} Text</span>}>
                  <div style={{border : flash}}>
                    <Editor value={html} onChange={onChange}
                     
                    /> </div>
                  </Tab>
                 
                </Tabs>
              </div>
            </div>
            <div className="col-sm-6 col-xs-12">
              <div className="cmsWRight">
                <div className="header p-3">
                  <img src={previewIcon} alt="Preview icon" className="pe-1" />{" "}
                  <span className="fs--14">Preview</span>
                </div>
                <div className="body p-3 d-flex justify-content-center mt-3">
                  <div className={html?.length > 33 ? "inner p-3 px-4 mw-100 f-c" : "inner p-3 w-50 mw-100" }>
                    <div className="d-flex mb-3">
                      <img
                        src={CalendarIcon}
                        alt="Calendar Icon"
                        onClick={() => {
                          datePickerRef.current.setOpen(true);
                        }}
                      />
                      <div style={{width : "5%"}}>
                      <DatePicker
                        id="datepicker-input"
                        selected={date}
                        onChange={(date) => setDate(date)}
                        dateFormat="MM/dd/yyyy"
                        customInput={<CustomInput />}
                        ref={datePickerRef}
                      />
                      </div>
                      <span className="fs--16 fw--500">
                        {new Date(date).toLocaleString("en-us", {
                          month: "long",
                          day: "numeric",
                        })}
                      </span>
                    </div>
                    <div dangerouslySetInnerHTML={{ __html: html }}></div>
                    {/* <p className='fs--14 fw--400 py-2 text--grey6'>Lorem Ipsum is simply dummy text of the printing and typesetting industry.Lorem Ipsum is simply dummy text of the printing and typesetting industry</p> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default AddCalendar;
