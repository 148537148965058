/* eslint-disable react/no-danger-with-children */
import React, { useState, useEffect } from 'react';
import Carousel, { consts } from 'react-elastic-carousel';
import AxiosService from "../../service/ApiService";
import { toast } from "react-toastify";
import { useLogout } from "../../hooks/useLogout";
import { NoData } from './NoData';

const breakPoints = [
  { width: 1, itemsToShow: 1 },
  { width: 550, itemsToShow: 3 },
  { width: 768, itemsToShow: 3 },
  { width: 1200, itemsToShow: 3 },
];

export const WatchTheLatest = () => {
  let [announcements, setAnnouncements] = useState([]);
  let Logout = useLogout();
  const handleGetAllAnnouncements = async () => {
    try {
      let res = await AxiosService.get(
        "/content-management-tool/student/watchlatests"
      );
      if (res.data.statusCode === 200) {
        setAnnouncements(res.data.watchLatest);
      }
    } catch (error) {
      if (error.response.status === 401) Logout();
      toast.error(
        error.response.data.message
          ? error.response.data.message
          : error.response.data.message[0]
      );
    }
  };
  useEffect(() => {
    handleGetAllAnnouncements();
  }, []);
  const handleContextMenu = (event) => {
    //prevent right click
    event.preventDefault();
  };
  return (
    <div className='px-4'>
      {
        announcements.length!==0?<div className='row'>
        {announcements.map((user) => (
          user?.videoUrl !== null ?
            <div className='col-sm-4 col-xs-12'>
              <div className='watchlatestBlock' onContextMenu = {(e) => handleContextMenu(e)}>
                <video
                  width="188"
                  height="150"
                  controls
                  src={user?.videoUrl}
                  autoPlay
                  playsInline
                  muted
                  controlsList="nodownload">
                  Your browser does not support the video tag.
                </video>
                <div dangerouslySetInnerHTML={{ __html: user.text }} className='mt-0 fs--12 text--grey6 fw--500 p5px pb-0 mb-0 text-truncate' title={user.text}></div>
                <a href={user?.videoRedirectUrl} target='_blank' rel="noreferrer" className='mt-0 fs--12 link--hover fw--500 p5px mb-0 text--primary text-decoration-none text-end d-block'>Read more</a>
                </div>
            </div>

            :
            <div className='col-sm-4 col-xs-12'>
              <div className='watchlatestBlock'>
              <img src={user?.imageUrl} alt='Profile pic' className='mb-2 br--10 w-100' />
              
               
                <div dangerouslySetInnerHTML={{ __html: user.text }} className='mt-0 fs--12 text--grey6 fw--500 p5px pb-0 mb-0 text-truncate' title={user?.text}


                ></div>

                <a href={user?.imageRedirectUrl} target='_blank' rel="noreferrer" className='mt-0 fs--12 link--hover fw--500 p5px mb-0 text--primary text-decoration-none text-end d-block'>Read more</a>
                </div>
            </div>
        ))}
      </div>:<NoData/>
      }
    </div>
  )
}
