import React from 'react'
// import { useNavigate } from 'react-router-dom';
import adminlogo from "../../images/landingPage/blackLogo.svg";
import adminlogout from "../../images/logout-icon2.svg";
import mobilemenu from "../../images/studentmenuIcon.svg";
import { useLogout } from '../../hooks/useLogout';
function Navbar({toggleSideBar}) {
    let Logout = useLogout()

    let handleSidebar = () => {

    }

    return <>

        <div className='adminHeader bg-white container-fluid'>
            <div className='container d-flex spaceBetween'>
            <div className='logoBlock'>
                <img src={adminlogo} alt='admin' />
                {/* <div className='mobileMenu'  onClick={()=>{toggleSideBar(prev=>!prev)}}>
                    <img
                        src={mobilemenu}
                        alt='mobile'

                    /> </div> */}
            </div>
            <div className='displayFlex alignCenter gap30'>
                {/* <div>   <img
                    src={notification}
                    alt='notify'

                /> </div> */}
                <div onClick={() => {
                    Logout()
                }}>
                    <button className='logoutButton slogoutButton gap15'>
                        <div className="logoutText">logout</div>
                        <div>

                            <img
                                src={adminlogout}
                                alt='admin'

                            />

                        </div>

                    </button>


                </div>
            </div>
            </div>
        </div>




        {/* <div>Navbar</div> */}
    </>
}

export default Navbar