import React from 'react'
// import { useNavigate } from 'react-router-dom'
import UserBackdrop from './UserBackdrop'

function TermsAndCondition({setTermsToggle,setToggle, setCheck}) {
  // const navigate = useNavigate();
  const handleAccept = () =>{
    setTermsToggle(false)
    setCheck(true);
  }

  const handleDecline = () =>{
    setTermsToggle(false)
    setCheck(false)
  }
  return (
    <>
      <div className='displayFlex'>
        <UserBackdrop />

      </div>
      <div className='overlayDiv'>


        <div className='overlayContent'>

          <div className='termTextblock'>
            <div className='termtext  termtextActive' onClick={()=>{
             setToggle(true)
            }}>Term & Condition</div>
            <div className='termtext' onClick={() =>{
              setToggle(false)
            }}> Privacy Policy</div>
          </div>

          <div className='innercontent'>


            <strong>Terms of Use</strong> <br />


            The GoStartupGo Foundation website located at www.GoStartUpGo.org is a copyrighted work belonging to GoStartupGo. Certain features of the Site may be subject to additional guidelines, terms, or rules, which will be posted on the Site in connection with such features. All such additional terms, guidelines, and rules are incorporated by reference into these Terms. GoStartupGo Foundation offers this website, including all information, products and services available from this website to you the user, conditioned upon your acceptance of all the terms, conditions, policies and notices stated here. Your continued use of the website constitutes your agreement to all such terms, conditions, policies and notices. <br />
            <br />


            <strong>Your Use of this Site</strong> <br />


            This website is for your personal and non-commercial use. As a condition to your continued use of the website, you agree that you will not use the website for any purpose that is unlawful or prohibited by these Terms of Use. <br />
            <br />


            If you use this site, you are responsible for maintaining the confidentiality of your account and password and for restricting access to your computer, and you agree to accept responsibility for all activities that occur under your account or password. You agree to immediately notify GoStartupGo of any unauthorized use of your account or password or any other breach of security on the site of which you are aware and exit from your account at the end of each session. GoStartupGo is not liable for any loss or damage arising from your failure to protect your account or password. If you are under 18, you may use the services provided via this site only with the permission of a parent or guardian. GoStartupGo and its affiliates reserve the right to refuse service, terminate accounts, remove or edit content, or cancel orders in their sole discretion. <br />
            <br />


            <strong>Access to the Site</strong> <br />


            GoStartupGo grants you a non-transferable, non-exclusive, revocable, limited license to access the Site solely for your own personal, non-commercial use. <br />
            <br />


            The rights approved to you in these Terms are subject to the following restrictions: (a) you shall not sell, rent, lease, transfer, assign, distribute, host, or otherwise commercially exploit the Site; (b) you shall not change, make derivative works of, disassemble, reverse compile or reverse engineer any part of the Site; (c) you shall not access the Site in order to build a similar or competitive website; and (d) except as expressly stated herein, no part of the Site may be copied, reproduced, distributed, republished, downloaded, displayed, posted or transmitted in any form or by any means unless otherwise indicated, any future release, update, or other addition to functionality of the Site shall be subject to these Terms. All copyright and other proprietary notices on the Site must be retained on all copies thereof. <br />
            <br />


            GoStartupGo reserves the right to change, suspend, or cease the Site with or without notice to you. GoStartupGo will not be held liable to you or any third-party for any change, interruption, or termination of the Site or any part. <br />
            <br />


            <strong>Use of Content from the Site</strong> <br />


            This site is the property of and owned by GoStartupGo and is protected by copyright, trademark and other laws of the United States and other countries. You may display and electronically copy, download and print hard copy versions of the materials contained on the site for your personal and non-commercial use, provided that you do not modify or delete any copyright, trademark or other proprietary notice that appears on the materials you copy, download or print. GoStartupGo retains full and complete title to the content and to any and all of the associated intellectual property rights. Any other use of the materials on the site, including but not limited to the modification, distribution, transmission, performance, broadcast, publication, licensing, reverse engineering, transfer or sale of, or the creation of derivative works from, any materials, information, software, products or services obtained from the site, without the prior written permission of GoStartupGo, is expressly prohibited. You may not frame or utilize framing techniques to enclose any trademark, logo or other proprietary information (including images, text, page layout or form) of GoStartupGo or its affiliates without express written consent. You may not use any meta tags or any other "hidden text" utilizing GoStartupGo’s or its affiliates' name or trademarks without the express written consent of GoStartupGo. Any unauthorized use terminates the permission or license granted by GoStartupGo. <br />
            <br />


            Requests for permission to undertake any of these activities should be mailed to: <br />

            GOSTARTUPGO Foundation <br />

            No 1 Okoro - Otun Avenue,  Oredo Local Government Area <br />
            <br />


            <strong>Disclaimers</strong> <br />


            The site is provided on an “as-is” and “as available” basis, and GoStartupGo expressly disclaim any and all warranties and conditions of any kind, whether express, implied, or statutory, including all warranties or conditions of merchantability, fitness for a particular purpose, title, quiet enjoyment, accuracy, or non-infringement. We and our suppliers make no guarantee that the site will meet your requirements, will be available on an uninterrupted, timely, secure, or error-free basis, or will be accurate, reliable, free of viruses or other harmful code, complete, legal, or safe. <br />
            <br />


            <strong>Featured Links</strong> <br />


            This website may contain hyperlinks to other Internet sites. These hyperlinks are provided for your information and convenience only. GoStartupGo does not control these other websites and is not responsible for their content; nor does GoStartupGo’s inclusion of hyperlinks to such websites imply any endorsement or approval of the material on such websites or any association with their operators. Check the URL address provided in your browser to determine whether you are still on this GoStartupGo website or have moved to another site.




          </div>
          <div style={{display : 'flex', gap : '0px'}}>         
          <button className='orangeButton' onClick={() =>{
            handleAccept();
          }}>Accept</button>
          <button className='orangeButton' onClick={()=>{
            handleDecline();
          }}>Decline</button>
          </div>



        </div>


      </div>



    </>
  )
}

export default TermsAndCondition